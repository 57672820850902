import React from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import {FormGroup, Label, Input, Toast, Button} from "spectre-react/dist/cjs";
import {REPORTS_PATH} from '../paths';
import {checkAuth, signIn} from '../api';
import {LOGGED_IN} from "../store/actions";


class SignInForm extends React.Component {
    static propTypes = {
        onLoggedIn: PropTypes.func.isRequired,
    }

    state = {
        isLoggedIn: undefined,
        name: '', password: '',
        isSubmitting: false, submitError: ''
    };

    componentDidMount() {
        checkAuth()
            .then(this.props.onLoggedIn)
            .catch(error => this.setState({ isLoggedIn: false }));
    }

    onChange = (ev) => this.setState({ [ev.target.name]: ev.target.value, submitError: '' });

    handleSubmit = (ev) => {
        ev.preventDefault();
        this.setState({isSubmitting: true});

        const {name, password} = this.state;
        signIn(name, password)
            .then(this.props.onLoggedIn)
            .catch(error => this.setState({isSubmitting: false, submitError: error.message}));
    };

    render() {
        const {isLoggedIn, isSubmitting, submitError, name, password} = this.state;

        if (isLoggedIn === undefined || isLoggedIn === true) {
            return null;
        }

        return (
            <form id="sign-in-form" onSubmit={this.handleSubmit}>
                <img src="/logo.svg" className="img-responsive mb-4" alt="Логотип"/>

                <FormGroup>
                    <Label form htmlFor="name">Логин</Label>
                    <Input type="text" id="name" name="name" value={name} onChange={this.onChange} loading={isSubmitting} autoFocus/>
                </FormGroup>

                <FormGroup>
                    <Label form htmlFor="password">Пароль</Label>
                    <Input type="password" id="password" name="password" value={password} onChange={this.onChange} loading={isSubmitting}/>
                </FormGroup>

                {submitError && <Toast className="mt-4">{submitError}</Toast>}

                <Button primary loading={isSubmitting} type="submit" className="mt-4">Войти</Button>
            </form>
        );
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    onLoggedIn: ({user, engineers}) => {
        dispatch({ type: LOGGED_IN, payload: {user, engineers} });

        const state = ownProps.location.state;
        const location = state ? state.from : REPORTS_PATH;
        ownProps.history.push(location);
    }
});

export default connect(null, mapDispatchToProps)(SignInForm);