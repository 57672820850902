import propTypes from "prop-types";

export function basename(path) { return path.substring(path.lastIndexOf('/') + 1) }
export function dirname(path) { return path.substring(0, path.lastIndexOf('/')); }

export const dateLocale = {
    months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    weekdaysLong: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота',],
    weekdaysShort: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
    firstDayOfWeek: 1,
    locale: 'ru',
    labels: { nextMonth: 'следующий месяц', previousMonth: 'предыдущий месяц' }
};

// const monthsGenitive = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];
// function humanDate(date, short = false) {
//     const t = date instanceof Date
//         ? [date.getDate(), monthsGenitive[date.getMonth()], date.getFullYear()]
//         : [date.slice(-2), monthsGenitive[parseInt(date.slice(5, 7), 10) - 1], date.slice(0, 4)];
//
//     if (short)
//         t[1] = t[1].slice(0, 3);
//
//     return t.join(' ');
// }

// https://stackoverflow.com/a/41716722
export function roundToOne(num) {
    return Math.round( ( num + Number.EPSILON ) * 10 ) / 10;
}

// https://stackoverflow.com/a/41716722
export function roundToTwo(num) {
    return Math.round( ( num + Number.EPSILON ) * 100 ) / 100;
}

export function formatDateTime2(d) { return ("0" + d.getDate()).slice(-2) + "." + ("0" + (d.getMonth()+1)).slice(-2) + "." + d.getFullYear() + ", " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2); }
export function formatDate1(d) { return d.getFullYear() + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2); }
export function formatDate2(d) { return ("0" + d.getDate()).slice(-2) + "." + ("0"+(d.getMonth()+1)).slice(-2) + "." + d.getFullYear(); }
export function formatDate2s(d) { return ("0" + d.getDate()).slice(-2) + "." + ("0" + (d.getMonth()+1)).slice(-2) + "." + ("" + d.getFullYear()).slice(-2); }

export function date1to2(str) { return str.slice(8) + '.' + str.slice(5,7) + '.' + str.slice(0,4); }
export function date1to2s(str) { return str.slice(8) + '.' + str.slice(5,7) + '.' + str.slice(2,4); }
export function date2to1(str) { return str.slice(6) + '-' + str.slice(3,5) + '-' + str.slice(0,2); }

export function parseDate2(str) {
    if (!(typeof(str) === "string") || !/^\d\d\.\d\d\.\d\d\d\d$/.test(str)) return undefined;
    const value = new Date(str.slice(6, 10) + '-' + str.slice(3, 5) + '-' + str.slice(0, 2));
    if (isNaN(value.valueOf())) return undefined;
    return value;
}

export function parseDate(str) {
    if (str instanceof Date)
        return new Date(str);
    else if (/^\d\d\d\d-\d\d-\d\d$/.test(str))
        return new Date(str);
    else if (/^\d\d\.\d\d\.\d\d\d\d$/.test(str))
        return new Date(str.slice(6, 10) + '-' + str.slice(3, 5) + '-' + str.slice(0, 2));
    // else if (/^\d\d\.\d\d\.\d\d$/.test(str))
    //     return new Date('20' + str.slice(6, 8) + '-' + str.slice(3, 5) + '-' + str.slice(0, 2));
    else
        return undefined;
}

export function groupBy(array, keyfunc, returnMap = false) {
    const map = new Map();
    for (const item of array) {
        const key = keyfunc(item);
        const group = map.get(key);
        if (group)
            group.push(item);
        else
            map.set(key, [item]);
    }
    return returnMap ? map : Array.from(map.entries());
}
export function isMobile() { return (/Mobi|Android/i.test(navigator.userAgent)); }

// https://github.com/kulakowka/pluralize-ru
export const pluralize = (i, str0, str1, str2, str5) => {
    if (i === 0)
        return str0;
    else if (i % 10 === 1 && i % 100 !== 11)
        return str1;
    else if (i % 10 >= 2 && i % 10 <= 4 && (i % 100 < 10 || i % 100 >= 20))
        return str2;
    else
        return str5;
};

export function debounce(func, timeout = 300) {
    let timer;

    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

export const pastel19 = {red: '#fbb4ae', blue: '#b3cde3', green: '#ccebc5', purple: '#decbe4', orange: '#fed9a6', yellow: '#ffffcc', brown: '#e5d8bd', pink: '#fddaec', grey: '#f2f2f2',};

export const FilePropType = propTypes.shape({
    mimetype: propTypes.string,
    originalname: propTypes.string,
    path: propTypes.string,
    size: propTypes.number
});

export const LocationPropType = propTypes.shape({
    address: propTypes.string,
    lat: propTypes.string,
    lon: propTypes.string
});

export const ClientContactPropType = propTypes.shape({
    name: propTypes.string,
    phone: propTypes.string,
    email: propTypes.string
});

export const ClientContactsPropType = propTypes.arrayOf(ClientContactPropType);


export const PRINTER_CATEGORIES = [
    {value: "0", label: "Другие"},
    {value: "1", label: "Керамика"},
    {value: "2", label: "Текстиль"},
    {value: "3", label: "Traffic Sign"},
    {value: "4", label: "Flatbed старый"},
    {value: "5", label: "Flatbed малый"},
    {value: "6", label: "Flatbed средний"},
    {value: "7", label: "Flatbed большой"},
    {value: "8", label: "Roll старый"},
    {value: "9", label: "Roll актуальный"},
];

export const STATUS = {}, STATUS_LABEL = {};
STATUS.NOT_FIXED = '1';  STATUS_LABEL['1'] = 'Не устранена';       STATUS_LABEL.NOT_FIXED = 'Не устранена';
STATUS.PART_FIXED = '2'; STATUS_LABEL['2'] = 'Частично устранена'; STATUS_LABEL.PART_FIXED = 'Частично устранена';
STATUS.FIXED = '3';      STATUS_LABEL['3'] = 'Устранена';          STATUS_LABEL.FIXED = 'Устранена';

export const WORK_TYPE = {}, WORK_TYPE_LABEL = {};
WORK_TYPE.PAID = '1';        WORK_TYPE_LABEL['1'] = 'Платный ремонт';       WORK_TYPE_LABEL.PAID = 'Платный ремонт';
WORK_TYPE.CONTRACT = '2';    WORK_TYPE_LABEL['2'] = 'Договор';              WORK_TYPE_LABEL.CONTRACT = 'Договор';
WORK_TYPE.WARRANTY = '3';    WORK_TYPE_LABEL['3'] = 'Гарантийный ремонт';   WORK_TYPE_LABEL.WARRANTY = 'Гарантийный ремонт';
WORK_TYPE.INSTALL = '4';     WORK_TYPE_LABEL['4'] = 'Пусконаладка';         WORK_TYPE_LABEL.INSTALL = 'Пусконаладка';
WORK_TYPE.SHORT = '5';       WORK_TYPE_LABEL['5'] = 'Краткосрочный выезд';  WORK_TYPE_LABEL.SHORT = 'Краткосрочный выезд';
WORK_TYPE.TEACH = '6';       WORK_TYPE_LABEL['6'] = 'Обучение';             WORK_TYPE_LABEL.TEACH = 'Обучение';
WORK_TYPE.MOVE = '7';        WORK_TYPE_LABEL['7'] = 'Перемещение';          WORK_TYPE_LABEL.MOVE = 'Перемещение';
WORK_TYPE.MAINTENANCE = '8'; WORK_TYPE_LABEL['8'] = 'ТО';                   WORK_TYPE_LABEL.MAINTENANCE = 'ТО';
WORK_TYPE.FOC = '9';         WORK_TYPE_LABEL['9'] = 'Бесплатно';            WORK_TYPE_LABEL.FOC = 'Бесплатно';
WORK_TYPE.SLOT = 'A';        WORK_TYPE_LABEL['A'] = 'Замена слотов в лаборатории';        WORK_TYPE_LABEL.SLOT = 'Замена слотов в лаборатории';
WORK_TYPE.SUPPORT = 'S';     WORK_TYPE_LABEL['S'] = 'Удаленная поддержка';  WORK_TYPE_LABEL.SUPPORT = 'Удаленная поддержка';

export const WORK_TYPE_REPORTS_LIST_LABEL = {
    '1': 'Платный ремонт',
    '2': 'Договор',
    '3': 'Гарантийный ремонт',
    '4': 'Пусконаладка',
    '5': 'Краткосрочный выезд',
    '6': 'Обучение',
    '7': 'Перемещение',
    '8': 'ТО',
    '9': 'Бесплатно',
    'A': 'Замена слотов',
    'S': 'Поддержка',
}

export const BILL_STATE = {}, BILL_STATE_LABEL = {};
BILL_STATE.ERROR = '0';      BILL_STATE_LABEL['0'] = 'Ошибка';            BILL_STATE_LABEL.ERROR = 'Ошибка';
BILL_STATE.NOT_ISSUED = '1'; BILL_STATE_LABEL['1'] = 'Счет не выставлен'; BILL_STATE_LABEL.NOT_ISSUED = 'Счет не выставлен';
BILL_STATE.ISSUED = '2';     BILL_STATE_LABEL['2'] = 'Счет выставлен';    BILL_STATE_LABEL.ISSUED = 'Счет выставлен';
BILL_STATE.PART_PAY = '3';   BILL_STATE_LABEL['3'] = 'Частичная оплата';  BILL_STATE_LABEL.PART_PAY = 'Частичная оплата';
BILL_STATE.FULL_PAY = '4';   BILL_STATE_LABEL['4'] = 'Полная оплата';     BILL_STATE_LABEL.FULL_PAY = 'Полная оплата';

export const PAYMENT_TYPE = {}, PAYMENT_TYPE_LABEL = {};
PAYMENT_TYPE.GOODWILL = '5';   PAYMENT_TYPE_LABEL['5'] = 'Goodwill';  PAYMENT_TYPE_LABEL.GOODWILL = 'Goodwill';
PAYMENT_TYPE.CONTRACT = '6';   PAYMENT_TYPE_LABEL['6'] = 'Контракт';  PAYMENT_TYPE_LABEL.CONTRACT = 'Контракт';
PAYMENT_TYPE.WARRANTY = '7';   PAYMENT_TYPE_LABEL['7'] = 'Гарантия';  PAYMENT_TYPE_LABEL.WARRANTY = 'Гарантия';

export const PAYMENT_STATE = {}, PAYMENT_STATE_LABEL = {};
PAYMENT_STATE.ERROR = '1';     PAYMENT_STATE_LABEL['1'] = 'Ошибка';             PAYMENT_STATE_LABEL.ERROR = 'Ошибка';
PAYMENT_STATE.NO_LINK = '2';   PAYMENT_STATE_LABEL['2'] = 'Не привязан';        PAYMENT_STATE_LABEL.NO_LINK = 'Не привязан';
PAYMENT_STATE.PART_LINK = '3'; PAYMENT_STATE_LABEL['3'] = 'Частично привязан';  PAYMENT_STATE_LABEL.PART_LINK = 'Частично привязан';
PAYMENT_STATE.FULL_LINK = '4'; PAYMENT_STATE_LABEL['4'] = 'Полностью привязан'; PAYMENT_STATE_LABEL.FULL_LINK = 'Полностью привязан';
PAYMENT_STATE.HIDDEN = '5';    PAYMENT_STATE_LABEL['5'] = 'Неактивен';          PAYMENT_STATE_LABEL.HIDDEN = 'Неактивен';

export const REPLACEMENT = {}, REPLACEMENT_LABEL = {};
REPLACEMENT.SP_WO_SN = 'SP_WO_SN';      REPLACEMENT_LABEL.SP_WO_SN = 'З/ч без с/н';
REPLACEMENT.SP_W_SN = 'SP_W_SN';        REPLACEMENT_LABEL.SP_W_SN = 'З/ч с с/н';
REPLACEMENT.PH_ADD = 'PH_ADD';          REPLACEMENT_LABEL.PH_ADD = 'ПГ установка';
REPLACEMENT.PH_REMOVE = 'PH_REMOVE';    REPLACEMENT_LABEL.PH_REMOVE = 'ПГ демонтаж';
REPLACEMENT.PH_SHIFT = 'PH_SHIFT';      REPLACEMENT_LABEL.PH_SHIFT = 'ПГ перестановка';

export const SLOT_STATE = {};
SLOT_STATE.UNKNOWN = "UNKNOWN";
SLOT_STATE.USED = "USED";
SLOT_STATE.NEW = "NEW";

export const EXPENSE = {}, EXPENSE_LABEL = {};
EXPENSE.AIR_RAIL = "AIR_RAIL";   EXPENSE_LABEL.AIR_RAIL = 'Авиа и ЖД';
EXPENSE.TAXI = "TAXI";           EXPENSE_LABEL.TAXI = 'Такси до/от аэропорта';
EXPENSE.LOCAL = "LOCAL";         EXPENSE_LABEL.LOCAL = 'Локальный транспорт';
EXPENSE.HOTEL = "HOTEL";         EXPENSE_LABEL.HOTEL = 'Отель';
EXPENSE.PER_DIEM = "PER_DIEM";   EXPENSE_LABEL.PER_DIEM = 'Суточные';
EXPENSE.OTHER =  "OTHER";        EXPENSE_LABEL.OTHER =  'Прочие расходы';

export const WORK_TYPE_CHIP_CLASS = {
    '1': 'bg-gray',  // PAID
    '2': 'bg-gray',  // CONTRACT
    '3': 'bg-gray',  // WARRANTY
    '4': 'bg-primary',  // INSTALL
    '5': 'bg-gray',  // SHORT
    '6': 'bg-secondary',  // TEACH
    '7': 'bg-gray',  // MOVE
    '8': 'bg-warning',  // MAINTENANCE
    '9': 'bg-gray',  // FOC
    'A': 'bg-dark',  // SLOT
}


export const MACHINE_COEFF_GROUP = {
    GAMMA__PICTOCER: 'GAMMA__PICTOCER',
    RHOTEX__P5_250_HS: 'RHOTEX__P5_250_HS',
    MUTOH: 'MUTOH',
    REST: 'REST',
}

export const MACHINE_COEFF_GROUP_LABEL = {
    GAMMA__PICTOCER: 'Gamma + Pictocer',
    RHOTEX__P5_250_HS: 'Rhotex + P5 250 HS',
    MUTOH: 'Mutoh',
    REST: 'Остальное оборудование',
}

export const DAY_STATUS = {
    VACATION_PAID: 'VACATION_PAID',
    VACATION_UNPAID: 'VACATION_UNPAID',
    TIME_OFF_PAID: 'TIME_OFF_PAID',
    TIME_OFF_UNPAID: 'TIME_OFF_UNPAID',
    WORK_FROM_HOME: 'WORK_FROM_HOME',
    WORK_FROM_HOME_NWD: 'WORK_FROM_HOME_NWD',
    WORK_FROM_OFFICE: 'WORK_FROM_OFFICE',
    WORK_FROM_OFFICE_NWD: 'WORK_FROM_OFFICE_NWD',
    FIELD_WORK_HOME_CITY: 'FIELD_WORK_HOME_CITY',
    FIELD_WORK_HOME_CITY_NWD: 'FIELD_WORK_HOME_CITY_NWD',
    FIELD_WORK_OTHER_CITY: 'FIELD_WORK_OTHER_CITY',
    FIELD_WORK_OTHER_CITY_NWD: 'FIELD_WORK_OTHER_CITY_NWD',
    ILLNESS: 'ILLNESS',
    EMPTY: 'EMPTY',
};

export const DAY_STATUS_LABEL = {
    TIME_OFF_PAID: 'Оплачиваемый отгул',
    TIME_OFF_UNPAID: 'Неоплачиваемый отгул',
    WORK_FROM_HOME: 'Из дома',
    WORK_FROM_HOME_NWD: 'Из дома в нерабочий день',
    WORK_FROM_OFFICE: 'Из офиса',
    WORK_FROM_OFFICE_NWD: 'Из офиса в нерабочий день',
    FIELD_WORK_HOME_CITY: 'Выезд/слоты',
    FIELD_WORK_HOME_CITY_NWD: 'Выезд/слоты в нерабочий день',
    FIELD_WORK_OTHER_CITY: 'Командировка',
    FIELD_WORK_OTHER_CITY_NWD: 'Командировка в нерабочий день',
    ILLNESS: 'Больничный',
    EMPTY: 'Неопределено',
};

export const ENGINEER_CATEGORY = {
    FOREIGN: 'FOREIGN', // foreign from SignART point of view
    INACTIVE: 'INACTIVE', // worked at SignART during some time in the past
    ACTIVE: 'ACTIVE' // works at SignART now
}

export const TRIP_KIND = {
    PLANE: "PLANE",
    TAXI: "TAXI",
    TRAIN: "TRAIN",
}

export const TRIP_KIND_ICON = {
    TAXI: 'local_taxi',
    TRAIN: 'train',
    PLANE: 'flight',
}

export const BOOKED_AT_ICON = {
    AEROFLOT: '/transport/aeroflot.svg',
    ROSSIYA: '/transport/rossiya.svg',
    RZD: '/transport/rzd.svg',
    S7: '/transport/s7.svg',
    YANDEX_GO: '/transport/yandex.svg',
}

export const REPORT_APPROVAL_STAGE = {
    IN_PROGRESS: "IN_PROGRESS",
    APPROVED: "APPROVED"
}

export const DURST_INFO_DOC_ID = 'durstInfo';