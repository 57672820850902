import React from "react";
import propTypes from 'prop-types';
import {connect} from "react-redux";
import memoize from "memoize-one";
import {Button, Checkbox, Icon, Input, Label, Radio} from "spectre-react/dist/cjs";
import {Autocomplete, SimpleAutocomplete} from "../../shared/Select";
import {ArrayField, ObjectField} from "../../shared/Forms";
import {getSparePartsByRef, getSparePartsByDescription, fetchDurstInfoDoc} from "../../../api";
import {REPLACEMENT_LABEL, REPLACEMENT} from "../../shared/sharedImport";
import {getMachines} from "../../../selectors";


class WithAutocomplete extends React.PureComponent {
    static propTypes = {
        item: propTypes.object.isRequired,
        onChange: propTypes.func.isRequired,
        children: propTypes.func.isRequired
    };

    onChange = (ev) => {
        const item = ev.target.value;

        if ('ref' in item && item.ref instanceof Object) {
            const {ref, name} = item.ref;
            ev.target.value = {...ev.target.value, ref, name};
        }

        if ('name' in item && item.name instanceof Object) {
            const {ref, name} = item.name;
            ev.target.value = {...ev.target.value, ref, name};
        }

        this.props.onChange(ev);
    };

    render() {
        return this.props.children(this.onChange);
    }
}

class Replacements extends React.PureComponent {
    static propTypes = {
        machineId: propTypes.oneOfType([propTypes.string, propTypes.object]),
        replacements: propTypes.array.isRequired,
        onChange: propTypes.func
    };

    static defaultProps = {
        replacements: []
    };

    info = undefined;
    machineModels = {};

    async componentDidMount() {
        this.machineModels = this.props.machines.map(machine => [machine._id, machine.model]);
        this.machineModels = Object.fromEntries(this.machineModels);

        this.info = await fetchDurstInfoDoc();

        for (const key of Object.keys(this.info.printheads))
            this.info.printheads[key] = this.info.printheads[key].map(({ref, name}) => ({ref, name}));
    }

    getPrintheadsAndPositions = memoize(
        (model) => {
            if (!model || !this.info)
                return { printheads: [], positions: [] };

            return {
                printheads: this.info.printheads[model] || [],
                positions: (model in this.info.configurations)
                    ? this.info.tables[this.info.configurations[model].table]
                        .reduce((acc, val) => acc.concat(val), [])
                        .filter(Boolean)
                    : []
            }
        }
    );

    addNewReplacementsItem = () => {
        return { type: null };
    }

    render() {
        const {replacements, onChange, machineId} = this.props;

        const machineModel = this.machineModels[machineId] || null;
        const {printheads, positions} = this.getPrintheadsAndPositions(machineModel);

        return (
            <ArrayField name="replacements" array={replacements} onChange={onChange} buildNewItem={this.addNewReplacementsItem}>
                {({array, keys, onAdd, onRemove, onChange}) =>
                    <fieldset className="mb-6">
                        <legend className="h2">
                            Замены запчастей/комплектующих&nbsp;
                            <Button action link small onClick={onAdd}>
                                <Icon icon="plus"/>
                            </Button>
                        </legend>

                        <p className="text-italic mb-1">
                            При выборе "ПГ перестановка" нужно писать с/н и позиции ПОСЛЕ замены.
                        </p>

                        <div id="replacements-table">
                            {array.map((item, index) => this.renderItem(item, index, onRemove, onChange, keys[index], printheads, positions))}
                        </div>
                        <Button primary className="mt-2" onClick={onAdd}>Добавить</Button>
                    </fieldset>
                }
            </ArrayField>
        )
    }

    renderItem = (item, index, onRemove, onChange, key, printheads, positions) => {
        const removeButton =
            <Button action onClick={onRemove} data-index={index}>
                <Icon icon="minus"/>
            </Button>;

        if (typeof item === "string")
            return <div className="replacement-item" key={key}>
                <Input type="text" name={`replacements.${index}`} placeholder="Описание" value={item} onChange={onChange}/>
                {removeButton}
            </div>;

        switch (item.type) {
            case null:
                return <ObjectField object={item} onChange={onChange} dummy={index} key={key}>
                    {({onChange}) => <div className="replacement-item">
                        <Label primary>Выберите тип:</Label>
                        {Object.keys(REPLACEMENT).map(type =>
                            <Radio key={type} name={`replacements.${index}.type`} value={type} checked={false} onChange={onChange}>
                                {REPLACEMENT_LABEL[type]}
                            </Radio>
                        )}
                        {removeButton}
                    </div>}
                </ObjectField>;

            case REPLACEMENT.SP_WO_SN:
                return <WithAutocomplete item={item} onChange={onChange} key={key}>
                    {(onChange) =>
                        <ObjectField object={item} onChange={onChange} dummy={index}>
                            {({onChange, object}) => <div className="replacement-item">
                                <Label>{REPLACEMENT_LABEL.SP_WO_SN}</Label>
                                <Autocomplete
                                    placeholder="Артикул" onChange={onChange} onSelect={onChange}
                                    itemsGetter={getSparePartsByRef} itemToString={_item => _item ? _item.ref : ''}
                                    name={`replacements.${index}.ref`} value={object.ref}
                                />
                                <Autocomplete
                                    placeholder="Наименование" onChange={onChange} onSelect={onChange}
                                    itemsGetter={getSparePartsByDescription} itemToString={_item => _item ? _item.name : ''}
                                    name={`replacements.${index}.name`} value={object.name}
                                />
                                <Input type="number" name={`replacements.${index}.num`} placeholder="Кол-во" step="1" value={object.num} onChange={onChange}/>
                                {removeButton}
                            </div>}
                        </ObjectField>
                    }
                </WithAutocomplete>;

            case REPLACEMENT.SP_W_SN:
                return <WithAutocomplete item={item} onChange={onChange} key={key}>
                    {(onChange) =>
                        <ObjectField object={item} onChange={onChange} dummy={index}>
                            {({onChange, object}) =><div className="replacement-item">
                                <Label>{REPLACEMENT_LABEL.SP_W_SN}</Label>
                                <Autocomplete
                                    placeholder="Артикул" onChange={onChange} onSelect={onChange}
                                    itemsGetter={getSparePartsByRef} itemToString={_item => _item ? _item.ref : ''}
                                    name={`replacements.${index}.ref`} value={object.ref}
                                />
                                <Autocomplete
                                    placeholder="Наименование" onChange={onChange} onSelect={onChange}
                                    itemsGetter={getSparePartsByDescription} itemToString={_item => _item ? _item.name : ''}
                                    name={`replacements.${index}.name`} value={object.name}
                                />
                                <Input type="text" name={`replacements.${index}.old`} placeholder="Старый с/н" value={object.old} onChange={onChange}/>
                                <Input type="text" name={`replacements.${index}.new`} placeholder="Новый с/н" value={object.new} onChange={onChange}/>
                                {removeButton}
                            </div>}
                        </ObjectField>
                    }
                </WithAutocomplete>;

            case REPLACEMENT.PH_ADD:
                return <WithAutocomplete item={item} onChange={onChange} key={key}>
                    {(onChange) =>
                        <ObjectField object={item} onChange={onChange} dummy={index}>
                            {({onChange, object}) => <div className="replacement-item">
                                <Label>{REPLACEMENT_LABEL.PH_ADD}</Label>
                                {/*<SimpleAutocomplete items={printheads} name={`replacements.${index}.ref`}*/}
                                {/*                    value={object.ref} placeholder="Артикул" onChange={onChange}/>*/}
                                <Checkbox name={`replacements.${index}.isAfterLab`} checked={object.isAfterLab} onChange={onChange}>после ремонта</Checkbox>
                                <Autocomplete
                                    placeholder="Артикул" onChange={onChange} onSelect={onChange}
                                    items={printheads} itemToString={_item => _item ? _item.ref : ''}
                                    name={`replacements.${index}.ref`} value={object.ref}
                                />
                                <Input type="text" name={`replacements.${index}.name`} placeholder="Наименование" value={object.name} onChange={onChange}/>
                                <SimpleAutocomplete items={positions} name={`replacements.${index}.pos`} value={object.pos} placeholder="Позиция" onChange={onChange}/>
                                <Input type="text" name={`replacements.${index}.old`} placeholder="Старый с/н" value={object.old} onChange={onChange}/>
                                <Input type="text" name={`replacements.${index}.new`} value={object.new} placeholder="Новый с/н" onChange={onChange}/>
                                {removeButton}
                            </div>}
                        </ObjectField>}
                </WithAutocomplete>;

            case REPLACEMENT.PH_REMOVE:
                return <ObjectField object={item} onChange={onChange} dummy={index} key={key}>
                    {({onChange, object}) => <div className="replacement-item">
                        <Label>{REPLACEMENT_LABEL.PH_REMOVE}</Label>
                        <Checkbox name={`replacements.${index}.isForLab`} checked={object.isForLab} onChange={onChange}>для ремонта</Checkbox>
                        <SimpleAutocomplete items={positions} name={`replacements.${index}.pos`} value={object.pos} placeholder="Позиция" onChange={onChange}/>
                        <Input type="text" name={`replacements.${index}.old`} placeholder="C/н головы" value={object.old} onChange={onChange}/>
                        {removeButton}
                    </div>}
                </ObjectField>;

            case REPLACEMENT.PH_SHIFT:
                return <ObjectField object={item} onChange={onChange} dummy={index} key={key}>
                    {({onChange, object}) => <div className="replacement-item">
                        <Label>{REPLACEMENT_LABEL.PH_SHIFT}</Label>
                        <Input type="text" onChange={onChange} name={`replacements.${index}.serial1`} placeholder="С/н 1"     value={object.serial1}/>
                        <Input type="text" onChange={onChange} name={`replacements.${index}.pos1`}    placeholder="Позиция 1" value={object.pos1}/>
                        <Input type="text" onChange={onChange} name={`replacements.${index}.serial2`} placeholder="С/н 2"     value={object.serial2}/>
                        <Input type="text" onChange={onChange} name={`replacements.${index}.pos2`}    placeholder="Позиция 2" value={object.pos2}/>
                        {removeButton}
                    </div>}
                </ObjectField>;
        }
    };
}


function mapStateToProps(state) {
    return {
        machines: getMachines(state),
    }
}


export default connect(mapStateToProps)(Replacements);