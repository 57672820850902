import React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalContainer, ModalFooter, ModalHeader, ModalTitle, ModalOverlay, Button, Toast} from "spectre-react/dist/cjs";
import {doApproveReport} from "../../api";


class ModalApproval extends React.PureComponent {
    defaultState = {
        active: false,
        reportId: undefined,
        submitState: 0, submitError: null
    }

    state = {...this.defaultState};

    openModal = (reportId) => this.setState({active: true, reportId});

    closeModal = () => this.setState(this.defaultState);

    onSubmit = async () => {
        try {
            this.setState({submitState: 1, submitError: null});
            await doApproveReport(this.state.reportId);
            this.closeModal();
        } catch (error) {
            this.setState({submitState: 0, error: error.message})
        }
    };

    render() {
        if (!this.state.active) return null;

        const {submitState, submitError} = this.state;
        const isLoading = submitState === 1;

        return (
            <Modal active small id="report-approval-modal">
                <ModalOverlay onClick={this.closeModal}/>
                <ModalContainer>
                    <ModalHeader>
                        <Button className="btn-clear float-right" onClick={this.closeModal}/>
                        <ModalTitle className="h5">Одобрение репорта</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        Одобрить репорт<br/>
                        для начисления оплаты труда технику?<br/><br/>
                        Действие будет невозможно отменить.
                    </ModalBody>
                    <ModalFooter>
                        {submitError && <Toast error className="mb-2">{submitError}</Toast>}

                        <Button primary loading={isLoading} onClick={this.onSubmit}>Одобрить</Button>
                        <Button link className="ml-2" onClick={this.closeModal}>Закрыть</Button>
                    </ModalFooter>
                </ModalContainer>
            </Modal>
        )
    }
}


export default ModalApproval;