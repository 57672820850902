import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import {Button, Checkbox, FormGroup} from "spectre-react/dist/cjs";
import {lightFormat} from "date-fns";
import {transformDayStatusToCSSClass} from "./shared";
import {createCalendarDayStatusOverride} from "../../api";
import {DAY_STATUS_LABEL} from "../shared/sharedImport";

class EngineerActions extends React.PureComponent {
    static propTypes = {
        selectedDay: PropTypes.instanceOf(Date),
        selectedRange: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
        selectRange: PropTypes.bool,

        toggleSelectRange: PropTypes.func,
        availableStatuses: PropTypes.arrayOf(PropTypes.string),
        onItemCreated: PropTypes.func,

        selectedEngineer: PropTypes.object,
        selectEngineerItems: PropTypes.arrayOf(PropTypes.object),
        onEngineerChange: PropTypes.func,

        calendarType: PropTypes.oneOf(['YEAR', 'MONTH']),
        changeCalendarType: PropTypes.func,
    }

    onStatusButtonClick = async (ev) => {
        const {selectRange, selectedRange, selectedDay} = this.props;

        const affectedEngineerId = this.props.selectedEngineer._id;
        const affectedDates = selectRange
            ? [lightFormat(selectedRange[0], 'yyyy-MM-dd'), lightFormat(selectedRange[1], 'yyyy-MM-dd')]
            : [lightFormat(selectedDay, 'yyyy-MM-dd')];
        const dayStatus = ev.target.parentElement.dataset.status;

        const response = await createCalendarDayStatusOverride({
            affectedEngineerId: affectedEngineerId,
            affectedDates: affectedDates,
            dayStatus: dayStatus,
        });

        this.props.onItemCreated(response);
    }

    onCalendarTypeButtonClick = (ev) => this.props.changeCalendarType(ev.target.dataset.value);

    renderCalendarTypeButtonGroup() {
        const calendarType = this.props.calendarType;

        return (
            <div className="btn-group btn-group-block">
                <Button data-value="YEAR" active={calendarType === "YEAR"} onClick={this.onCalendarTypeButtonClick}>Год</Button>
                <Button data-value="MONTH" active={calendarType === "MONTH"} onClick={this.onCalendarTypeButtonClick}>Месяц</Button>
            </div>
        )
    }

    render() {
        const {
            selectedDay, selectedRange, selectRange,
            availableStatuses,
        } = this.props;

        return (
            <div id="calendar-actions">
                <FormGroup>
                    {this.renderCalendarTypeButtonGroup()}
                </FormGroup>

                <FormGroup>
                    {!selectRange && <div>Выбран день {lightFormat(selectedDay, 'dd.MM.yyyy')}</div>}
                    {selectRange && <div>Выбран период {lightFormat(selectedRange[0], 'dd.MM.yyyy')} - {lightFormat(selectedRange[1], 'dd.MM.yyyy')}</div>}

                    <Checkbox id="selectRange" checked={selectRange} onChange={this.props.toggleSelectRange}>
                        Выбрать период
                    </Checkbox>
                </FormGroup>

                {availableStatuses.map(status =>
                    <div key={status} data-status={status} className="form-group status-group">
                        <span className={transformDayStatusToCSSClass(status)}></span>
                        <Button block className="text-dark" onClick={this.onStatusButtonClick}>
                            {DAY_STATUS_LABEL[status]}
                        </Button>
                    </div>
                )}
            </div>
        );
    }
}

export default EngineerActions;