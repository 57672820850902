import React from "react";
import PropTypes from "prop-types";
import {deleteMachine, postMachine} from "../../api";
import {Redirect} from "react-router-dom";
import {MACHINES_PATH} from "../../paths";
import MachineForm from "./MachineForm";
import {getMachine} from "../../selectors";
import {connect} from "react-redux";


class EditMachinePage extends React.Component {
    static propTypes = {
        machine: PropTypes.object.isRequired,
    }

    state = {
        submitState: 0, submitError: null,
        destroyState: 0, destroyError: null,
        machine: this.props.machine
    };

    // componentDidUpdate(prevProps) {
    //     if (this.props.match.params.machineId !== prevProps.match.params.machineId)
    //         this.setState({machine: this.props.machine});
    // }

    onChange = (ev) => {
        const machine = {...this.state.machine};

        if (Array.isArray(ev.target))
            for (const target of ev.target)
                machine[target.name] = target.value;
        else
            machine[ev.target.name] = ev.target.value;

        this.setState({submitError: null, machine});
    };

    onSubmit = async (machine) => {
        this.setState({submitError: null, submitState: 1});

        try {
            await postMachine(machine);

            this.setState({submitError: null, submitState: 2});
        } catch (error) {
            this.setState({submitError: error.message, submitState: 0})
        }
    };

    onDestroy = async () => {
        if (!window.confirm('Точно удалить?'))
            return;

        this.setState({destroyError: null, destroyState: 1});

        const {_id, _rev} = this.state.machine;

        try {
            await deleteMachine(_id, _rev);

            this.setState({destroyError: null, destroyState: 2});
        } catch (error) {
            this.setState({destroyError: error.message, destroyState: 0})
        }
    };

    render() {
        if (this.state.submitState === 2) {
            alert("Изменения успешно внесены.");
            return <Redirect to={MACHINES_PATH} />;
        }

        if (this.state.destroyState === 2) {
            alert("Оборудование успешно удалено.");
            return <Redirect to={MACHINES_PATH} />;
        }

        const {machine, submitState, submitError, destroyState, destroyError} = this.state;

        return (
            <div>
                <h1>Редактирование оборудования</h1>

                <MachineForm key={machine?._id || 'whatever'}
                             isSubmitting={submitState === 1} submitError={submitError}
                             isDestroying={destroyState === 1} destroyError={destroyError}
                             onSubmit={this.onSubmit} onDestroy={this.onDestroy}
                             machine={machine} onChange={this.onChange}
                />
            </div>
        )
    }
}


function mapStateToProps(state, ownProps) {
    const machineId = ownProps.match.params.machineId;
    const machine = getMachine(state, machineId);

    return { machine };
}

export default connect(mapStateToProps)(EditMachinePage);