import React from "react";
import PropTypes from "prop-types";
import {FormGroup, Label} from "spectre-react/dist/cjs";
import {groupBy} from "../../shared/sharedImport";
import {SingleSelect} from "../../shared/Select";
import {connect} from "react-redux";
import {getActiveMachines} from "../../../selectors";


class Machine extends React.PureComponent {
    static propTypes = {
        machines: PropTypes.arrayOf(PropTypes.object),
        clientId: PropTypes.string,
        // ACHTUNG! YES, we store the machine OBJECT in the field named 'machineId'. machine.model is required in Replacements
        // TODO: fix the above-stated
        machineId: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
    };

    machines = this.props.machines.map(({_id, clientId, serial, model, manufacturer}) => ({_id, clientId, manufacturer, serial, model}));

    onChange = (ev) => {
        const machine = ev.target.value;

        this.props.onChange({target: [
            {name: 'machineId', value: machine?._id},
            {name: 'clientId', value: machine?.clientId}
        ]});
    };

    buildItems() {
        const clientId = this.props.clientId;
        let machines = this.machines;

        if (this.props.clientId)
            machines = machines.filter(machine => machine.clientId === clientId);

        return groupBy(machines, machine => machine.manufacturer)
            .map(([label, items]) => ({label, items}))
            .sort((a, b) => a.label.localeCompare(b.label));
    }

    itemToString(machine) {
        return machine ? `${machine.serial} (${machine.model})` : '';
    }

    render() {
        const items = this.buildItems(), machineId = this.props.machineId;
        const selectedItem = machineId ? this.machines.find(machine => machine._id === machineId) : null;

        return (
            <FormGroup>
                <Label form htmlFor="machineId">Оборудование</Label>
                <SingleSelect items={items} selectedItem={selectedItem} itemToString={this.itemToString}
                              clearable={false} searchable={true} grouped={true} disabled={this.props.disabled}
                              name="machineId" placeholder="Оборудование" onChange={this.onChange}
                />
            </FormGroup>
        );
    }
}


function mapStateToProps(state, ownProps) {
    return {
        machines: getActiveMachines(state).sort((a, b) => a.serial.localeCompare(b.serial))
    }
}


export default connect(mapStateToProps)(Machine);