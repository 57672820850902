import React from 'react';
import PropTypes from 'prop-types';
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import ClientForm from "./ClientForm";
import {CLIENTS_PATH} from "../../paths";
import {postClient, deleteClient} from '../../api';
import {getClient} from "../../selectors";


class EditClientPage extends React.Component {
    static propTypes = {
        client: PropTypes.object.isRequired,
    }

    _state = {
        submitState: 0, submitError: null,
        destroyState: 0, destroyError: null,
        client: this.props.client,
    };

    state = {...this._state};

    // componentDidUpdate(prevProps) {
    //     if (this.props.match.params.clientId !== prevProps.match.params.clientId)
    //         this.setState({client: this.props.client});
    // }

    onChange = (ev) => {
        const client = {...this.state.client};

        if (Array.isArray(ev.target))
            for (const target of ev.target)
                client[target.name] = target.value;
        else
            client[ev.target.name] = ev.target.value;

        this.setState({submitError: null, client});
    };

    onSubmit = async (client) => {
        this.setState({submitError: null, submitState: 1});

        try {
            await postClient(client);

            this.setState({submitError: null, submitState: 2});
        } catch (error) {
            this.setState({submitError: error.message, submitState: 0})
        }
    };

    onDestroy = async () => {
        if (!window.confirm('Точно удалить?'))
            return;

        this.setState({destroyError: null, destroyState: 1});

        const {_id, _rev} = this.state.client;

        try {
            await deleteClient(_id, _rev);

            this.setState({destroyError: null, destroyState: 2});
        } catch (error) {
            this.setState({destroyError: error.message, destroyState: 0})
        }
    };

    render() {
        if (this.state.submitState === 2) {
            alert("Изменения успешно внесены.");
            return <Redirect to={CLIENTS_PATH} />;
        }

        if (this.state.destroyState === 2) {
            alert("Клиент успешно удален.");
            return <Redirect to={CLIENTS_PATH} />;
        }

        const {client, submitState, submitError, destroyState, destroyError} = this.state;

        return (
            <div>
                <h1>Редактирование клиента</h1>

                <ClientForm key={client?._id || 'whatever'}
                            isSubmitting={submitState === 1} submitError={submitError}
                            isDestroying={destroyState === 1} destroyError={destroyError}
                            onSubmit={this.onSubmit} onDestroy={this.onDestroy}
                            client={client} onChange={this.onChange}
                />
            </div>
        )
    }
}


function mapStateToProps(state, ownProps) {
    return {
        client: getClient(state, ownProps.match.params.clientId)
    };
}

export default connect(mapStateToProps)(EditClientPage);