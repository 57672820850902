import React from 'react';
import {MathJaxContext, MathJax} from "better-react-mathjax";
import {Table} from "spectre-react/dist/cjs";


class WagesAlgorithmExplanationPage extends React.PureComponent {
    render() {
        return (
            <MathJaxContext>
                <article className="py-2">
                    <h1>Расчет оплаты труда техников</h1>

                    <p>Всё написанное ниже описывает сам алгоритм, а не его техническую реализацию!</p>
                    <p>Работал или нет техник в конкретный день определяется наличием репорта с соответствующим указанным промежутком работы (<b>командировка</b> или работа <b>в СПб</b>) или записью в календаре (работа <b>дома</b> или <b>в офисе</b>). По умолчанию в будние (по производственному календарю) дни техник работает "дома" и ему начисляется 8 часов. Если техник работает в офисе, то он в этот день нажимает соответствующую кнопку в календаре. Если техник работает в СПб или в командировке, то он просто заносит репорт.</p>

                    <p>Оплата труда за конкретный день является суммой трех слагаемых:</p>
                    <MathJax>
                        {"\\[H = H_{work} + H_{complement} + H_{travel}\\]"}
                    </MathJax>

                    <section className="mt-6">
                        <h2>1. Рабочие часы</h2>

                        <p>Часы разделяются на <i>регулярные</i> и <i>сверхурочные</i>. Они считаются по-разному в зависимости от схемы начисления оплаты.</p>
                        <ul>
                            <li>В схеме "смена": регулярные = количество часов работы в любое время дня, ограниченное сверху 8 часами; сверхурочные = количество часов работы свыше 8.</li>
                            <li>В схеме "10-19": регулярные = количество часов работы в промежутке 10-19; сверхурочные = количество часов работы вне промежутка 10-19.</li>
                        </ul>
                        <p>Перерыв (указывается количеством минут без конкретного времени) всегда отнимается от регулярных часов; если при этом регулярные часы стали отрицательными (например, в случае, когда весь промежуток рабочего времени находится свыше 8 часов или вне промежутка 10-19), то этот минус уходит в сверхурочные часы.</p>
                        <p>Регулярных часов может оказаться больше 8 (только в схеме "10-19"; например, в случае, когда техник работал с 10 до 19 с перерывом меньше 60 минут) - в этом случае всё, что превышает 8 часов, уходит в сверхурочные часы.</p>
                        <p>Промежуток 10-19 - это дефолтное значение, его можно изменить для конкретного клиента на соответствующей странице.</p>
                        <p>Промежутков работы в общем случае больше одного.</p>
                        <p>В таблице с примерами ниже рассматриваются случаи только с одним промежутком работы в день.</p>

                        <Table className="table-striped text-center table-xs my-6">
                            <thead>
                            <tr>
                                <th>Схема</th>
                                <th>Начало промежутка работы</th>
                                <th>Конец промежутка работы</th>
                                <th>Перерыв</th>
                                <th>Регулярных раб. часов</th>
                                <th>Сверхуроч. раб. часов</th>
                            </tr>
                            </thead>
                            <tbody className="font-courier-new">
                            <tr><td>Смена</td><td>10:00</td><td>19:00</td><td>60</td><td>8</td><td>0</td></tr>
                            <tr><td>Смена</td><td>10:00</td><td>20:00</td><td>30</td><td>8</td><td>1.5</td></tr>
                            <tr><td>Смена</td><td>20:00</td><td>23:00</td><td>0</td><td>3</td><td>0</td></tr>
                            <tr><td>Смена</td><td>15:00</td><td>23:00</td><td>30</td><td>7.5</td><td>0</td></tr>
                            <tr><td>10-19</td><td>10:00</td><td>19:00</td><td>60</td><td>8</td><td>0</td></tr>
                            <tr><td>10-19</td><td>10:00</td><td>20:00</td><td>30</td><td>8.5 ⇒ 8</td><td>1 ⇒ 1.5</td></tr>
                            <tr><td>10-19</td><td>20:00</td><td>23:00</td><td>0</td><td>0</td><td>3</td></tr>
                            <tr><td>10-19</td><td>20:00</td><td>23:00</td><td>30</td><td>0</td><td>2.5</td></tr>
                            </tbody>
                        </Table>

                        <MathJax>
                            {"Если принять \\(b\\) - базовая ставка, \\(h_{wR_i}\\) - регулярные часы, \\(h_{wO_i}\\) - сверхурочные часы, то формула этого слагаемого выглядит так:"}
                            {"\\[H_{work} = b * \\left(\\sum_{i}^{}h_{wR_i} * k_{wPlace} * k_{machine} * k_{wType} \\right) * k_{wdR} * k_{engineer} + b * \\left(\\sum_{i}^{}h_{wO_i} * k_{wPlace} * k_{machine} * k_{wType} \\right) * k_{wdO} * k_{engineer},\\]"}
                            {"где \\(k_{wPlace}\\) - коэфф. места работы, \\(k_{machine}\\) - коэфф. оборудования, \\(k_{wType}\\) - коэфф. вида работы, \\(k_{wdR}\\) - коэфф. вида дня для регулярных часов, \\(k_{wdO}\\) - коэфф. вида дня для сверхурочных часов, \\(k_{engineer}\\) - персональный коэффициент техника."}
                        </MathJax>
                    </section>

                    <section className="mt-6">
                        <h2>2. Дополнительные часы</h2>

                        <p>Дополнительные часы также делятся на <i>регулярные</i> и <i>сверхурочные</i>. Здесь правила следующие:</p>

                        <ol>
                            <li>В те дни, а) которые являются нерабочими согласно производственному календарю; <b>и</b> б) в которые техник не производил работ; - ничего не добавляется.</li>
                            <li>
                                В <b>командировках</b>, вне зависимости от того, рабочий это день или выходной по производственному календарю, применяется "добивание" часов.
                                <ul>
                                    <li>В схеме "смена" "добиваются" только регулярные часы: до 4 (если в сумме за конкретный день регулярных рабочих часов меньше 4) или до 8 (если в сумме за конкретный день регулярных рабочих часов больше 4, но меньше 8).</li>
                                    <li>В схеме "10-19" "добиваются" и регулярные и сверхурочные часы: до 4 или до 8, в зависимости от того, какова сумма рабочих часов и каких часов больше.</li>
                                </ul>

                                <Table className="table-striped text-center table-xs my-6">
                                    <thead>
                                    <tr>
                                        <th>Схема</th>
                                        <th>Регулярных раб. часов</th>
                                        <th>Сверхуроч. раб. часов</th>
                                        <th>Регулярных доп. часов</th>
                                        <th>Сверхуроч. доп. часов</th>
                                    </tr>
                                    </thead>
                                    <tbody className="font-courier-new">
                                    <tr><td>Смена</td><td>3</td><td>0</td><td>3 ≤ 4 ⇒ 4-3 = 1</td><td>-</td></tr>
                                    <tr><td>Смена</td><td>4</td><td>0</td><td>4 ≤ 4 ⇒ 4-4 = 0</td><td>-</td></tr>
                                    <tr><td>Смена</td><td>5</td><td>0</td><td>4 &lt; 5 ≤ 8 ⇒ 8-5 = 3</td><td>-</td></tr>
                                    <tr><td>Смена</td><td>8</td><td>0</td><td>4 &lt; 8 ≤ 8 ⇒ 8-8 = 0</td><td>-</td></tr>
                                    <tr><td>10-19</td><td>2</td><td>1</td><td>2+1 ≤ 4, 2 ≥ 1 ⇒ 4-(2+1) = 1</td><td>0</td></tr>
                                    <tr><td>10-19</td><td>1</td><td>2</td><td>0</td><td>2+1 ≤ 4, 1 ≤ 2 ⇒ 4-(2+1) = 1</td></tr>
                                    <tr><td>10-19</td><td>2</td><td>2</td><td>2+2 ≤ 4, 2 ≥ 2 ⇒ 4-(2+2) = 0</td><td>0</td></tr>
                                    <tr><td>10-19</td><td>4</td><td>1</td><td>4 &lt; 4+1 ≤ 8, 4 ≥ 1 ⇒ 8-(4+1) = 3</td><td>0</td></tr>
                                    <tr><td>10-19</td><td>3</td><td>4</td><td>0</td><td>4 &lt; 3+4 ≤ 8, 3 ≤ 4 ⇒ 8-(4+3) = 1</td></tr>
                                    <tr><td>10-19</td><td>8</td><td>0</td><td>4 &lt; 8+0 ≤ 8, 8 ≥ 0 ⇒ 8-(8+0) = 0</td><td>0</td></tr>
                                    </tbody>
                                </Table>
                            </li>
                            <li>
                                Во всех остальных случаях (это дни, когда техник работает <b>дома</b>, <b>в офисе</b> или <b>в СПб</b>): в рабочие (в смысле будние) дни регулярные часы "добиваются" до 8, в нерабочие (выходные/праздники) дни регулярные часы "добиваются" до 4.

                                <Table className="table-striped text-center table-xs my-6">
                                    <thead>
                                    <tr>
                                        <th>Будний или нет</th>
                                        <th>Регулярных раб. часов</th>
                                        <th>Сверхурочных раб. часов</th>
                                        <th>Регулярных доп. часов</th>
                                    </tr>
                                    </thead>
                                    <tbody className="font-courier-new">
                                    <tr><td>Будний</td><td>3</td><td>0</td><td>8-3 = 5</td></tr>
                                    <tr><td>Будний</td><td>8</td><td>0</td><td>8-8 = 0</td></tr>
                                    <tr><td>Будний</td><td>4</td><td>5</td><td>8-4 = 4</td></tr>
                                    <tr><td>Выходной</td><td>3</td><td>0</td><td>4-3 = 1</td></tr>
                                    <tr><td>Выходной</td><td>8</td><td>0</td><td>4-8 {"<"} 0 ⇒ 0</td></tr>
                                    <tr><td>Выходной</td><td>4</td><td>5</td><td>4-4 = 0</td></tr>
                                    </tbody>
                                </Table>
                            </li>
                        </ol>

                        <MathJax>
                            {"Если принять \\(b\\) - базовая ставка, \\(h_{сR}\\) - регулярные часы, \\(h_{cO}\\) - сверхурочные часы, то формула этого слагаемого выглядит так:"}
                            {"\\[H_{complement} = b * h_{cR} * k_{cR} * k_{wdR} + b * h_{cO} * k_{cO} * k_{wdR},\\]"}
                            {"где \\(k_{cR}\\) - коэфф. доп. регулярных часов, \\(k_{cO}\\) - коэфф. доп. сверхурочных часов, \\(k_{wdR}\\) - коэфф. вида дня для регулярных часов (здесь имеет смысл общего повышающего коэффициента на выходной день)."}
                        </MathJax>
                    </section>

                    <section className="mt-6">
                        <h2>3. Время в дороге</h2>

                        <p>Здесь также существует <i>регулярные</i> и <i>сверхурочные</i> часы. Правила следующие:</p>

                        <ol>
                            <li>В те дни, а) которые являются нерабочими согласно производственному календарю; <b>и</b> б) в которые техник не производил работ; - все часы, проведенные в дороге, считаются сверхурочными.</li>
                            <li>
                                Во всех остальных случаях регулярными считаются те "дорожные" часы, которые попадают в промежуток от количества регулярных рабочих часов до 8 часов, а те, которые не попадают - сверхурочными.

                                <Table className="table-striped text-center table-xs my-6">
                                    <thead>
                                    <tr>
                                        <th>Регулярных раб. часов</th>
                                        <th>Всего дор. часов</th>
                                        <th>Регулярных дор. часов</th>
                                        <th>Сверхурочных дор. часов</th>
                                    </tr>
                                    </thead>
                                    <tbody className="font-courier-new">
                                    <tr><td>5</td><td>4</td><td>8-5 ≤ 4 ⇒ 8-5 = 3</td><td>8-5 ≤ 4 ⇒ 4-3 = 1</td></tr>
                                    <tr><td>8</td><td>2</td><td>8-8 ≤ 2 ⇒ 8-8 = 0</td><td>8-8 ≤ 2 ⇒ 2-0 = 2</td></tr>
                                    </tbody>
                                </Table>
                            </li>
                        </ol>

                        <MathJax>
                            {"Если принять \\(b\\) - базовая ставка, \\(h_{tR}\\) - регулярные часы, \\(h_{tO}\\) - сверхурочные часы, то формула этого слагаемого выглядит так:"}
                            {"\\[H_{travel} = b * h_{tR} * k_{tR} + b * h_{tO} * k_{tO},\\]"}
                            {"где \\(k_{tR}\\) - коэфф. дорожных регулярных часов, \\(k_{tO}\\) - коэфф. дорожных сверхурочных часов."}
                        </MathJax>
                    </section>

                </article>
            </MathJaxContext>
        );
    }
}


export default WagesAlgorithmExplanationPage;
