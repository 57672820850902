export function getCurrentUser(state) { return state.currentUser; }

export function getClient(state, clientId) { return state.docsById[clientId]; }
export function getMachine(state, machineId) { return state.docsById[machineId]; }
export function getReport(state, reportId) { return state.docsById[reportId]; }

export function getEngineers(state) { return state.engineers; }
export function getClients(state) { return state.docs.filter(doc => doc.type === "client"); }
export function getMachines(state) { return state.docs.filter(doc => doc.type === "machine"); }
export function getReports(state) { return state.docs.filter(doc => doc.type === "report"); }

export function getClientsById(state) { return Object.fromEntries(getClients(state).map(doc => [doc._id, doc])); }
export function getMachinesById(state) { return Object.fromEntries(getMachines(state).map(doc => [doc._id, doc])); }
export function getReportsById(state) { return Object.fromEntries(getReports(state).map(doc => [doc._id, doc])); }

export function getClientsByIdAsMap(state) { return new Map(getClients(state).map(doc => [doc._id, doc])); }
export function getMachinesByIdAsMap(state) { return new Map(getMachines(state).map(doc => [doc._id, doc])); }
export function getEngineersByIsAsMap(state) { return new Map(getEngineers(state).map(doc => [doc._id, doc])); }

export function getActiveClients(state) {
    const clientsWithMachinesIds = new Set();
    getMachines(state).forEach(machine => clientsWithMachinesIds.add(machine.clientId));

    return getClients(state)
        .filter(client => clientsWithMachinesIds.has(client._id))
        .sort((a, b) => a.name.localeCompare(b.name));
}
export function getActiveMachines(state) { return getMachines(state).filter(machine => machine.clientId); }

export function getClientsWithMachines(state) {
    const clientsById = getClientsById(state);

    for (const clientId in clientsById)
        clientsById[clientId] = {...clientsById[clientId], machines: []};

    const machines = getMachines(state);

    for (const machine of machines) {
        if (machine.clientId) {
            clientsById[machine.clientId].machines.push(machine);
        }
    }

    for (const clientId in clientsById)
        clientsById[clientId].machines.sort((a, b) => a.manufacturer.localeCompare(b.manufacturer) || a.model.localeCompare(b.model));

    return Object.values(clientsById).sort((a, b) => a.name.localeCompare(b.name));
}
export function getMachinesWithClients(state) {
    const clientsById = getClientsById(state);

    for (const clientId in clientsById)
        clientsById[clientId] = {...clientsById[clientId]};

    const machines = getMachines(state).map(machine => ({
        ...machine,
        client: machine.clientId ? clientsById[machine.clientId] : undefined
    }));

    return machines.sort((a, b) => a.manufacturer.localeCompare(b.manufacturer) || a.model.localeCompare(b.model) || a.serial.localeCompare(b.serial));
}
