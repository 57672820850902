import React from "react";
import propTypes from 'prop-types';
import {Button, Icon, Input, Radio, Select} from "spectre-react/dist/cjs";
import {ArrayField, ObjectField} from "../../shared/Forms";
import {SLOT_STATE} from "../../shared/sharedImport";


export class SlotReplacements extends React.PureComponent {
    static propTypes = {
        slotReplacements: propTypes.array.isRequired,
        onChange: propTypes.func.isRequired
    };

    static defaultProps = {
        slotReplacements: [],
    };

    SLOT_POSITIONS = [
        <option key="1" value="1">1</option>,
        <option key="2" value="2">2</option>,
        <option key="3" value="3">3</option>,
        <option key="4" value="4">4</option>
    ];

    renderRow(onChange, object, index, onRemove) {
        return (
            <tr>
                <td>
                    <Input type="text" name={`slotReplacements.${index}.phSerial`} value={object.phSerial}
                           onChange={onChange} placeholder="С/н головы"/>
                </td>
                <td>
                    <Input type="text" name={`slotReplacements.${index}.phPosition`} value={object.phPosition}
                           onChange={onChange} placeholder="Позиция головы"/>
                </td>
                <td>
                    <Select name={`slotReplacements.${index}.slotPosition`}
                            value={object.slotPosition} onChange={onChange}>{this.SLOT_POSITIONS}</Select>
                </td>
                <td>
                    <Input type="text" name={`slotReplacements.${index}.slotUnmounted`} value={object.slotUnmounted}
                           onChange={onChange} placeholder="С/н снятого слота"/>
                </td>
                <td>
                    <Input type="text" name={`slotReplacements.${index}.slotMounted`} value={object.slotMounted}
                           onChange={onChange} placeholder="С/н установленного слота"/>
                </td>
                <td>
                    <Radio inline name={`slotReplacements.${index}.mountedSlotState`} value={SLOT_STATE.NEW}
                           checked={object.mountedSlotState === SLOT_STATE.NEW} onChange={onChange}>
                        Новый
                    </Radio>
                    <Radio inline name={`slotReplacements.${index}.mountedSlotState`} value={SLOT_STATE.USED}
                           checked={object.mountedSlotState === SLOT_STATE.USED} onChange={onChange}>
                        Б/у
                    </Radio>
                </td>
                <td>
                    <Button action onClick={onRemove} data-index={index}>
                        <Icon icon="minus"/>
                    </Button>
                </td>
            </tr>
        );
    }

    addNewSlotReplacementsItem = () => {
        return {
            phSerial: '',
            phPosition: '',
            slotPosition: '1',
            slotMounted: '',
            slotUnmounted: '',
            mountedSlotState: SLOT_STATE.USED
        };
    }

    render() {
        return (
            <ArrayField name="slotReplacements" array={this.props.slotReplacements} onChange={this.props.onChange} buildNewItem={this.addNewSlotReplacementsItem}>
                {({array, keys, onAdd, onRemove, onChange}) =>
                    <fieldset className="mb-6">
                        <legend className="h2">
                            Замены слотов&nbsp;
                            <Button action link small onClick={onAdd}>
                                <Icon icon="plus"/>
                            </Button>
                        </legend>

                        <table className="table">
                            <thead><tr>
                                <th>С/н ПГ</th>
                                <th>Позиция ПГ</th>
                                <th>№ слота</th>
                                <th>Снят слот</th>
                                <th>Установлен слот</th>
                                <th>Установлен</th>
                                <th/>
                            </tr></thead>
                            <tbody>{array.map((item, index) =>
                                <ObjectField object={item} onChange={onChange} dummy={index} key={keys[index]}>
                                    {({onChange, object}) => this.renderRow(onChange, object, index, onRemove)}
                                </ObjectField>
                            )}</tbody>
                        </table>
                        <Button primary className="mt-2" onClick={onAdd}>Добавить</Button>
                    </fieldset>}
            </ArrayField>
        );
    }
}


export default SlotReplacements;