import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {endOfQuarter, lightFormat, startOfQuarter} from "date-fns";
import reducer from './reducer';
import pollingMiddleware from "./pollingMiddleware";
import settingsMiddleware from "./settingsMiddleware";
import {ENGINEER_CATEGORY} from "../components/shared/sharedImport";



const DEFAULT_STATE = {
    currentUser: null, // "serg"
    engineers: null, // [{_id: '...', firstName: '', lastName: '', patronym: '', category: ENGINEER_CATEGORY}]
    engineersByCategory: Object.fromEntries(Object.keys(ENGINEER_CATEGORY).map(category => [category, []])),
    engineersById: {},

    isInitializing: false, // true after LOGGED_IN, false after ALL_DOCS_FETCH_SUCCESS or HYDRATE_STATE_FROM_IDB
    allDocsFetch: {
        isInProgress: false,
        progress: 0,  // 0 - 100
        contentLength: 1,  // we just shouldn't divide by zero if anything goes wrong
        error: null
    },
    changesNormalFetch: {
        isInProgress: false,
        error: null,
    },
    changesFeedFetch: {
        isInProgress: false,
        error: null,
    },
    docs: [],
    docsById: [],
    docsHash: 0,
    lastSeq: null,

    filters: [],
    wagesEngineersTablePage: {
        startDate: lightFormat(startOfQuarter(new Date()), 'yyyy-MM-dd'),
        endDate: lightFormat(endOfQuarter(new Date()), 'yyyy-MM-dd'),

        displayType: "twoTables",
        showStats: true,
        timespanMode: 'quarter',
    }
};

//     filters: [
//         {label: 'clients', value: []},
//         {label: 'machines', value: []},
//         {label: 'engineers', value: []},
//         {label: 'status', value: "1"},
//         {label: 'date', value: []},
//         {label: 'type': value: '1'},
//         {label: 'payment', value: "1"},
//         {label: 'replacements', value: true},
//         {label: 'words', value: ''}
//     ],


const store = createStore(reducer, DEFAULT_STATE, applyMiddleware(thunk, pollingMiddleware, settingsMiddleware));

export default store;