import React from 'react';
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import ReportForm from "./ReportForm/ReportForm";
import {deleteReport, postReport} from "../../api";
import {REPORTS_PATH} from "../../paths";
import {getMachine, getReport} from "../../selectors";


class EditReportPage extends React.Component {
    state = {
        submitState: 0, submitError: null,
        destroyState: 0, destroyError: null,
        report: this.props.report
    };

    componentDidUpdate(prevProps) {
        if (this.props.match.params.reportId !== prevProps.match.params.reportId)
            this.setState({report: null, loading: true, error: ''}, this.componentDidMount);
    }

    onChange = (ev) => {
        const report = {...this.state.report};

        if (Array.isArray(ev.target))
            for (const target of ev.target)
                report[target.name] = target.value;
        else
            report[ev.target.name] = ev.target.value;

        this.setState({submitError: null, report});
    };

    onSubmit = async (ev) => {
        this.setState({submitError: null, submitState: 1});

        try {
            const report = this.state.report;
            await postReport(report);

            this.setState({submitError: null, submitState: 2});
        } catch (error) {
            this.setState({submitError: error.message, submitState: 0})
        }
    };

    onDestroy = async () => {
        if (!window.confirm('Точно удалить?'))
            return;

        this.setState({destroyError: null, destroyState: 1});

        const {_id, _rev} = this.state.report;

        try {
            await deleteReport(_id, _rev);

            this.setState({destroyError: null, destroyState: 2});
        } catch (error) {
            this.setState({destroyError: error.message, destroyState: 0})
        }
    };

    render() {
        if (this.state.submitState === 2) {
            alert("Изменения успешно внесены.");
            return <Redirect to={REPORTS_PATH} />;
        }

        if (this.state.destroyState === 2) {
            alert("Репорт успешно удален.");
            return <Redirect to={REPORTS_PATH} />;
        }

        const {report, submitState, submitError, destroyState, destroyError} = this.state;
        const isOldReport = this.props.isOldReport;

        return (
            <div>
                <h1>Редактирование репорта</h1>

                <ReportForm key={report?._id || 'whatever'}
                            isSubmitting={submitState === 1} submitError={submitError}
                            isDestroying={destroyState === 1} destroyError={destroyError}
                            onSubmit={this.onSubmit} onDestroy={this.onDestroy}
                            report={report} onChange={this.onChange} isOldReport={isOldReport}
                />
            </div>
        )
    }
}


function mapStateToProps(state, ownProps) {
    const reportId = ownProps.match.params.reportId;
    const report = getReport(state, reportId);
    const machine = getMachine(state, report.machineId);

    return {
        report: getReport(state, reportId),
        isOldReport: machine.clientId !== report.clientId
    }
}


export default connect(mapStateToProps)(EditReportPage);