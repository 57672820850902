import React from 'react';
import PropTypes from 'prop-types';
import {ArrayField, ObjectField, DateInput, TimeInput} from "../../shared/Forms";
import {formatDate1, parseDate, TRIP_KIND, TRIP_KIND_ICON} from '../../shared/sharedImport';
import {addDays, lightFormat, subDays} from "date-fns";
import {connect} from "react-redux";
import {fetchTransportTrips} from "../../../api";
import {TRANSPORT_EMAILS_PATH} from "../../../paths";
import {Link} from "react-router-dom";
import {Button, Checkbox, Icon, Select} from "spectre-react/dist/cjs";


class TransportEmailsExplanationModal extends React.Component {
    state = { isOpened: false };

    open = () => this.setState({ isOpened: true });
    close = () => this.setState({ isOpened: false });

    render() {
        if (!this.state.isOpened)
            return null;

        return (
            <div className="modal active" id="transport-addresses-modal">
                <span className="modal-overlay" onClick={this.close}/>
                <div className="modal-container modal-fullheight">
                    <div className="modal-header">
                        <button type="button" className="btn btn-clear float-right" onClick={this.close}/>
                        <div className="modal-title h5">Информация</div>
                    </div>

                    <div className="modal-body">
                        <ul>
                            <li>Функция "Заполнить автоматически" подтягивает информацию из писем, пересланных на ящик <span className="text-bold">transport@signart.ru</span>.</li>
                            <li>После нажатия на соответствующую кнопку таблица заполняется информацией из писем. <span className="text-bold">При этом все данные в таблицe будут заменены, а не добавлены в конец или в начало.</span></li>
                            <li>Информация может быть неполной – нужных писем может просто не быть в ящике, в парсере есть ошибка, подходящего парсера попросту нет, изменился шаблон письма т.д.</li>
                            <li>Будут отображены поездки только из тех писем, которые привязаны к текущему пользователю. К текущему пользователю привязаны только те письма, которые пришли с привязанных к пользователю email-адресов. Список этих адресов можно посмотреть и изменить на <Link to={TRANSPORT_EMAILS_PATH}>странице с письмами</Link>.</li>
                            <li>Поэтому после автоматического заполнения нужно просмотреть таблицу и добавить недостающее.</li>
                            <li>Порядок строк значения не имеет, строки таблицы не обязаны идти в хронологическом порядке, однако пересечений времени быть не должно. В любом случае, для удобства между строками есть кнопка, нажатие на которую добавляет дополнительную строку в этом месте, а не в конце таблицы.</li>
                        </ul>
                    </div>

                    <div className="modal-footer">
                        <Button primary block onClick={this.close}>Понятно</Button>
                    </div>
                </div>
            </div>
        )
    }
}


class TravelHoursTable extends React.PureComponent {
    static propTypes = {
        engineerIds: PropTypes.arrayOf(PropTypes.string),
        travelHours: PropTypes.arrayOf(PropTypes.shape({
            startDate: PropTypes.string,
            startTime: PropTypes.string,
            endDate: PropTypes.string,
            endTime: PropTypes.string,
            category: PropTypes.string,
        })),
        onChange: PropTypes.func
    };

    static CATEGORIES = [
        {name: 'PLANE', text: 'САМОЛЕТ'},
        {name: 'TRAIN', text: 'ПОЕЗД'},
        {name: 'TAXI', text: 'ТАКСИ'},
    ]

    addressesModal = React.createRef();

    addNewTravelHoursItem = () => {
        let travelHours = this.props.travelHours;
        let date = lightFormat(new Date(), 'yyyy-MM-dd');

        if (travelHours.length > 0) {
            const lastItem = travelHours[travelHours.length - 1];

            if (lastItem.endDate)
                date = lastItem.endDate;
        }

        const engineerIds1 = this.props.engineerIds;
        const engineerIds = Object.fromEntries(engineerIds1.map(engineerId => [engineerId, true]));
        return {startDate: date, startTime: '', endDate: date, endTime: '', category: TravelHoursTable.CATEGORIES[0].name, engineerIds};
    }

    fetchTransportTrips = async () => {
        const workHours = this.props.workHours;
        const startDate = lightFormat(subDays(new Date(workHours.at(0).startDate), 1), 'yyyy-MM-dd');
        const endDate = lightFormat(addDays(new Date(workHours.at(-1).endDate), 1), 'yyyy-MM-dd');
        return await fetchTransportTrips(startDate, endDate);
    }
    fillTable = async () => {
        const trips = await this.fetchTransportTrips();

        const engineerIds = Object.fromEntries(this.props.engineerIds.map(engineerId => [engineerId, true]));

        const travelHours = trips.map(trip => ({
            startDate: trip.startDate,
            startTime: trip.startTime,
            endDate: trip.endDate,
            endTime: trip.endTime,
            category: trip.tripKind,  // ACHTUNG: yes, right now it's the same enum
            bookedAt: trip.bookedAt,
            additionalInfo: `${trip.startAddress} ⇒ ${trip.endAddress}`,
            engineerIds: {...engineerIds},
        }));

        const ev = {target: {name: 'travelHours', value: travelHours}};
        this.props.onChange(ev);
    }
    openModal = () => this.addressesModal.current.open();

    onInsertAfterItemCopy = (modifiedArray, newItemIndex) => {
        const item = {...modifiedArray[newItemIndex]};

        if (!item.additionalInfo)
            return modifiedArray;

        item.additionalInfo = undefined;

        modifiedArray = [
            ...modifiedArray.slice(0, newItemIndex),
            item,
            ...modifiedArray.slice(newItemIndex + 1)
        ];

        return modifiedArray
    }

    render() {
        const engineerIds = this.props.engineerIds;

        return (
            <ArrayField name="travelHours" array={this.props.travelHours} onChange={this.props.onChange} buildNewItem={this.addNewTravelHoursItem} onInsertAfterItemCopy={this.onInsertAfterItemCopy}>
                {({array, keys, onAdd, onRemove, onChange, onInsert}) =>
                    <fieldset className="mb-6" id="travel-hours-table">
                        <legend className="h2">
                            Время на дорогу&nbsp;

                            <Button action link small className="ml-2" onClick={onAdd}>
                                <Icon icon="plus"/>
                            </Button>

                            <Button small link className="btn-material-icon ml-2" onClick={this.fillTable}>
                                <span className="material-icons">content_paste_go</span> <span>Заполнить автоматически</span>
                            </Button>
                        </legend>

                        <TransportEmailsExplanationModal ref={this.addressesModal} />
                        <Button small onClick={this.openModal}>
                            Что значит "Заполнить автоматически"?
                        </Button>

                        <div className="mt-4">
                            <div className="d-flex mb-3 text-bold text-uppercase">
                                <div>Старт</div>
                                <div className="mx-2">—</div>
                                <div>Финиш</div>
                                <div className="mx-2">—</div>
                                <div>Категория</div>
                                <div className="mx-2">—</div>
                                <div>Специалисты (если больше 1)</div>
                                <div className="mx-2">—</div>
                                <div>Удалить</div>
                            </div>
                            {array.map((item, index) => {
                                const showInsertButton = index < array.length - 1;
                                const key = keys[index] + Number(showInsertButton);

                                return <TravelHoursItem index={index} key={key} engineerIds={engineerIds}
                                                        item={item} onChange={onChange} onRemove={onRemove}
                                                        showInsertButton={showInsertButton} onInsert={onInsert}
                                />;
                            })}
                        </div>

                        <Button primary className="mt-2" onClick={onAdd}>Добавить</Button>
                    </fieldset>
                }
            </ArrayField>
        )
    }
}


class TravelHoursItem extends React.PureComponent {
    static propTypes = {
        engineerById: PropTypes.object,
        engineerIds: PropTypes.arrayOf(PropTypes.string),
        index: PropTypes.number,
        item: PropTypes.object,
        onChange: PropTypes.func,
        onRemove: PropTypes.func,
        onInsert: PropTypes.func,
        showInsertButton: PropTypes.bool,
        dummy: PropTypes.number
    }

    onChange = (ev) => {
        const item = ev.target.value;

        const {startTime, endTime} = ev.target.value;
        const startTimeValid = startTime && TimeInput.isValidValue(startTime);
        const endTimeValid = endTime && TimeInput.isValidValue(endTime);

        if (startTimeValid && endTimeValid && endTime.localeCompare(startTime) < 0)
            item.endDate = formatDate1(addDays(parseDate(item.startDate), 1));
        else
            item.endDate = item.startDate;

        this.props.onChange(ev);
    }

    onEngineerIdsChange = (ev) => {
        const {name, checked} = ev.target;
        const [,indexStr,,engineerId] = name.split('.');
        const index = parseInt(indexStr, 10);

        const item = this.props.item;
        const itemWithChanges = {...item, engineerIds: {...item.engineerIds}};
        itemWithChanges.engineerIds[engineerId] = checked;

        this.onChange({target: {name: `travelHours.${index}`, value: itemWithChanges}});
    }

    render() {
        const {engineersById, engineerIds, index, item, onRemove, showInsertButton, onInsert} = this.props;

        return (
            <ObjectField object={item} onChange={this.onChange} dummy={index}>
                {({onChange, object}) =>
                    <React.Fragment>
                        <div className="d-flex travel-hours-table-row">
                            <div className="flex-1">
                                <DateInput name={`travelHours.${index}.startDate`} className="form-input input-sm" placeholder="Пример: 26.03.2015" value={object.startDate} onChange={onChange}/>
                            </div>
                            <div className="flex-1 ml-2">
                                <TimeInput name={`travelHours.${index}.startTime`} className="form-input input-sm" placeholder="Пример: 10:00" value={object.startTime} onChange={onChange}/>
                            </div>
                            <div className="ml-2">—</div>
                            <div className="flex-1 ml-2">
                                <DateInput name={`travelHours.${index}.endDate`} className="form-input input-sm" value={object.endDate} onChange={onChange}/>
                            </div>
                            <div className="flex-1 ml-2">
                                <TimeInput name={`travelHours.${index}.endTime`} className="form-input input-sm" placeholder="Пример: 18:00" value={object.endTime} onChange={onChange}/>
                            </div>
                            <div className="ml-2">—</div>
                            <div className="flex-1 ml-2">
                                <Select small name={`travelHours.${index}.category`} value={object.category} onChange={onChange}>
                                    <option value={TRIP_KIND.TAXI}>ТАКСИ</option>
                                    <option value={TRIP_KIND.PLANE}>САМОЛЕТ</option>
                                    <option value={TRIP_KIND.TRAIN}>ПОЕЗД</option>
                                </Select>
                            </div>
                            {engineerIds.length > 1 && <div className="ml-2">—</div>}
                            {engineerIds.length > 1 && <div className="ml-2">
                                {engineerIds.map(engineerId => (
                                    <Checkbox inline className="my-0" key={engineerId}
                                              name={`travelHours.${index}.engineerIds.${engineerId}`}
                                              checked={item.engineerIds[engineerId]}
                                              onChange={this.onEngineerIdsChange}>
                                        {engineersById[engineerId].nameLfp0}
                                    </Checkbox>
                                ))}
                            </div>}
                            <div className="ml-2">—</div>
                            <div className="ml-2">
                                <Button action small onClick={onRemove} data-index={index}>
                                    <Icon icon="minus"/>
                                </Button>
                            </div>
                        </div>
                        {object.additionalInfo &&
                            <div className="form-input-hint">
                                <span className="material-icons">{TRIP_KIND_ICON[object.category]}</span>
                                <span className="ml-2">{object.additionalInfo}</span>
                            </div>
                        }
                        {showInsertButton && <div className="btn-add-item" data-index-before={index + 1} onClick={onInsert}></div>}
                    </React.Fragment>
                }
            </ObjectField>
        )
    }
}
TravelHoursItem = connect(({engineersById}) => ({engineersById}), null)(TravelHoursItem);


export default TravelHoursTable;