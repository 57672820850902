import React from "react";
import {connect} from "react-redux";
import propTypes from 'prop-types';
import axios from "axios";
import filesize from "filesize";
import {ArrayField, DateInput, ObjectField} from "../../shared/Forms";
import {fetchFile, uploadFile} from "../../../api";
import {FilePropType, basename, dirname, pluralize, EXPENSE, EXPENSE_LABEL,formatDate1, date1to2} from '../../shared/sharedImport';
import {Button, Icon, Input, Select} from "spectre-react/dist/cjs";


function cancelFileUploads(files) {
    for (const file of files)
        if (file.abortController)
            file.abortController.abort();
}

function UploadFilesButton({name, onChange}) {
    return (
        <div>
            <input type="file" multiple id={name} onChange={onChange}/>
            <label htmlFor={name} className="btn btn-primary">
                <Icon icon="upload"/> Выбрать файлы
            </label>
        </div>
    )
}

class File extends React.PureComponent {
    static propTypes = {
        index: propTypes.number,
        onRemove: propTypes.func,
        file: propTypes.oneOfType([
            propTypes.shape({
                key: propTypes.string,
                loaded: propTypes.number,
                total: propTypes.number,
                abortController: propTypes.object, // axios.CancelToken.source()
                originalname: propTypes.string,
                size: propTypes.number,
                mimetype: propTypes.string
            }),
            propTypes.shape({
                originalname: propTypes.string,
                size: propTypes.number,
                mimetype: propTypes.string,
                path: propTypes.string
            })
        ])
    };

    renderUploadingFile() {
        const {key, originalname, loaded, total} = this.props.file;
        const progress = Math.round(loaded / total * 100);

        return (
            <div className="tile tile-centered file-tile form-group file-uploading">
                <div className="tile-icon loading"/>
                <div className="tile-content">
                    <div className="tile-title">{originalname}</div>
                    <div className="tile-subtitle">
                        <progress className="progress" value={progress} max="100"/>
                    </div>
                </div>
                <div className="tile-action">
                    <Button link onClick={this.onRemove} data-index={this.props.index}>
                        <Icon icon="stop"/>
                    </Button>
                </div>
            </div>
        );
    };

    renderUploadedFile() {
        const {size, originalname, path, mimetype, uploadedAt} = this.props.file;
        const filename = basename(path);
        const dir = basename(dirname(path));
        const uploadedAtDate = date1to2(uploadedAt.slice(0, 10));
        const href = `/api/files?path=${dir}/${filename}`;

        return (
            <div className="tile tile-centered file-tile form-group">
                <a href={href} download={originalname} className="tile-icon" onClick={fetchFile}>
                    <Icon icon="download"/>
                </a>
                <div className="tile-content">
                    <div className="tile-title">{originalname}</div>
                    <small className="tile-subtitle text-gray">{filesize(size)} · {mimetype} · {uploadedAtDate}</small>
                </div>
                <div className="tile-action">
                    <Button link onClick={this.onRemove} data-index={this.props.index}>
                        <Icon icon="cross"/>
                    </Button>
                </div>
            </div>
        )
    };

    onRemove = (ev) => {
        cancelFileUploads([this.props.file]);
        this.props.onRemove(ev);
    };

    render() {
        if (this.props.file.key)
            return this.renderUploadingFile();
        else
            return this.renderUploadedFile();
    }
}

class ExpenseFiles extends React.PureComponent {
    static propTypes = {
        name: propTypes.string,
        files: propTypes.arrayOf(FilePropType),
        onChange: propTypes.func,
        onRemove: propTypes.func
    };

    state = {opened: false};

    toggleModal = () => this.setState(({opened}) => ({opened: !opened}));

    onRemove = (ev) => {
        const index = parseInt(ev.currentTarget.dataset.index, 10);
        const files = this.props.files.slice();
        files.splice(index, 1);
        this.onChange(files);
    };

    onChange = (value) => this.props.onChange({target: {name: this.props.name, value}});

    onUploadProgress = (key) => {
        return (smth) => {
            const index = this.props.files.findIndex(file => file.key === key);
            if (index !== -1) {
                const files = this.props.files.slice();
                files[index] = (smth.loaded !== undefined && smth.total !== undefined)
                    ? {...files[index], loaded: smth.loaded, total: smth.total} /* smth is progress event */
                    : smth.data; /* uploaded, smth is axios response.data */
                this.onChange(files);
            }
        }
    };

    startUploading = (ev) => {
        const files = Array.from(ev.target.files).map(({name, size, type}) => ({
            key: Math.random().toString(36).slice(2),
            loaded: 0,
            total: 1,
            abortController: new AbortController(),
            originalname: name,
            size: size,
            mimetype: type
        }));

        this.onChange([...this.props.files, ...files]);

        for (let i = 0; i < files.length; ++i) {
            const data = new FormData();
            data.append('file', ev.target.files[i]);

            const callback = this.onUploadProgress(files[i].key);
            const options = {
                onUploadProgress: callback,
                signal: files[i].abortController.signal,
            };
            uploadFile(data, options)
                .then(callback)
                .catch(error => {
                    if (!axios.isCancel(error))
                        alert(error.message);
                    else
                        console.warn('[File] cancelled');
                });
        }
    };

    getProgress() {
        const files = this.props.files;
        const loaded = files.reduce((acc, file) => acc + file.loaded || file.size, 0);
        const total = files.reduce((acc, file) => acc + file.total || file.size, 0);
        return Math.round(loaded / total * 100);
    }

    render() {
        const files = this.props.files;
        const length = files.length;

        if (length === 0)
            return <UploadFilesButton name={this.props.name} onChange={this.startUploading}/>;

        if (!this.state.opened) {
            let header = `${length} ${pluralize(length, null, 'файл', 'файла', 'файлов')}`;
            const progress = this.getProgress();
            if (progress < 100) header += `, ${progress}%`;

            return (
                <Button link small onClick={this.toggleModal}>
                    {header}
                </Button>
            );
        }

        return (
            <div className="modal modal-sm active" id="expenses-files-modal">
                <a className="modal-overlay" onClick={this.toggleModal}/>
                <div className="modal-container modal-fullheight">
                    <div className="modal-body">
                        {files.map((file, index) => <File key={file.key || file.path} index={index} file={file} onRemove={this.onRemove}/>)}
                    </div>
                    <div className="modal-footer">
                        <UploadFilesButton name={this.props.name} onChange={this.startUploading}/>
                        <Button link className="ml-2" onClick={this.toggleModal}>Закрыть</Button>
                    </div>
                </div>
            </div>
        )
    }
}

class Expense extends React.PureComponent {
    static propTypes = {
        index: propTypes.number,
        object: propTypes.object,
        onChange: propTypes.func,
        onRemove: propTypes.func,
    };

    static OPTIONS = Object.entries(EXPENSE).map(([key, value]) => <option key={key} value={value}>{EXPENSE_LABEL[key]}</option>);

    isTypeWithDate() {
        const type = this.props.object.type;
        return type === EXPENSE.AIR_RAIL || type === EXPENSE.TAXI || type === EXPENSE.OTHER;
    }

    onRemove = (ev) => {
        cancelFileUploads(this.props.object.files);
        this.props.onRemove(ev);
    };

    render() {
        const {index, object, onChange} = this.props;

        return (
            <tr>
                <td>
                    <Select name={`expenses.${index}.type`} value={object.type} onChange={onChange}>{Expense.OPTIONS}</Select>
                </td>
                <td>
                    {this.isTypeWithDate()
                        ? <DateInput className="form-input" name={`expenses.${index}.date`} value={object.date} placeholder="Пример: 26.03.2015" onChange={onChange}/>
                        : "(сумма за всю командировку)"
                    }
                </td>
                <td>
                    <Input type="number" min="0" step="0.01" name={`expenses.${index}.amount`}
                           placeholder="Пример: 32463.50" value={object.amount} onChange={onChange} />
                </td>
                <td>
                    <Input type="text" name={`expenses.${index}.desc`}
                           value={object.desc} onChange={onChange} />
                </td>
                <td>
                    <ExpenseFiles name={`expenses.${index}.files`} files={object.files} onChange={onChange}/>
                </td>
                <td>
                    <Button action onClick={this.onRemove} data-index={index}>
                        <Icon icon="minus"/>
                    </Button>
                </td>
            </tr>
        )
    }
}

class Expenses extends React.PureComponent {
    static propTypes = {
        expenses: propTypes.array.isRequired,
        onChange: propTypes.func.isRequired,
        currentUser: propTypes.object.isRequired,
    };

    static defaultProps = {
        expenses: []
    };

    componentWillUnmount() {
        for (const item of this.props.expenses)
            cancelFileUploads(item.files);
    }

    onChange = (ev) => {
        if (ev.type === ArrayField.EVENT_TYPES.ADD && ev.target.value.length > 1) {
            // when adding new item (second and so on), set the date
            const items = ev.target.value;
            items[items.length - 1].date = items[items.length - 2].date;
        }
        this.props.onChange(ev);
    };

    addExpenseItem = () => {
        return {type: EXPENSE.AIR_RAIL, date: formatDate1(new Date()), desc: '', amount: '', files: []};
    }

    render() {
        const shouldRenderTable = this.props.currentUser.isManagement;

        return (
            <ArrayField name="expenses" array={this.props.expenses} onChange={this.onChange} buildNewItem={this.addExpenseItem}>
                {({array, keys, onRemove, onChange}) =>
                    <fieldset className="mb-6">
                        <legend className="h2">Расходы</legend>

                        <p>Теперь заносятся в <a href="http://asana.com" rel="noopener noreferrer nofollow" target="_blank">асану</a>.</p>

                        {shouldRenderTable && array.length > 0 && <table className="table">
                            <thead><tr><th>Вид</th><th>Дата</th><th>Сумма</th><th>Описание</th><th>Документы</th><th/></tr></thead>
                            <tbody>
                            {array.map((item, index) =>
                                <ObjectField key={keys[index]} object={item} onChange={onChange} dummy={index}>
                                    {({onChange, object}) => <Expense index={index} object={object} onChange={onChange} onRemove={onRemove} />}
                                </ObjectField>)}
                            </tbody>
                        </table>}
                    </fieldset>
                }
            </ArrayField>
        );
    }
}


function mapStateToProps(state) {
    return {
        currentUser: state.currentUser,
    }
}

export default connect(mapStateToProps)(Expenses);