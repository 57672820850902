import React from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {endOfMonth, lightFormat, startOfMonth} from "date-fns";
import cx from "classnames";
import {Divider, Loading, Toast, Button, Icon} from "spectre-react/dist/cjs";
import {
    fetchTransportDocs,
    deleteTransportDocAndTrashEmail,
    scanTransportInbox,
} from "../../api";
import TimeNavigation from "../shared/TimeNavigation";
import TransportAddressesModal from "./TransportAddressesModal";
import {ENGINEER_CATEGORY, TRIP_KIND_ICON, BOOKED_AT_ICON, debounce} from "../shared/sharedImport";


class TransportEmailsPage extends React.Component {
    state = {
        fetchState: 0, fetchError: undefined,  // 0 = not loaded, 1 = loading, 2 = loaded
        deleteState: 0, deleteError: undefined,  // 0 = not loading, 1 = loading, 2 = loaded
        scanState: 0, scanError: undefined,
        startDate: startOfMonth(new Date()), endDate: endOfMonth(new Date()),
        emails: [],
    };

    async componentDidMount() {
        await this.fetchData();
    }

    prepareData(emails) {
        const engineersById = this.props.engineersById;

        for (const email of emails) {
            email.envelope.date = new Date(email.envelope.date);

            if (email.trips) {
                for (const trip of email.trips) {
                    const {startDate, startTime, endDate, endTime} = trip;
                    trip.start = new Date(`${startDate}T${startTime}`);
                    trip.end = new Date(`${endDate}T${endTime}`);
                }
            }

            if (email.userId === undefined)
                email.userId = null;

            if (email.userId) {
                email.user = engineersById[email.userId];
            }

            for (const attachment of email.attachments) {
                if (attachment.envelope)
                    attachment.envelope.date = new Date(attachment.envelope.date);

                if (attachment.trips) {
                    for (const trip of attachment.trips) {
                        const {startDate, startTime, endDate, endTime} = trip;
                        trip.start = new Date(`${startDate}T${startTime}`);
                        trip.end = new Date(`${endDate}T${endTime}`);
                    }
                }
            }
        }
    }

    async fetchData() {
        this.setState({fetchState: 1, fetchError: undefined});

        try {
            const startDate = lightFormat(this.state.startDate, "yyyy-MM-dd");
            const endDate = lightFormat(this.state.endDate, "yyyy-MM-dd");
            const emails = await fetchTransportDocs(startDate, endDate);
            this.prepareData(emails);
            this.setState({fetchState: 2, emails});
        } catch (error) {
            console.error(error);
            alert(error.message);
            this.setState({fetchState: 0, fetchError: error.message});
        }
    }

    _onTimespanChange = ({startDate, endDate}) => this.setState({startDate, endDate}, this.fetchData);
    onTimespanChange = debounce(this._onTimespanChange, 500);

    doRefresh = async () => {
        this.setState({scanState: 1, scanError: undefined});

        try {
            await scanTransportInbox();
            this.setState({scanState: 2, scanError: undefined});
            await this.fetchData();
        } catch (error) {
            alert(error.message);
            console.error(error);
            this.setState({scanState: 0, scanError: error.message});
        }
    }

    deleteTransportDocAndTrashEmail = async (ev) => {
        this.setState({deleteState: 1, deleteError: undefined});

        const docId = ev.currentTarget.parentElement.parentElement.dataset.docId;
        const doc = this.state.emails.find(doc => doc._id === docId);
        const {_rev: docRev, uid: emailUID} = doc;

        try {
            await deleteTransportDocAndTrashEmail(docId, docRev, emailUID);

            this.setState({deleteState: 2, deleteError: undefined});
            await this.fetchData();
        } catch (error) {
            alert(error.message);
            console.error(error);
            this.setState({deleteState: 0, deleteError: error.message});
        }
    }

    render() {
        const {
            fetchState, scanState, deleteState,
            fetchError,
            emails,
        } = this.state;

        if (fetchError)
            return <Toast error>{fetchError}</Toast>;

        const engineersById = this.props.engineersById;
        const showManagerActions = this.props.currentUser.isManagement;

        let displayedEmails = emails;

        const isFetching = fetchState === 1;
        const isDeleting = deleteState === 1;
        const isScanning = scanState === 1;

        return <EmailsList emails={displayedEmails} showManagerActions={showManagerActions}
                           engineersById={engineersById}
                           onTimespanChange={this.onTimespanChange}
                           doTrash={this.deleteTransportDocAndTrashEmail} doRefresh={this.doRefresh}
                           isScanning={isScanning} isFetching={isFetching} isDeleting={isDeleting}
        />;
    }
}

class EmailsList extends React.Component {
    static parsedDataPropType = PropTypes.shape({
        start: PropTypes.instanceOf(Date),
        end: PropTypes.instanceOf(Date),
        startDate: PropTypes.string,
        startTime: PropTypes.string,
        endDate: PropTypes.string,
        endTime: PropTypes.string,
        startAddress: PropTypes.string,
        endAddress: PropTypes.string,
        cost: PropTypes.string,
        bookedAt: PropTypes.oneOf(["YANDEX_GO", "S7", "RZD"]),
        tripKind: PropTypes.oneOf(["TAXI", "PLANE", "TRAIN"]),
    });

    static envelopePropType = PropTypes.shape({
        date: PropTypes.instanceOf(Date),
        subject: PropTypes.string,
        from: PropTypes.shape({
            name: PropTypes.string,
            address: PropTypes.string,
        }),
        messageId: PropTypes.string,
    });

    static propTypes = {
        emails: PropTypes.arrayOf(PropTypes.shape({
            _id: PropTypes.string.isRequired,
            _rev: PropTypes.string,
            userId: PropTypes.string,
            uid: PropTypes.number.isRequired,
            size: PropTypes.number.isRequired,
            envelope: EmailsList.envelopePropType.isRequired,
            attachments: PropTypes.arrayOf(PropTypes.shape({
                contentType: PropTypes.string.isRequired,
                contentDisposition: PropTypes.string.isRequired,
                filename: PropTypes.string.isRequired,
                checksum: PropTypes.string.isRequired,
                size: PropTypes.number.isRequired,
                envelope: EmailsList.envelopePropType,
                data: PropTypes.arrayOf(EmailsList.parsedDataPropType),
            })),
            data: PropTypes.arrayOf(EmailsList.parsedDataPropType),
        }))
    };

    addressesModal = React.createRef();

    renderFrom(email) {
        const from = `${email.envelope.from.name} <${email.envelope.from.address}>`;
        const subject = email.envelope.subject;
        const {name: fromName, address: fromAddress} = email.envelope.from;
        const user = email.user;

        return (
            <div>
                {user && <div className="text-bold text-hover-primary c-hand">{user.nameLfp}</div>}
                {!user && <div className="text-ellipsis text-primary" title={from}>{fromName}&nbsp;{`<${fromAddress}>`}</div>}
                <div className="text-ellipsis" title={subject}>{subject}</div>
                <div>{lightFormat(email.envelope.date, 'dd.MM.yyyy HH:mm')}</div>
            </div>
        )
    }

    renderEmailTrips(trips, index = 0) {
        return trips.map((item, innerIndex) => {
            const tripKindIconName = TRIP_KIND_ICON[item.tripKind];
            const logoPath = BOOKED_AT_ICON[item.bookedAt];
            const start = lightFormat(item.start, 'dd.MM.yyyy HH:mm');
            const end = item.startDate === item.endDate ? item.endTime : lightFormat(item.end, 'dd.MM.yyyy HH:mm');

            return (
                <div key={`${index}${innerIndex}`} className="d-flex">
                    <span className="material-icons mr-1">{tripKindIconName}</span>
                    {/*<img className="booked-at-logo mr-2" src={logoPath} alt={item.bookedAt} />*/}
                    <div className="flex-1">
                        <div>
                            <span className="mr-2">{start}</span>—<span className="ml-2">{end}</span>
                            <span className="ml-4 text-small text-gray">{item.cost}</span>
                            <img className="booked-at-logo mr-2" src={logoPath} alt={item.bookedAt} />
                        </div>
                        <div className="text-small text-gray">
                            <span className="mr-2">{item.startAddress}</span>⇒<span className="ml-2">{item.endAddress}</span>
                        </div>
                        <div className="text-small text-gray text-preline">
                            {item.additionalInfo}
                        </div>
                    </div>
                </div>
            )
        })
    }

    renderTable() {
        const {emails, showManagerActions, doTrash, isDeleting, isScanning, isFetching} = this.props;
        const isLoading = isFetching || isScanning;

        return (
            <table className="table table-striped">
                <thead>
                <tr>
                    <th>№</th>
                    <th>From</th>
                    <th>Data</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {emails.map(email => {
                    const attachments = email.attachments;
                    const rootTrip = email.trips && email.trips.length > 0 ? this.renderEmailTrips(email.trips) : null;
                    const attachmentTrips = attachments.filter(item => item.trips && item.trips.length > 0).map((item, index) => this.renderEmailTrips(item.trips, index))

                    return (
                        <tr key={email._id} data-doc-id={email._id}>
                            <td>{email.uid}</td>
                            <td>
                                {this.renderFrom(email)}
                            </td>
                            <td>
                                {email.parsingFailed && this.renderErrorMessage()}
                                {rootTrip}
                                {attachmentTrips.length > 0 && !!rootTrip && <Divider />}
                                {attachmentTrips}
                            </td>
                            <td>
                                {(email.isMine || showManagerActions) &&
                                    <Button link action disabled={isLoading} loading={isDeleting} onClick={doTrash}>
                                        <span className="material-icons">delete_outline</span>
                                    </Button>
                                }
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }

    renderErrorMessage() {
        return (
            <div>
                <span className="material-icons valign-middle">error_outline</span>
                <span className="material-icons valign-middle">report_gmailerrorred</span>
                <span className="material-icons valign-middle">warning_amber</span>
            </div>
        )
    }

    renderEmptyMessage() {
        return (
            <div className="empty">
                <div className="empty-icon">
                    <Icon icon="mail" size="3x"/>
                </div>
                <p className="empty-title h5">Ни одного письма не найдено</p>
                <p className="empty-subtitle">Попробуйте изменить месяц или переслать письмо
                    на <b>transport@signart.ru</b></p>
                <p className="empty-subtitle">Здесь будут отображаться только <b>ваши</b> письма</p>
            </div>
        )
    }

    render() {
        const {isFetching, isScanning, emails, doRefresh, onTimespanChange} = this.props;
        const isLoading = isFetching || isScanning;

        return (
            <div id="transport-emails-page">
                <TransportAddressesModal ref={this.addressesModal} />

                <div className="columns my-4">
                    <div className="column col-2">
                        <Button primary block loading={isScanning} onClick={doRefresh}>Заглянуть в ящик</Button>
                    </div>
                    <div className="column col-2">
                        <Button primary block onClick={this.addressesModal.current?.open}>Мои адреса</Button>
                    </div>
                    <div className="column col-3">
                        <TimeNavigation showSelect={false} defaultMode="month" onChange={onTimespanChange} />
                    </div>
                    <div className="column col-5">
                        {isLoading && <Loading className="loading-md float-right" />}
                    </div>
                </div>

                {emails.length > 0 && this.renderTable()}
                {emails.length === 0 && this.renderEmptyMessage()}
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        engineers: state.engineersByCategory[ENGINEER_CATEGORY.ACTIVE],
        currentUser: state.currentUser,
        engineersById: state.engineersById
    }
}


export default connect(mapStateToProps, null)(TransportEmailsPage);