import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import memoize from "memoize-one";
import {Icon, Input, Loading, Toast, Select} from "spectre-react/dist/cjs";
import {NEW_CLIENT_PATH, NEW_REPORT_PATH, editMachinePath, editClientPath} from "../paths";
import {LocationPropType, ClientContactsPropType} from './shared/sharedImport';
import {connect} from "react-redux";
import {getClientsWithMachines} from "../selectors";


function Machines({clientId, machines, currentUser}) {
    const {isEngineer, isManagement} = currentUser;

    return (
        <table>
            <tbody>
            {machines.map(({_id, name, serial}) => {
                return (
                    <tr key={_id}>
                        <td>
                            <Link to={editMachinePath(_id)} className="tooltip" data-tooltip="Редактировать">{name}</Link>
                        </td>
                        <td className="m-serial">{
                            (isEngineer || isManagement)
                                ? <Link to={{pathname: NEW_REPORT_PATH, state: {c: clientId, m: _id}}} className="tooltip" data-tooltip="Новый репорт">{serial}</Link>
                                : serial
                        }</td>
                    </tr>
                );
            })}
            </tbody>
        </table>
    )
}


class Contacts extends React.PureComponent {
    static propTypes = {
        contacts: ClientContactsPropType
    };

    state = {page: 0};

    prevPage = () => this.setState({page: this.state.page - 1});
    nextPage = () => this.setState({page: this.state.page + 1});

    render() {
        const page = this.state.page;
        const contacts = this.props.contacts;

        if (contacts.length === 0)
            return null;

        return (
            <React.Fragment>
                {page > 0 &&
                <span className="arrow-left" onClick={this.prevPage}>
                    <Icon icon="caret" className="rot90"/>
                </span>
                }

                <Icon icon="people" className="ml-2 mr-1" title="ФИО"/> {contacts[page].name}<br/>
                {/*<i className="material-icons">phone_iphone</i>{contacts[page].phone}<br/>*/}
                <Icon icon="message" className="ml-2 mr-1" title="Телефон"/> {contacts[page].phone}<br/>
                <Icon icon="mail" className="ml-2 mr-1" title="Email"/> {contacts[page].email}<br/>

                {contacts.length > 1 && <span className="count">{page+1}/{contacts.length}</span>}

                {page < contacts.length-1 &&
                <span className="arrow-right" onClick={this.nextPage}>
                    <Icon icon="caret" className="rot270"/>
                </span>
                }
            </React.Fragment>
        )
    }
}


class ClientsList extends React.Component {
    static propTypes = {
        currentUser: PropTypes.object.isRequired,
        clients: PropTypes.arrayOf(PropTypes.shape({
            _id: PropTypes.string,
            _rev: PropTypes.string,
            name: PropTypes.string.isRequired,
            jurName: PropTypes.string,
            website: PropTypes.string,
            notes: PropTypes.string,
            bankDetails: PropTypes.string,
            contacts: ClientContactsPropType,
            contracts: PropTypes.string,
            machines: PropTypes.arrayOf(PropTypes.shape({
                _id: PropTypes.string,
                name: PropTypes.string,
                serial: PropTypes.string
            })),
            location: LocationPropType,
        }))
    };

    static getDerivedStateFromProps(props, state) {
        const clients = ClientsList.filter(props.clients, state.filters);
        return { clients };
    }

    static filter = memoize(
        (clients, filters) => {
            for (const key in filters) {
                const re = new RegExp(filters[key], 'i');
                switch (key) {
                    case 'name': clients = clients.filter(c => re.test(c.name) || re.test(c.jurName) || re.test(c.website)); break;
                    case 'machines': clients = clients.filter(c => c.machines.some(m => re.test(m.name) || re.test(m.serial))); break;
                    case 'contacts': clients = clients.filter(c => c.contacts.some(_c => re.test(_c.name) || re.test(_c.phone) || re.test(_c.email))); break;
                    case 'address': clients = clients.filter(c => re.test(c.location.address)); break;
                    case 'bankDetails': clients = clients.filter(c => re.test(c.bankDetails)); break;
                    case 'notes': clients = clients.filter(c => re.test(c.notes)); break;
                    case 'contracts': clients = clients.filter(c => re.test(c.contracts)); break;
                }
            }
            return clients;
        }
    );

    N = 25;

    state = {
        shownNum: this.N,
        lastColumn: 'address',
        filters: {},
        clients: []
    };

    componentDidMount() {
        // [document.body.scrollHeight - window.innerHeight, window.scrollY]
        window.addEventListener('scroll', this.onScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    }

    onScroll = () => {
        if (document.body.scrollHeight - window.innerHeight - 400 < window.scrollY &&
            this.state.shownNum < this.state.clients.length)
            this.setState({shownNum: this.state.shownNum + this.N});
    };

    onFilterChange = (ev) => {
        const {type, name, value, checked} = ev.target;
        const filters = Object.assign({}, this.state.filters);

        if (type === "checkbox" && checked) filters[name] = true;
        else if (type !== "checkbox" && value) filters[name] = value;
        else delete filters[name];

        this.setState({filters, shownNum: this.N});
    };

    onColumnSelect = (ev) => {
        if (ev.target.value !== this.state.lastColumn) {
            const filters = Object.assign({}, this.state.filters);
            delete filters[this.state.lastColumn];
            this.setState({lastColumn: ev.target.value, filters, shownNum: this.N});
        }
    };

    renderLastColumn = (client) => {
        switch (this.state.lastColumn) {
            case 'address':
                return <div>
                    {client.location.lat && client.location.lon &&
                    <a className="mr-1" href={`https://yandex.ru/maps/?text=${client.location.lat},${client.location.lon}&z=12`} target="_blank" rel="noopener noreferrer">
                        <Icon icon="location"/>
                    </a>}
                    {client.location.address}
                </div>;

            case 'bankDetails':
            case 'notes':
                return <div className="ws-pl">{client[this.state.lastColumn]}</div>;

            case 'contracts':
                return <div>{client.contracts}</div>;

            default:
                return null;
        }

    };

    render() {
        const currentUser = this.props.currentUser;
        const {lastColumn, filters, shownNum} = this.state;
        const clients = this.state.clients.slice(0, shownNum);

        return (
            <div>
                <Link className="btn btn-primary" to={NEW_CLIENT_PATH}>Создать клиента</Link>

                <div id="clients-table" className="custom-table">
                    <div className="table-row table-head" key={0}>
                        <div>
                            <div className="mb-2">Клиент</div>
                            <Input small name="name" value={filters.name} onChange={this.onFilterChange} />
                        </div>
                        <div className="c-machines">
                            <div className="mb-2">Оборудование</div>
                            <Input small name="machines" value={filters.machines} onChange={this.onFilterChange} />
                        </div>
                        <div className="c-contacts">
                            <div className="mb-2">Контактные лица</div>
                            <Input small name="contacts" value={filters.contacts} onChange={this.onFilterChange}/>
                        </div>
                        <div>
                            <Select small className="mb-1" value={lastColumn} onChange={this.onColumnSelect}>
                                <option value='address'>Адрес</option>
                                <option value='bankDetails'>Реквизиты</option>
                                <option value='contracts'>Контракты</option>
                                <option value='notes'>Заметки</option>
                            </Select>
                            <input name={lastColumn} className="form-input input-sm" value={filters[lastColumn]} onChange={this.onFilterChange}/>
                        </div>
                    </div>

                    {clients.map(client =>
                        <div className="table-row" key={client._id}>
                            <div>
                                <div>
                                    <Link to={editClientPath(client)} className="tooltip" data-tooltip="Редактировать">{client.name}</Link>
                                </div>
                                {(client.jurName || client.website) && <hr/>}
                                {client.jurName && <div>Юр. лицо: {client.jurName}</div>}
                                {client.website && <div>Веб-сайт: <a href={client.website} target="_blank noreferrer noopener">{client.website}</a></div>}
                            </div>
                            <div className="c-machines">
                                <Machines clientId={client._id} machines={client.machines} currentUser={currentUser}/>
                            </div>
                            <div className="c-contacts">
                                <Contacts contacts={client.contacts} />
                            </div>
                            {this.renderLastColumn(client)}
                        </div>
                    )}
                </div>
            </div>
        )
    }
}


function mapStateToProps(state, ownProps) {
    const clients = getClientsWithMachines(state);

    for (const client of clients) {
        const {aristoService, durstService, durstRepair} = client, contracts = [];

        if (aristoService.active) {
            const {comment, refund} = aristoService;
            contracts.push(`• Сервисный рамочный Aristo: ${comment} ${refund ? ' (с возмещением расходов)' : ''}`);
        }
        if (durstService.active) {
            const {comment, refund} = durstService;
            contracts.push(`• Сервисный рамочный Durst: ${comment} ${refund ? ' (с возмещением расходов)' : ''}`);
        }
        if (durstRepair.active)
            contracts.push(`• Восстановление голов Durst: ${durstRepair.comment}`);

        delete client.aristoService;
        delete client.durstService;
        delete client.durstRepair;

        client.machines = client.machines
            .map(({_id, model, manufacturer, serial}) => ({_id, name: `${manufacturer} ${model}`, serial}));

        client.contracts = contracts.join('\n');
    }

    return { clients };
}

export default connect(mapStateToProps)(ClientsList);