import React from 'react';
import cx from "classnames";
import {Loading, Button, Toast, Input, Label} from "spectre-react/dist/cjs";
import {fixPayoutsPageData, getPayoutsPageData, removePayout} from "../../api";
import rubFmt from "../shared/summaFormatter";
import {lightFormat} from "date-fns";


class PayoutsPage extends React.PureComponent {
    static propTypes = {};

    state = {
        dataFetchState: 0,
        dataFetchError: undefined,

        lastPayoutItems: undefined,
        nextPayoutItem: undefined,
        engineerNamesById: undefined,
        isLastPayoutDeletable: undefined,

        submitFetchState: 0,
        submitFetchError: undefined,
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async (endDateISO) => {
        try {
            this.setState({dataFetchState: 1, dataFetchError: undefined});
            const {lastPayoutItems, nextPayoutItem, engineerNames, isLastPayoutDeletable} = await getPayoutsPageData(endDateISO);
            // console.log(engineerNames);
            // console.log(lastPayoutItems);
            // console.log(nextPayoutItem);
            this.setState({dataFetchState: 2, lastPayoutItems, nextPayoutItem, isLastPayoutDeletable, engineerNamesById: engineerNames});
        } catch (error) {
            console.error(error);
            this.setState({dataFetchState: 0, dataFetchError: error.message});
        }
    };

    renderNextPayoutItemCell({computed, debt, total}) {
        computed = <span className="text-italic">{rubFmt(computed)}</span>;
        debt = <span>{`+ ${rubFmt(debt)}`.replace(/\+ -/g, ' - ')}</span>;
        total = <span className="text-bold text-mono">{rubFmt(total).padStart(12)}</span>;

        return (
            <td className="text-pre">{total} = {computed} {debt}</td>
        );
    }

    onSubmit = async () => {
        try {
            this.setState({submitFetchState: 1, submitFetchError: undefined});
            const endDateISO = this.state.nextPayoutItem.endDate;
            await fixPayoutsPageData(endDateISO);
            this.setState({submitFetchState: 2}, this.fetchData);
        } catch (error) {
            console.error(error);
            this.setState({submitFetchState: 0, submitFetchError: error.message});
        }
    }

    onEndDateChange = async (ev) => {
        const endDateISO = ev.target.value;
        await this.fetchData(endDateISO);
    }

    onDeleteLabelClick = async (startDateISO) => {
        await removePayout(startDateISO);
        await this.fetchData();
    }

    render() {
        const {
            dataFetchState, dataFetchError,
            submitFetchState, submitFetchError,
            lastPayoutItems, nextPayoutItem, engineerNamesById, isLastPayoutDeletable,
        } = this.state;

        if (!lastPayoutItems || !engineerNamesById)
            return <Loading large />;

        const engineers = Object.entries(engineerNamesById).sort((a, b) => a[1].localeCompare(b[1]));

        return (
            <div className="container" id="payouts-page">
                <div className="columns">
                    <div className="column col-12 text-center">
                        {dataFetchError && <Toast error>{dataFetchError}</Toast>}
                    </div>
                    <div className="column col-12">
                         <table className="table table-striped">
                             <thead>
                                <tr>
                                    <th>
                                        {dataFetchState === 1 && <div className="loader" />}
                                    </th>
                                    {lastPayoutItems.map((item, index) =>
                                        <th key={item.startDate} colSpan={2} className="text-justify">
                                            {item.startDateDMY} – {item.endDateDMY}
                                            {(index === lastPayoutItems.length - 1) && isLastPayoutDeletable && (
                                                <Label className="bg-primary valign-text-top text-tiny ml-2 c-hand"
                                                       onClick={() => this.onDeleteLabelClick(item.startDate)}>
                                                    УДАЛИТЬ
                                                </Label>
                                            )}
                                        </th>
                                    )}
                                    {nextPayoutItem && (
                                        <th key={nextPayoutItem.startDate}>
                                            {nextPayoutItem.startDateDMY} – <Input className="form-inline" type="date" onChange={this.onEndDateChange} value={nextPayoutItem.endDate} />
                                        </th>
                                    )}
                                </tr>
                                <tr>
                                    <th/>
                                    {lastPayoutItems.map((item) =>
                                        <React.Fragment key={item.startDate}>
                                            <th className="text-tiny">выплачено</th>
                                            <th className="text-tiny">начислено</th>
                                        </React.Fragment>
                                    )}
                                    {nextPayoutItem && <th className="text-tiny">начислено</th>}
                                </tr>
                             </thead>
                             <tbody>
                             {engineers.map(([engineerId, engineerName]) => (
                                 <tr key={engineerId}>
                                     <td className="text-uppercase text-bold">{engineerName}</td>
                                     {lastPayoutItems.map((item) => {
                                         const {paidOut, computed} = item.payAmountByUserId[engineerId];
                                         const className = cx({'text-primary': computed > paidOut, 'text-success': computed < paidOut})
                                         return (
                                             <React.Fragment key={item.startDate}>
                                                 <td>{rubFmt(paidOut)}</td>
                                                 <td><span className={className}>{rubFmt(computed)}</span></td>
                                             </React.Fragment>
                                         );
                                     })}
                                     {nextPayoutItem && this.renderNextPayoutItemCell(nextPayoutItem.payAmountByUserId[engineerId])}
                                 </tr>
                             ))}
                             <tr>
                                 <td/>
                                 {lastPayoutItems.map((item) => {
                                     return (
                                         <React.Fragment key={item.startDate}>
                                             <td>{rubFmt(item.totalPayAmount.paidOut)}</td>
                                             <td>{rubFmt(item.totalPayAmount.computed)}</td>
                                         </React.Fragment>
                                     );
                                 })}
                                 {nextPayoutItem && <td className="text-bold text-mono text-pre">{rubFmt(nextPayoutItem.totalPayAmount).padStart(12)}</td>}
                             </tr>
                             </tbody>
                         </table>
                    </div>
                    <div className="column col-12">
                        <div className="my-4 text-center">
                            {submitFetchError && <Toast className="mb-2" error>{submitFetchError}</Toast>}

                            <Button primary type="button" onClick={this.onSubmit} loading={submitFetchState === 1} disabled={!nextPayoutItem}>
                                Зафиксировать
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default PayoutsPage;
