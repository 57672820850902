import React from "react";
import PropTypes from "prop-types";
import {Divider} from "spectre-react/dist/cjs";
import {lightFormat} from "date-fns";


class CalculationString extends React.PureComponent {
    static propTypes = {
        coeffInfo: PropTypes.objectOf(PropTypes.shape({
            color: PropTypes.string,
            description: PropTypes.string,
        })),
        string: PropTypes.string,
        prefix: PropTypes.string,
    }

    static defaultProps = {
        prefix: 'Расчет: ',
    }

    constructor(props) {
        super(props);

        const abstractString = props.prefix + props.string.replace(/{(\d):(.*?)}/g, (match, p1, p2, offset, string, groups) => {
            const coeff = `k${p1}`;
            const className = props.coeffInfo[coeff].color;
            return `<span class="${className}">${coeff}</span>`;
        });
        const explicitString = props.prefix + props.string.replace(/{(\d):(.*?)}/g, (match, p1, p2, offset, string, groups) => {
            const coeff = `k${p1}`;
            const className = props.coeffInfo[coeff].color;
            return `<span class="${className}">${p2}</span>`;
        });

        this.state = {
            opened: false,
            abstractString: {__html: abstractString},
            explicitString: {__html: explicitString},
        }
    }

    toggleStrings = () => this.setState(({opened}) => ({opened: !opened}));

    render() {
        const {opened, abstractString, explicitString} = this.state;

        if (opened) {
            const coeffDivs = Object.keys(this.props.coeffInfo)
                .sort((a, b) => a.localeCompare(b))
                .map(coeff => {
                    const {color, description} = this.props.coeffInfo[coeff];
                    return <div key={coeff} className={color}>{coeff} -- {description}</div>;
                });

            return (
                <div className="text-italic">
                    <div className="c-hand" onClick={this.toggleStrings} dangerouslySetInnerHTML={abstractString}></div>
                    {coeffDivs}
                </div>
            );
        } else {
            return <div className="text-italic c-hand" onClick={this.toggleStrings} dangerouslySetInnerHTML={explicitString}/>;
        }
    }
}


class DayContent extends React.PureComponent {
    static COEFF_INFO = {
        work: {
            'k1': {color: 'navy', description: 'коэфф. (разн.) места работы'},
            'k2': {color: 'olive', description: 'коэфф. (разн.) оборудования'},
            'k3': {color: 'orange', description: 'коэфф. (разн.) вида работы'},
            'k4': {color: 'fuchsia', description: 'коэфф. (соотв.) вида дня'},
            'k5': {color: 'maroon', description: 'персональный коэфф.'},
        },
        complement: {
            'k1': {color: 'navy', description: 'коэфф. (соотв.) доп. часов'},
            'k2': {color: 'olive', description: 'коэфф. вида дня'},
        },
        travel: {
            'k1': {color: 'navy', description: 'коэфф. (соотв.) дор. часов'},
        }
    }

    static propTypes = {
        showDetailedPayAmount: PropTypes.bool.isRequired,
        timelineDay: PropTypes.object,
    }

    render() {
        const showDetailedPayAmount = this.props.showDetailedPayAmount;
        const isTimelineDaySelected = !!this.props.timelineDay;
        const timelineDay = {...this.props.timelineDay};

        if (!timelineDay)
            return null;

        for (const key in timelineDay) {
            if (typeof timelineDay[key] === "number")
                timelineDay[key] = timelineDay[key].toFixed(2);
        }

        return (
            <div id="day-content" className="text-preline">
                {!isTimelineDaySelected && <div>Выберите день.</div>}

                {isTimelineDaySelected && <div>Начислено всего: {timelineDay.payAmount} ₽</div>}

                {timelineDay.workHoursSum > 0 && <Divider />}

                {timelineDay.workHoursSum > 0 && <div>Регулярные рабочие часы: {timelineDay.workHoursRegular} ч</div>}
                {timelineDay.workHoursSum > 0 && <div>Сверхурочные рабочие часы: {timelineDay.workHoursOvertime} ч</div>}
                {timelineDay.workHoursSum > 0 && <div>Всего рабочих часов: {timelineDay.workHoursSum} ч</div>}
                {timelineDay.workHoursSum > 0 && <div>Начислено за рабочие часы: {timelineDay.workHoursPayAmount} ₽</div>}
                {timelineDay.workHoursSum > 0 && <div>Репорты: {timelineDay.reportIds.map(([reportId, clientName], i) => <span key={reportId}>{i > 0 && ", "}<a href={`/reports/${reportId}/edit`}>{clientName}</a></span>)}</div>}
                {timelineDay.workHoursSum > 0 && showDetailedPayAmount && <CalculationString key={timelineDay.workHoursCalc} coeffInfo={DayContent.COEFF_INFO.work} string={timelineDay.workHoursCalc} />}

                {timelineDay.complementHoursSum > 0 && <Divider />}

                {timelineDay.complementHoursSum > 0 && <div>Дополнительные регулярные рабочие часы: {timelineDay.complementHoursRegular} ч</div>}
                {timelineDay.complementHoursSum > 0 && <div>Дополнительные сверхурочные рабочие часы: {timelineDay.complementHoursOvertime} ч</div>}
                {timelineDay.complementHoursSum > 0 && <div>Всего дополнительных часов: {timelineDay.complementHoursSum} ч</div>}
                {timelineDay.complementHoursSum > 0 && <div>Начислено дополнительно: {timelineDay.complementHoursPayAmount} ₽</div>}
                {timelineDay.complementHoursSum > 0 && showDetailedPayAmount && <CalculationString key={timelineDay.complementHoursCalc} coeffInfo={DayContent.COEFF_INFO.complement} string={timelineDay.complementHoursCalc} />}

                {timelineDay.travelHoursSumVirtual > 0 && <Divider />}

                {timelineDay.travelHoursSumReal > 0 && <div>Реальных часов в дороге: {timelineDay.travelHoursSumReal} ч</div>}
                {timelineDay.travelHoursSumVirtual > 0 && <div>Виртуальных часов в дороге: {timelineDay.travelHoursSumVirtual} ч</div>}
                {timelineDay.travelHoursSumVirtual > 0 && <div>Регулярные часы в дороге: {timelineDay.travelHoursRegular} ч</div>}
                {timelineDay.travelHoursSumVirtual > 0 && <div>Сверхурочные часы в дороге: {timelineDay.travelHoursOvertime} ч</div>}
                {timelineDay.travelHoursSumVirtual > 0 && <div>Всего часов в дороге: {timelineDay.travelHoursSumVirtual} ч</div>}
                {timelineDay.travelHoursSumVirtual > 0 && <div>Начислено за время в дороге: {timelineDay.travelHoursPayAmount} ₽</div>}
                {timelineDay.travelHoursSumVirtual > 0 && showDetailedPayAmount && <CalculationString key={timelineDay.travelHoursCalc} coeffInfo={DayContent.COEFF_INFO.travel} string={timelineDay.travelHoursCalc} />}

                {timelineDay.vacationPayAmount > 0 && <div>Отпускные: {timelineDay.vacationPayAmount} ₽</div>}

                {timelineDay.updatedAt && showDetailedPayAmount && <Divider />}
                {timelineDay.updatedAt && showDetailedPayAmount && <div className="text-italic">Обновлено: {lightFormat(new Date(timelineDay.updatedAt), 'dd.MM.yyyy HH:mm')}</div>}

                {/*<div>{JSON.stringify(timelineDay, null, 2)}</div>*/}
            </div>
        );
    }
}

export default DayContent;