import React from 'react';
import {Redirect} from "react-router-dom";
import ClientForm from "./ClientForm";
import {CLIENTS_PATH} from "../../paths";
import {postClient} from '../../api';


class NewClientPage extends React.Component {
    state = {
        submitState: 0, submitError: null,
        client: {
            type: "client",
            name: "",
            jurName: "",
            website: "",
            notes: "",
            bankDetails: "",
            durstRepair: {active: false, comment: ""},
            durstService: {active: false, refund: false, comment: ""},
            aristoService: {active: false, refund: false, comment: ""},
            contacts: [],
            contractPersons: [],
            location: {address: "", lat: "", lon: ""},
        }
    };

    onChange = (ev) => {
        const client = {...this.state.client};

        if (Array.isArray(ev.target))
            for (const target of ev.target)
                client[target.name] = target.value;
        else
            client[ev.target.name] = ev.target.value;

        this.setState({submitError: null, client});
    };

    onSubmit = async (client) => {
        this.setState({submitError: null, submitState: 1});

        try {
            await postClient(client);
            this.setState({submitError: null, submitState: 2});
        } catch (error) {
            this.setState({submitError: error.message, submitState: 0});
        }
    };

    render() {
        if (this.state.submitState === 2) {
            alert("Новый клиент успешно создан.");
            return <Redirect to={CLIENTS_PATH} />;
        }

        const {submitState, submitError, client} = this.state;

        return (
            <div>
                <h1>Новый клиент</h1>

                <ClientForm isSubmitting={submitState === 1} submitError={submitError}
                            client={client} onChange={this.onChange} onSubmit={this.onSubmit}
                />
            </div>
        )
    }
}


export default NewClientPage;