import {
    LOGGED_IN,
    SET_WAGES_TABLE_FILTER,
} from "./actions";


const settingsMiddleware = store => (next) => (action) => {
    if (action.type === LOGGED_IN) {
        let value = localStorage.getItem('wagesEngineersTablePage');

        if (value) {
            value = JSON.parse(value);
            store.dispatch({ type: SET_WAGES_TABLE_FILTER, payload: value });
            // yes, we rewrite the value in localStorage one time on login, it's wrong, but so convenient
        }
    }

    const result = next(action);

    if (action.type === SET_WAGES_TABLE_FILTER) {
        const values = store.getState().wagesEngineersTablePage;
        localStorage.setItem('wagesEngineersTablePage', JSON.stringify(values));
    }

    return result;
};


export default settingsMiddleware;
