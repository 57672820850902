import React from "react";
import PropTypes from "prop-types";
import {Button, Loading, Toast} from "spectre-react/dist/cjs";
import {connect} from "react-redux";
import {setInnClientId, setPaymentClientId} from "../../api";
import {SingleSelect} from "../shared/Select";
import {getClients} from "../../selectors";


class SetClientModal extends React.PureComponent {
    static propTypes = {
        provideController: PropTypes.func.isRequired,
        clients: PropTypes.arrayOf(PropTypes.object).isRequired,
    }

    getDefaultState() {
        return {
            active: false,
            INN_PL: null, PL_NAME: null, paymentId: null,
            submitState: 0, submitError: null,
            selectedItem: null,
        };
    }

    state = this.getDefaultState();

    componentDidMount() {
        this.props.provideController({open: this.open, close: this.close});
        this.clientSelectOptions = this.props.clients.map(client => ({_id: client._id, label: client.name}))
    }

    open = (INN_PL, PL_NAME, paymentId) => this.setState({active: true, INN_PL, PL_NAME, paymentId});
    close = () => this.setState(this.getDefaultState());

    submit = () => {
        const {INN_PL, paymentId, selectedItem} = this.state;
        const clientId = selectedItem._id;
        this.setState({submitState: 1});

        const promise = INN_PL
            ? setInnClientId(INN_PL, selectedItem._id)
            : setPaymentClientId(paymentId, clientId);

        promise
            .then(this.close)
            .then(this.props.reloadData)
            .catch(error => this.setState({submitState: 0, submitError: error.message}))
    };

    onItemSelect = (ev) => this.setState({selectedItem: ev.target.value});

    renderBody() {
        if (this.state.submitState === 1)
            return (
                <div className="modal-body">
                    <Loading large />
                </div>
            );

        if (this.state.submitError)
            return (
                <div className="modal-body">
                    <Toast error>{this.state.error}</Toast>
                </div>
            );

        const {INN_PL, PL_NAME} = this.state;
        const clientSelectOptions = this.clientSelectOptions;

        return (
            <div className="modal-body">
                {INN_PL && <p>ИНН: {INN_PL}</p>}
                <p>Плательщик: {PL_NAME}</p>
                <SingleSelect items={clientSelectOptions} onChange={this.onItemSelect} name="selectedItem"
                              searchable={true} clearable={false}
                />
            </div>
        )
    }

    renderHeader() {
        const title = 'Указать клиента ' + (this.state.INN_PL ? 'для ИНН' : 'для платежа');

        return (
            <div className="modal-header">
                <span className="btn btn-clear float-right" onClick={this.close}/>
                <div className="modal-title h5">{title}</div>
            </div>
        )
    }

    renderFooter() {
        const isSubmitButtonDisabled = !this.state.selectedItem || this.state.submitState === 1;

        return (
            <div className="modal-footer">
                <Button primary disabled={isSubmitButtonDisabled} className="mr-2" onClick={this.submit}>Сохранить</Button>
                <Button link onClick={this.close}>Закрыть</Button>
            </div>
        )
    }

    render() {
        if (!this.state.active)
            return null;

        return (
            <div id="inn-modal" className="modal active">
                <span className="modal-overlay" onClick={this.close}/>
                <div className="modal-container">
                    {this.renderHeader()}
                    {this.renderBody()}
                    {this.renderFooter()}
                </div>
            </div>
        )
    }
}


function mapStateToProps(state, ownProps) {
    return {
        clients: getClients(state).sort((a, b) => a.name.localeCompare(b.name))
    }
}

export default connect(mapStateToProps)(SetClientModal);