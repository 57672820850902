import React from 'react';
import {Link} from "react-router-dom";
import {Divider} from "spectre-react/dist/cjs";
import {fetchSpecialDocsList} from "../../api";
import {editSpecialDocPath, EDIT_WAGES_PARAMS_PATH, REPORT_APPROVAL_CHAIN_DOC_PATH, WAGES_ALGORITHM_PATH, WAGES_PARAMS_HISTORY_PATH} from "../../paths";


class SpecialDocsPage extends React.PureComponent {
    static propTypes = {

    };

    state = {
        docsLoadState: 0, docLoadError: undefined, docs: [],
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        this.setState({ docsLoadState: 1, docsLoadError: undefined, docs: [] });

        try {
            let docs = await fetchSpecialDocsList();
            docs = docs.filter(doc => doc._id !== "reportApprovalChain"); // TODO: сделать отдельую страницу для каждого документа

            this.setState({ docsLoadState: 2, docs });
        } catch (error) {
            console.error(error);
            alert(error.message);
            this.setState({ docsLoadState: 0, docsLoadError: error.message });
        }
    }

    render() {
        const docs = this.state.docs;

        return (
            <div>
                <h1>Редактирование различных настроек</h1>
                <ul>
                    {docs.map(doc => (
                        <li key={doc._id}>
                            <Link to={editSpecialDocPath(doc._id)}>{doc.description}</Link>
                        </li>
                    ))}
                    <li>
                        <Link to={REPORT_APPROVAL_CHAIN_DOC_PATH}>Цепочка одобрения репортов</Link>
                    </li>
                </ul>
                <Divider />
                <Link to={WAGES_PARAMS_HISTORY_PATH}>История коэффициентов ОТ</Link>
                <Divider />
                <Link to={EDIT_WAGES_PARAMS_PATH}>Добавить/редактировать коэффициенты ОТ</Link>
                <Divider />
                <Link to={WAGES_ALGORITHM_PATH}>Алгоритм расчета ОТ</Link>
            </div>
        );
    }
}


export default SpecialDocsPage;

