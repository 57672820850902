import React from 'react';
import cx from 'classnames';
import {Button, FormGroup, Input, Label, Select, Toast} from "spectre-react/dist/cjs";

import Replacements from './Replacements';
import SlotReplacements from './SlotReplacements';
import Expenses from './Expenses';
import Client from "./Client";
import Machine from "./Machine";
import WorkType from "./WorkType";
import Engineers from "./Engineers";
import TravelHoursTable from "./TravelHoursTable";
import WorkHoursTable from "./WorkHoursTable";

import {STATUS_LABEL, STATUS} from '../../shared/sharedImport';


class ReportForm extends React.PureComponent {
    STATUSES = Object.entries(STATUS).map(([key, value]) => ({label: STATUS_LABEL[key], value}));

    static statusCls(status) {
        return cx({
            "text-primary": status === STATUS.NOT_FIXED,
            "text-warning": status === STATUS.PART_FIXED,
            "text-success": status === STATUS.FIXED
        });
    }

    onEngineerIdsChange = (ev) => {
        const engineerIds = ev.target.value;
        const engineerIdsSet = new Set(engineerIds);

        const workHours = [...this.props.report.workHours];
        for (let i = 0; i < workHours.length; ++i) {
            workHours[i] = {...workHours[i]};
            workHours[i].engineerPresence = {...workHours[i].engineerPresence};

            for (const engineerId in workHours[i].engineerPresence) {
                if (!engineerIdsSet.has(engineerId))
                    delete workHours[i].engineerPresence[engineerId];
            }
            for (const engineerId of engineerIdsSet) {
                if (!(engineerId in workHours[i].engineerPresence))
                    workHours[i].engineerPresence[engineerId] = true;
            }
        }

        const travelHours = [...this.props.report.travelHours];
        for (let i = 0; i < travelHours.length; ++i) {
            travelHours[i] = {...travelHours[i]};
            travelHours[i].engineerIds = {...travelHours[i].engineerIds};

            for (const engineerId in travelHours[i].engineerIds) {
                if (!engineerIdsSet.has(engineerId))
                    delete travelHours[i].engineerIds[engineerId];
            }
            for (const engineerId of engineerIdsSet) {
                if (!(engineerId in travelHours[i].engineerIds))
                    travelHours[i].engineerIds[engineerId] = true;
            }
        }

        this.props.onChange({target: [
            {name: 'engineerIds', value: engineerIds},
            {name: 'workHours', value: workHours},
            {name: 'travelHours', value: travelHours},
        ]});
    }

    render() {
        const {report, isSubmitting, isDestroying, submitError, destroyError, onChange, onDestroy, isOldReport} = this.props;

        const isLoading = isSubmitting || isDestroying;
        const error = submitError || destroyError;

        return (
            <form id="report-form">
                {isOldReport &&
                <Toast className="text-italic mb-6">
                    Судя по имеющимся данным в настоящее время у указанного клиента нет указанного оборудования,
                    и сейчас происходит редактирование очень старого репорта. Поэтому поля Клиент и Оборудование
                    редактировать нельзя
                </Toast>}

                <fieldset className="mb-6">
                    <div className="columns">
                        <div className="column col-sm-12 col-4">
                            <Client clientId={report.clientId} disabled={isOldReport} onChange={onChange}/>
                        </div>
                        <div className="column col-sm-12 col-4">
                            <Machine clientId={report.clientId} machineId={report.machineId} disabled={isOldReport} onChange={onChange}/>
                        </div>
                        <div className="column col-sm-12 col-2">
                            <FormGroup>
                                <Label form htmlFor="tickets">Тикеты</Label>
                                <Input type="tel" id="tickets" name="tickets" value={report.tickets} onChange={onChange} placeholder="Пример: 38355,40087"/>
                            </FormGroup>
                        </div>
                        <div className="column col-sm-12 col-2">
                            <FormGroup>
                                <Label form htmlFor="mileage">Пробег (м²)</Label>
                                <Input type="number" id="mileage" name="mileage" value={report.mileage} onChange={onChange} placeholder="Пример: 2500000" min="0" step="1"/>
                            </FormGroup>
                        </div>
                        <div className="column col-sm-12 col-8">
                            <Engineers engineerIds={report.engineerIds} onChange={this.onEngineerIdsChange}/>
                        </div>
                        <div className="column col-sm-12 col-4">
                            <FormGroup>
                                <Label form htmlFor="watchers">Лицо со стороны клиента</Label>
                                <Input type="text" id="watchers" name="watchers" value={report.watchers} onChange={onChange}/>
                            </FormGroup>
                        </div>
                        <div className="column col-sm-12 col-6">
                            <FormGroup>
                                <Label form htmlFor="problem">Описание проблемы</Label>
                                <textarea className="form-input" id="problem" name="problem" value={report.problem} onChange={onChange}/>
                            </FormGroup>
                        </div>
                        <div className="column col-sm-12 col-6">
                            <FormGroup>
                                <Label form htmlFor="solution">Выполненные работы (+ замечания)</Label>
                                <textarea className="form-input" id="solution" name="solution" value={report.solution} onChange={onChange}/>
                            </FormGroup>
                        </div>
                        <div className='column col-sm-12 col-6'>
                            <FormGroup>
                                <Label form htmlFor="status">Статус проблемы</Label>
                                <Select name="status" id="status" className={ReportForm.statusCls(report.status)} onChange={onChange} value={report.status}>
                                    {this.STATUSES.map(({label, value}) => <option key={value} value={value}>{label}</option>)}
                                </Select>
                            </FormGroup>
                        </div>
                        <div className='column col-sm-12 col-6'>
                            <WorkType workType={report.workType} onChange={onChange}/>
                        </div>
                    </div>
                </fieldset>

                <Replacements machineId={report.machineId} replacements={report.replacements} onChange={onChange}/>

                <SlotReplacements slotReplacements={report.slotReplacements} onChange={onChange}/>

                {report.logsheet &&
                <div className="columns">
                    <div className='column col-12'>
                        <FormGroup>
                            <Label form htmlFor="logsheet">Номер Logsheet</Label>
                            <Input type="text" id="logsheet" name="logsheet" value={report.logsheet} onChange={onChange}/>
                        </FormGroup>
                    </div>
                </div>}

                {Array.isArray(report.workHours)
                    ? <WorkHoursTable engineerIds={report.engineerIds} workHours={report.workHours} onChange={onChange}/>
                    : <div className="columns">
                        <div className='column col-sm-12 col-6'>
                            <FormGroup>
                                <Label form htmlFor="workDate">Дата работы</Label>
                                <Input type="date" id="workDate" name="workDate" value={report.workDate} onChange={onChange}/>
                            </FormGroup>
                        </div>
                        <div className='column col-sm-12 col-6'>
                            <FormGroup>
                                <Label form htmlFor="workHours">Суммарное время работы (в часах, точность 0.5)</Label>
                                <Input type="number" id="workHours" name="workHours" value={report.workHours} onChange={onChange} min="0" step="0.5"/>
                            </FormGroup>
                        </div>
                    </div>
                }

                {report.travelHours && Array.isArray(report.travelHours) &&
                    <TravelHoursTable engineerIds={report.engineerIds} travelHours={report.travelHours} onChange={onChange} workHours={report.workHours}/>}

                {(report.travelHours && !Array.isArray(report.travelHours)) || report.travelCost || report.housingCost
                    ? <div className="columns mb-6">
                        <div className='column col-sm-12 col-4'>
                            <FormGroup>
                                <Label form htmlFor="travelHours">Суммарное время на дорогу (в часах, точность 0.5)</Label>
                                <Input type="number" id="travelHours" name="travelHours" value={report.travelHours} onChange={onChange} min="0" step="0.5"/>
                            </FormGroup>
                        </div>
                        <div className='column col-sm-12 col-4'>
                            <FormGroup>
                                <Label form htmlFor="travelCost">Суммарные расходы на транспорт (в рублях)</Label>
                                <Input type="number" id="travelCost" name="travelCost" value={report.travelCost} onChange={onChange} min="0" step="1"/>
                            </FormGroup>
                        </div>
                        <div className='column col-sm-12 col-4'>
                            <FormGroup>
                                <Label form htmlFor="housingCost">Суммарные расходы на проживание (в рублях)</Label>
                                <Input type="number" id="housingCost" name="housingCost" value={report.housingCost} onChange={onChange} min="0" step="1"/>
                            </FormGroup>
                        </div>
                    </div>
                    : <Expenses expenses={report.expenses} onChange={onChange}/>
                }

                <div className="form-actions">
                    {error && <Toast error className="mb-2">{error}</Toast>}

                    <div className="action-buttons">
                        {onDestroy && <Button error loading={isLoading} className="mr-2" onClick={onDestroy}>Удалить</Button>}
                        <Button success loading={isLoading} onClick={this.props.onSubmit}>Отправить</Button>
                    </div>
                </div>
            </form>
        )
    }
}


export default ReportForm;