import React from "react";
import PropTypes from "prop-types";
import {Button, Icon, Input, Loading, Select, Toast} from "spectre-react/dist/cjs";
import {SingleSelect} from "../shared/Select";
import BillEditModal from "./BillEditModal";
import {fetchBills, deleteBill, fetchCSV} from "../../api";
import rubFmt from "../shared/summaFormatter";
import {BILL_STATE, BILL_STATE_LABEL, formatDate1, formatDate2} from "../shared/sharedImport";
import {connect} from "react-redux";
import {getClientsById} from "../../selectors";


const BILL_COLOR_CLASS = {
    [BILL_STATE.ERROR]: 'text-primary',
    [BILL_STATE.ISSUED]: 'text-error',
    [BILL_STATE.PART_PAY]: 'text-warning',
    [BILL_STATE.FULL_PAY]: 'text-success',
};


class BillsList extends React.PureComponent {
    static propTypes = {
        clientsById: PropTypes.objectOf(PropTypes.object).isRequired,
    }

    state = {
        billsLoadState: 0, billsLoadError: undefined, bills: null,  // 0 = not loaded, 1 = loading, 2 = loaded
        clientSelectOptions: null,
        filters: {client: null, NUM_DOC: '', DATE: '', SUMMA: '', state: '', sortType: 'smart'},
        displayedNum: 30,
    };

    SORT_FUNCTIONS = {
        'smart': (a, b) => a.state.localeCompare(b.state),
        'dateAsc': (a, b) => a.DATE - b.DATE,
        'dateDesc': (a, b) => b.DATE - a.DATE,
    };

    fetchBills = async () => {
        this.setState({
            billsLoadState: 1, billsLoadError: undefined, bills: null,
            clientSelectOptions: null
        });

        try {
            const clientsById = {...this.props.clientsById};
            for (const clientId in clientsById) {
                const client = clientsById[clientId];
                clientsById[clientId] = {_id: clientId, label: client.name, name: client.name};
            }

            const docs = await fetchBills();
            const bills = docs.map(({_id, numDoc: NUM_DOC, date: DATE, summa: SUMMA, clientId, state, createdDelay, fullPayDelay}) => ({
                _id, NUM_DOC, DATE: new Date(DATE), SUMMA, clientId, state, createdDelay, fullPayDelay,
                client: clientsById[clientId]
            }));

            const clientIds = new Set();
            for (const bill of bills) {
                clientIds.add(bill.clientId);
            }

            const clientSelectOptions = Array.from(clientIds)
                .map(clientId => clientsById[clientId])
                .sort((a, b) => a.label.localeCompare(b.label));

            this.setState({billsLoadState: 2, bills, clientSelectOptions});
        } catch (error) {
            console.error(error);
            alert(error.message);
            this.setState({billsLoadState: 0, billsLoadError: error.message});
        }
    };

    componentDidMount() {
        // [document.body.scrollHeight - window.innerHeight, window.scrollY]
        window.addEventListener('scroll', this.onScroll);

        this.fetchBills();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    }

    onScroll = () => {
        // TODO: check if this is really necessary
        if (!this.state.bills)
            return;

        if (
            document.body.scrollHeight - window.innerHeight - 400 < window.scrollY &&
            this.state.displayedNum < this.state.bills.length
        )
            this.setState({displayedNum: this.state.displayedNum + 30});
    };

    onFilterChange = (ev) => {
        const filters = {...this.state.filters, [ev.target.name.slice(8)]: ev.target.value};
        this.setState({filters, displayedNum: 30});
    };

    filter(bills, filters) {
        if (filters.client)
            bills = bills.filter(bill => bill.client._id === filters.client._id);
        if (filters.state)
            bills = bills.filter(item => item.state === filters.state);
        if (filters.NUM_DOC) {
            const re = new RegExp(filters.NUM_DOC, 'i');
            bills = bills.filter(item => re.test(item.NUM_DOC));
        }
        if (filters.DATE) {
            const re = new RegExp(filters.DATE, 'i');
            bills = bills.filter(item => re.test(formatDate2(item.DATE)));
        }
        if (filters.SUMMA) {
            const re = new RegExp(filters.SUMMA, 'i');
            bills = bills.filter(item => re.test(item.SUMMA.toString()));
        }
        return bills;
    }

    // ===========

    selectClient = (ev) => {
        const _id = ev.target.dataset.id;
        const client = this.state.clientSelectOptions.find(c => c._id === _id);
        this.onFilterChange({target: {name: 'filters.client', value: client}});
    };

    downloadCSV = () => {
        let output = "data:text/csv;charset=utf-8," + "Клиент;№ счета;Дата;Сумма;Статус\n";
        for (const {NUM_DOC, DATE, SUMMA, client, state} of this.state.bills)
            output += `${client.name};${NUM_DOC};${formatDate1(DATE)};${SUMMA};${BILL_STATE_LABEL[state]}\n`;
        fetchCSV(output, `bills-${new Date().toISOString()}.csv`);
    };

    deleteBill = (ev) => {
        const msg = "Счет будет удален, восстановить будет нельзя. Никакие платежи удалены не будут. Продолжаем?";

        if (window.confirm(msg))
            deleteBill(ev.currentTarget.dataset.id)
                .then(this.fetchBills)
                .catch(error => alert(error.message));
    };

    // ===========

    render() {
        if (this.state.billsLoadState !== 2)
            return <Loading large />;

        if (this.state.billsLoadError)
            return <Toast error>{this.state.billsLoadError}</Toast>;

        const {filters, clientSelectOptions, displayedNum} = this.state;
        const bills = this.filter(this.state.bills.slice(), filters)
            .sort(this.SORT_FUNCTIONS[filters.sortType])
            .slice(0, displayedNum);

        return <BillsListTable filters={filters} bills={bills} clientSelectOptions={clientSelectOptions}
                      selectClient={this.selectClient} onFilterChange={this.onFilterChange}
                      reloadData={this.fetchBills} downloadCSV={this.downloadCSV} deleteBill={this.deleteBill} />;
    }
}

class BillsListTable extends React.PureComponent {
    static propTypes = {
        bills: PropTypes.array,
        clientSelectOptions: PropTypes.array,
        reloadData: PropTypes.func,
        deleteBill: PropTypes.func,
        downloadCSV: PropTypes.func,
        filters: PropTypes.object,
        selectClient: PropTypes.func,
        onFilterChange: PropTypes.func,
    };

    editBillModalController = undefined;
    receiveEditBillModalController = (methods) => { this.editBillModalController = methods; }
    openEditModal = (ev) => this.editBillModalController.open(ev.currentTarget.dataset.id);

    renderRow = ({_id, NUM_DOC, DATE, SUMMA, state, client, createdDelay, fullPayDelay}) => (
        <div className="table-row" key={_id}>
            <div>
                {client &&
                    <span data-id={client._id} onClick={this.props.selectClient}>
                        {client.name}
                    </span>
                }
            </div>
            <div>{NUM_DOC}</div>
            <div>
                {formatDate2(DATE)}
                <span className="text-gray float-right">{createdDelay}</span>
            </div>
            <div className="text-right">{rubFmt(SUMMA)}</div>
            <div>
                <span className={BILL_COLOR_CLASS[state]}>{BILL_STATE_LABEL[state]}</span>
                <Button primary small className="float-right" data-id={_id} onClick={this.props.deleteBill}>
                    <Icon icon="cross"/>
                </Button>
                <Button primary small className="mr-1 float-right" data-id={_id} onClick={this.openEditModal}>
                    <Icon icon="edit"/> Edit
                </Button>
                <span className="text-gray float-right mr-2">{fullPayDelay}</span>
            </div>
        </div>
    );

    render() {
        const {bills, filters, clientSelectOptions, reloadData, onFilterChange, downloadCSV} = this.props;

        return (
            <div id="bills-list">
                <BillEditModal provideController={this.receiveEditBillModalController} onSubmit={reloadData}/>

                <div id="list-actions">
                    <Button primary small className="mr-2" onClick={downloadCSV}>
                        <Icon icon="download"/> CSV
                    </Button>

                    <Button primary small className="mr-2" onClick={reloadData}>
                        <Icon icon="refresh"/> Обновить
                    </Button>

                    <span className="chip">
                        Сортировать по <Select small name="filters.sortType" value={filters.sortType} onChange={onFilterChange}>
                        <option value="smart">smart</option>
                        <option value="dateAsc">датам по возрастанию</option>
                        <option value="dateDesc">датам по убыванию</option>
                    </Select>
                    </span>
                </div>

                <div className="custom-table">
                    <div className="table-row table-head" key={0}>
                        <div>
                            <div>Клиент</div>
                            <SingleSelect items={clientSelectOptions} selectedItem={filters.client} onChange={onFilterChange}
                                          name="filters.client" className="form-input input-sm"
                                          clearable={true} searchable={true}/>
                        </div>
                        <div>
                            <div>№ счета</div>
                            <Input small type="text" name="filters.NUM_DOC" value={filters.NUM_DOC} onChange={onFilterChange}/>
                        </div>
                        <div>
                            <div>Дата</div>
                            <Input small type="text" name="filters.DATE" value={filters.DATE} onChange={onFilterChange}/>
                        </div>
                        <div>
                            <div>Сумма</div>
                            <Input small type="text" name="filters.SUMMA" value={filters.SUMMA} onChange={onFilterChange}/>
                        </div>
                        <div>
                            <div>Статус</div>
                            <Select small name="filters.state" value={filters.state} onChange={onFilterChange}>
                                <option value="">Любой</option>
                                <option key={BILL_STATE.ERROR} value={BILL_STATE.ERROR}>{BILL_STATE_LABEL.ERROR}</option>
                                <option key={BILL_STATE.ISSUED} value={BILL_STATE.ISSUED}>{BILL_STATE_LABEL.ISSUED}</option>
                                <option key={BILL_STATE.PART_PAY} value={BILL_STATE.PART_PAY}>{BILL_STATE_LABEL.PART_PAY}</option>
                                <option key={BILL_STATE.FULL_PAY} value={BILL_STATE.FULL_PAY}>{BILL_STATE_LABEL.FULL_PAY}</option>
                            </Select>
                        </div>
                    </div>
                    {bills.map(this.renderRow)}
                </div>
            </div>
        )
    }
}


function mapStateToProps(state, ownProps) {
    return {
        clientsById: getClientsById(state)
    }
}

export default connect(mapStateToProps)(BillsList);