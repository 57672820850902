import React from "react";
import PropTypes from "prop-types";
import {Button, Checkbox, Icon, Input, Loading, Select} from "spectre-react/dist/cjs";
import {parseDate} from "../shared/sharedImport";


class WordsFilter extends React.PureComponent {
    static propTypes = {
        value: PropTypes.string,
        onChange: PropTypes.func
    };

    static defaultProps = {value: ''};

    timer = null;

    onChange = (value) => this.props.onChange({target: {name: 'filters.words', value}});

    handleChange = (ev) => {
        if (this.timer !== null)
            clearTimeout(this.timer);

        const value = ev.target.value;

        this.timer = setTimeout(() => {
            this.timer = null;
            this.onChange(value);
        }, 500);
    };

    doClearInput = () => {
        if (this.timer !== null) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.onChange('');
        this.input.value = '';
    };

    render() {
        return (
            <Input ref={input => this.input = input} placeholder='Поиск по словам' onChange={this.handleChange} iconRight>
                <Icon formIcon icon="cross" onClick={this.doClearInput}/>
            </Input>
        );
    }
}


class TimespanFilter extends React.PureComponent {
    static propTypes = {
        borders: PropTypes.shape({from: PropTypes.string, to: PropTypes.string}),
        value: PropTypes.shape({from: PropTypes.string, to: PropTypes.string}),
        onChange: PropTypes.func
    };

    static defaultProps = {
        value: { from: "", to: "" }
    };

    onChange = (value) => this.props.onChange({ target: { name: 'filters.timespan', value } });

    handleChange = (ev) => {
        const name = ev.target.name.slice(17);
        this.setState({ [name]: ev.target.value || "" });

        const value = parseDate(ev.target.value) ? ev.target.value : "";
        if (value !== this.props.value[name])
            this.onChange({ ...this.props.value, [name]: value });
    };

    doClearInputs = () => {
        this.onChange( { from: '', to: '' });
    };

    render() {
        const borders = this.props.borders;
        const {from: fromDate, to: toDate} = this.props.value;

        return (
            <div className="form-autocomplete custom-multiselect">
                <div className="form-autocomplete-input form-input">
                    <div className="chip">C</div>

                    <Input type="date" min={borders.from} max={borders.to} name="filters.timespan.from"
                           placeholder={borders.from} onChange={this.handleChange} value={fromDate}/>

                    <div className="chip">по</div>

                    <Input type="date" min={borders.from} max={borders.to} name="filters.timespan.to"
                           placeholder={borders.to} onChange={this.handleChange} value={toDate}/>

                    <Icon formIcon icon="cross" onClick={this.doClearInputs}/>
                </div>
            </div>
        );
    }
}


export {
    WordsFilter,
    TimespanFilter,
}