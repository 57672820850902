import {
    ALL_DOCS_FETCH_START,
    ALL_DOCS_FETCH_PROGRESS,
    ALL_DOCS_FETCH_SUCCESS,
    ALL_DOCS_FETCH_ERROR,
    HYDRATE_STATE_FROM_IDB,
    CHANGES_NORMAL_FETCH_START,
    CHANGES_NORMAL_FETCH_SUCCESS,
    CHANGES_NORMAL_FETCH_ERROR,
    CHANGES_FEED_FETCH_START,
    CHANGES_FEED_FETCH_SUCCESS,
    CHANGES_FEED_FETCH_ERROR,
    CHANGES_FEED_FETCH_STOP,
    LOGGED_IN,
    LOGGED_OUT,
    SET_REPORTS_LIST_FILTER,
    SET_WAGES_TABLE_FILTER
} from './actions';
import {ENGINEER_CATEGORY} from "../components/shared/sharedImport";
import {md5} from "../components/shared/md5";


function computeDocsHash(docs) {
    const t0 = performance.now();
    const data = docs.map(doc => doc._id + doc._rev);
    const hash = md5(data);
    console.log(`[computeDocsHash] took ${performance.now() - t0} ms`, hash);
    return hash;
}


export default (state, action) => {
    if (action.type === ALL_DOCS_FETCH_START) {
        return {
            ...state,
            allDocsFetch: { isInProgress: true, progress: 1, error: null }
        };
    }
    if (action.type === ALL_DOCS_FETCH_PROGRESS) {
        return {
            ...state,
            allDocsFetch: { isInProgress: true, progress: action.payload, error: null }
        };
    }
    if (action.type === ALL_DOCS_FETCH_SUCCESS || action.type === HYDRATE_STATE_FROM_IDB) {
        const {lastSeq, docs} = action.payload;

        return {
            ...state,
            isInitializing: false,
            allDocsFetch: { isInProgress: false, progress: 0, error: null },
            lastSeq,
            docs,
            docsHash: computeDocsHash(docs),
            docsById: Object.fromEntries(docs.map(doc => [doc._id, doc])),
        };
    }
    if (action.type === ALL_DOCS_FETCH_ERROR) {
        return {
            ...state,
            allDocsFetch: { isInProgress: false, progress: 0, error: action.payload }
        };
    }


    if (action.type === CHANGES_NORMAL_FETCH_START) {
        return {
            ...state,
            changesNormalFetch: { isInProgress: true, error: null }
        };
    }
    if (action.type === CHANGES_NORMAL_FETCH_SUCCESS) {
        const docsById = {...state.docsById};

        for (const {id, doc, deleted} of action.payload.changes) {
            if (deleted)
                delete docsById[id];
            else
                docsById[id] = doc;
        }

        const docs = Object.values(docsById);

        return {
            ...state,
            docsById,
            docs,
            docsHash: computeDocsHash(docs),
            lastSeq: action.payload.lastSeq,
            changesNormalFetch: { isInProgress: false, error: null }
        };
    }
    if (action.type === CHANGES_NORMAL_FETCH_ERROR) {
        return {
            ...state,
            changesNormalFetch: { isInProgress: false, error: action.payload }
        };
    }


    if (action.type === CHANGES_FEED_FETCH_START) {
        return {
            ...state,
            changesFeedFetch: { isInProgress: true, error: null }
        };
    }
    if (action.type === CHANGES_FEED_FETCH_STOP) {
        return {
            ...state,
            changesFeedFetch: { isInProgress: false, error: state.changesFeedFetch.error }
        };
    }
    if (action.type === CHANGES_FEED_FETCH_SUCCESS) {
        if (action.payload.changes.length === 0)
            return state;

        const docsById = {...state.docsById};

        for (const {id, doc, deleted} of action.payload.changes) {
            if (deleted)
                delete docsById[id];
            else
                docsById[id] = doc;
        }

        const docs = Object.values(docsById);

        return {
            ...state,
            docsById,
            docs,
            docsHash: computeDocsHash(docs),
            lastSeq: action.payload.lastSeq,
            changesFeedFetch: { isInProgress: true, error: null }
        };
    }
    if (action.type === CHANGES_FEED_FETCH_ERROR) {
        return {
            ...state,
            changesFeedFetch: { isInProgress: true, error: action.payload }
        };
    }


    if (action.type === SET_REPORTS_LIST_FILTER) {
        const {label, value} = action.payload;

        const index = state.filters.findIndex(filter => filter.label === label);
        const filters = state.filters.slice(0, index === -1 ? state.filters.length : index);

        if (label === "timespan") {
            if (value.from || value.to)
                filters.push({label, value})
        } else if (Array.isArray(value)) { // если фильтр multiple, то у него value будет [...]
            if (value.length) // если это value непусто (т.е. была добавлена опция, а не очищено)
                filters.push({label, value});
        } else { // если фильтр не multiple, то value будет не Array
            if (value) // react-select выдает null, когда фильтр очищают
                filters.push({label, value});
        }

        return {...state, filters};
    }

    if (action.type === LOGGED_IN) {
        let {user, engineers} = action.payload;

        const {_id, isSales, isLawyer, isEngineer, isManager, isDirector, isAdmin} = user;

        const currentUser = {
            _id,
            isSales, isLawyer, isEngineer, isManager, isDirector, isAdmin,
            isManagement: isManager || isDirector || isAdmin,
        };

        engineers = engineers.map(e => {
            const nameLFP = `${e.lastName} ${e.firstName} ${e.patronym}`;
            const nameLF = `${e.lastName} ${e.firstName}`;
            const nameLf = `${e.lastName} ${e.firstName[0]}.`;
            const nameLfp = `${e.lastName} ${e.firstName[0]}. ${e.patronym[0]}.`;
            const nameLfp0 = `${e.lastName} ${e.firstName[0]}${e.patronym[0]}`;
            return ({...e, nameLFP, nameLF, nameLf, nameLfp, nameLfp0});
        });

        const engineersByCategory = {
            [ENGINEER_CATEGORY.ACTIVE]: engineers.filter(e => e.category === ENGINEER_CATEGORY.ACTIVE),
            [ENGINEER_CATEGORY.INACTIVE]: engineers.filter(e => e.category === ENGINEER_CATEGORY.INACTIVE),
            [ENGINEER_CATEGORY.FOREIGN]: engineers.filter(e => e.category === ENGINEER_CATEGORY.FOREIGN)
        }

        const engineersById = Object.fromEntries(engineers.map(engineer => [engineer._id, engineer]));

        return {
            ...state,
            isInitializing: true,
            currentUser,
            engineers,
            engineersById,
            engineersByCategory
        };
    }

    if (action.type === LOGGED_OUT) {
        return {
            ...state,
            currentUser: null,
            engineers: null,
            engineersById: null,
            changesFeedFetch: { isInProgress: false, error: null }
        };
    }

    if (action.type === SET_WAGES_TABLE_FILTER) {
        return {
            ...state,
            wagesEngineersTablePage: {
                ...state.wagesEngineersTablePage,
                ...action.payload
            },
        }
    }

    return state;
};