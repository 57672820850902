import React from "react";
import PropTypes from "prop-types";
import {FormGroup, Label, Checkbox, Select} from "spectre-react/dist/cjs";
import {WORK_TYPE, WORK_TYPE_LABEL} from "../../shared/sharedImport";


class WorkType extends React.PureComponent {
    static propTypes = {
        workType: PropTypes.string,
        onChange: PropTypes.func
    };

    WORK_TYPES = Object.entries(WORK_TYPE).map(([key, value]) => ({label: WORK_TYPE_LABEL[key], value}));
    CHECKBOX_TYPES = [WORK_TYPE.SUPPORT, WORK_TYPE.SLOT, WORK_TYPE.INSTALL, WORK_TYPE.TEACH, WORK_TYPE.MAINTENANCE];

    onChange = (ev) => {
        const workType = this.props.workType.split('');
        if (ev.target.checked)
            workType.push(ev.target.value);
        else
            workType.splice(workType.indexOf(ev.target.value), 1);
        this.props.onChange({target: {name: 'workType', value: workType.join('')}});
    };

    renderCheckboxes() {
        return this.props.workType.split('').filter(item => !(this.CHECKBOX_TYPES.includes(item))).length === 0;
    }

    render() {
        const {workType, onChange} = this.props;

        return this.renderCheckboxes()
            ? <FormGroup>
                <Label form>Вид работ <span className="text-bold">оставить пустым, если не попадает под категории</span></Label>
                {this.CHECKBOX_TYPES.map(wt =>
                    <Checkbox key={wt} inline name="workType" value={wt} checked={workType.includes(wt)} onChange={this.onChange}>
                        {WORK_TYPE_LABEL[wt]}
                    </Checkbox>
                )}
            </FormGroup>
            : <FormGroup>
                <Label form htmlFor="workType">Вид работ</Label>
                <Select name="workType" id="workType" onChange={onChange} value={workType}>
                    {this.WORK_TYPES.map(({label, value}) => <option key={value} value={value}>{label}</option>)}
                </Select>
            </FormGroup>;
    }
}


export default WorkType;