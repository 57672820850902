import React from 'react';
import cx from "classnames";
import {Redirect} from "react-router-dom";
import {Button, FormGroup, Loading, Toast, Input} from "spectre-react/dist/cjs";
import {submitReportApprovalChainDoc, fetchReportApprovalChainDoc} from '../../api';
import {SPECIAL_DOCS_PATH} from "../../paths";


// const ChainItem = ({index, onChange, userId, skip}) => {
//     return (
//         <FormGroup>
//             <div className="col-7 col-sm-12">
//                 <Input name={`chain.${index}.userId`} value={userId} placeholder="ID пользователя" onChange={onChange}/>
//             </div>
//             <div className="col-6 col-sm-12">
//                 <Checkbox name={`chain.${index}.skip`} checked={skip} onChange={onChange}>
//                     Не участвует
//                 </Checkbox>
//             </div>
//         </FormGroup>
//     )
// }


class ReportApprovalChainDocPage extends React.Component {
    state = {
        fetchState: 0, fetchError: undefined,
        submitState: 0, submitError: undefined,
        _rev: undefined, chainJSON: undefined, isValidJSON: true,
    };

    componentDidMount() {
        this.fetchDoc();
    }

    async fetchDoc() {
        this.setState({fetchState: 1, fetchError: undefined, _rev: undefined, chain: undefined});

        try {
            const {_rev, chain} = await fetchReportApprovalChainDoc();

            this.setState({fetchState: 2, _rev, chainJSON: JSON.stringify(chain, null, 4)});
        } catch (error) {
            console.error(error);
            this.setState({fetchState: 0, fetchError: error.message, _rev: undefined, chain: undefined});
        }
    }

    validateJSON = (ev) => {
        try {
            JSON.parse(ev.target.value);
            this.setState({isValidJSON: true});
        } catch (error) {
            this.setState({isValidJSON: false});
        }
    }

    submitDoc = async (ev) => {
        ev.preventDefault();

        this.setState({submitState: 1, submitError: undefined});

        try {
            const {_rev} = this.state;
            const chainJSON = ev.target.elements.chainJSON.value;
            const chain = JSON.parse(chainJSON);
            await submitReportApprovalChainDoc(_rev, chain);

            this.setState({submitState: 2});
        } catch (error) {
            this.setState({submitState: 0, submitError: error.message})
        }
    };

    render() {
        const {chainJSON, fetchState, fetchError, submitState, submitError, isValidJSON} = this.state;

        if (submitState === 2) {
            alert("Изменения успешно внесены.");
            return <Redirect to={SPECIAL_DOCS_PATH} />;
        }

        if (fetchError)
            return <Toast error>{fetchError}</Toast>;

        if (fetchState !== 2)
            return <Loading large />;

        return (
            <div id="special-doc-edit-page">
                <form className="container grid-md" onSubmit={this.submitDoc}>
                    <div className="h3 my-4">Цепочка пользователей, участвующих в процессе одобрения репорта</div>

                    <p>
                        Это буквально список пользователей, каждый элемент списка -- объект с двумя полями <code>userId</code> и <code>skip</code>.
                        Пользователи одобряют репорт по очереди в соответствии с этим списком.
                    </p>
                    <p>
                        Поле <code>skip</code> обозначает участие или неучастие данного пользователя в процессе одобрения.
                        Если <code>skip === true</code>, то пользователей не участвует в процессе, и это равносильно отсутствию пользователя в цепочке/списке.
                        Поле добавлено просто для удобства.
                    </p>
                    <p>
                        Если у всех пользователей <code>skip === true</code> или список пустой, то репорты автоматически одобряются при создании.
                        Если список пустой, то он должен быть литералом <code>'[]'</code>.
                    </p>
                    <p>
                        Кнопка <span className="chip bg-primary">Сохранить</span> становится неактивной в случае, когда в поле находится невалидный JSON.
                    </p>

                    <FormGroup>
                        <Input renderAs="textarea" rows="25" name="chainJSON"
                               defaultValue={chainJSON} onChange={this.validateJSON}
                               className={cx({'is-error': !isValidJSON})}/>
                    </FormGroup>

                    <div className="form-actions my-4">
                        {submitError && <Toast error className="mb-2">{submitError}</Toast>}

                        <Button type="submit" primary disabled={!isValidJSON}>Сохранить</Button>
                    </div>
                </form>
            </div>
        )
    }
}


export default ReportApprovalChainDocPage;