import React from 'react';
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import ReportForm from "./ReportForm/ReportForm";
import {postReport} from "../../api";
import {REPORTS_PATH} from "../../paths";
import {BILL_STATE, STATUS} from '../shared/sharedImport';
import {getCurrentUser} from "../../selectors";


class NewReportPage extends React.Component {
    state = {
        submitState: 0, submitError: null,
        report: {
            type: "report",
            clientId: this.props.location.state?.c || null,
            machineId: this.props.location.state?.m || null,
            tickets: '',
            mileage: '',
            engineerIds: !this.props.currentUser.isEngineer ? [] : [this.props.currentUser._id],
            watchers: '',
            problem: '',
            solution: '',
            status: STATUS.NOT_FIXED,
            workType: '',
            // replacements: [],
            // slotReplacements: [],
            workHours: [],
            travelHours: [],
            expenses: [],

            paymentType: BILL_STATE.NOT_ISSUED,
        }
    };

    onChange = (ev) => {
        const report = {...this.state.report};

        if (Array.isArray(ev.target))
            for (const target of ev.target)
                report[target.name] = target.value;
        else
            report[ev.target.name] = ev.target.value;

        this.setState({submitError: null, report});
    };

    onSubmit = async (ev) => {
        this.setState({submitError: null, submitState: 1});

        try {
            const report = this.state.report;
            await postReport(report);

            this.setState({submitError: null, submitState: 2});
        } catch (error) {
            this.setState({submitError: error.message, submitState: 0});
        }
    };

    render() {
        if (this.state.submitState === 2) {
            alert("Новый репорт успешно создан.");
            return <Redirect to={REPORTS_PATH} />;
        }

        const {submitState, submitError, report} = this.state;

        return (
            <div>
                <h1>Новый репорт</h1>

                <ReportForm isSubmitting={submitState === 1} submitError={submitError}
                            report={report} onChange={this.onChange} onSubmit={this.onSubmit}
                />
            </div>
        )
    }
}


function mapStateToProps(state, ownProps) {
    return {
        currentUser: getCurrentUser(state)
    }
}

export default connect(mapStateToProps)(NewReportPage);
