import React from "react";
import {postMachine} from "../../api";
import {Redirect} from "react-router-dom";
import {MACHINES_PATH} from "../../paths";
import MachineForm from "./MachineForm";


class NewMachinePage extends React.Component {
    state = {
        submitState: 0, submitError: null,
        machine: {
            type: "machine",

            manufacturer: "Durst",
            model: "",
            serial: "",
            clientId: this.props.location.state?.c || null,
            notes: "",

            location: {address: "", lat: "", lon: ""},

            yearOfIssue: "",
            installation: "",
            warrantyEnd: "",

            durst: {
                category: "0", // see PRINTER_CATEGORIES
                inkTypes: "",
                options: "",
                spotColors: "",
                bulb: {batch: "", type: "",},
                service: {start: "", end: "", refund: false, comment: ""},
                spares: {start: "", end: "", with: "", comment: ""},
                inspection: {start: "", end: "", times: "", comment: ""},
            }
        }
    };

    onChange = (ev) => {
        const machine = {...this.state.machine};

        if (Array.isArray(ev.target))
            for (const target of ev.target)
                machine[target.name] = target.value;
        else
            machine[ev.target.name] = ev.target.value;

        this.setState({submitError: '', machine});
    };

    onSubmit = async (machine) => {
        this.setState({submitError: '', submitSate: 1});

        try {
            await postMachine(machine);
            this.setState({submitError: null, submitState: 2});
        } catch (error) {
            this.setState({submitError: error.message, submitState: 0});
        }
    };

    render() {
        if (this.state.submitState === 2) {
            alert("Новое оборудование успешно создано.");
            return <Redirect to={MACHINES_PATH} />;
        }

        const {submitState, submitError, machine} = this.state;

        return (
            <div>
                <h1>Новое оборудование</h1>

                <MachineForm isSubmitting={submitState === 1} submitError={submitError}
                             machine={machine} onChange={this.onChange} onSubmit={this.onSubmit}
                />
            </div>
        )
    }
}


export default NewMachinePage;