import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {lightFormat, addDays} from "date-fns";
import {Checkbox, FormGroup, Label, Loading, Radio, Divider, Input, Toast, Button, Icon, Select} from "spectre-react/dist/cjs";
import {postWagesParams, fetchWagesParamsLast} from "../api";
import {SPECIAL_DOCS_PATH} from "../paths";
import {
    ENGINEER_CATEGORY,
    MACHINE_COEFF_GROUP_LABEL,
    MACHINE_COEFF_GROUP,
    WORK_TYPE_LABEL, TRIP_KIND,
} from "./shared/sharedImport";
import {getClients} from "../selectors";


class ClientWorkTimes extends React.PureComponent {
    static propTypes = {};

    constructor(props) {
        super(props);

        const clientWorkTimes = props.clientWorkTimes.slice();
        const clientWorkTimesArrayKeys = props.clientWorkTimes.map(() => Math.random().toString(16).slice(2));

        if (clientWorkTimes.length === 0) {
            clientWorkTimes.unshift({clientId: '', startTime: '', endTime: ''});
            clientWorkTimesArrayKeys.unshift(Math.random().toString(16).slice(2));
        }

        this.state = {
            selectOptions: [],
            clientWorkTimesArrayKeys,
            clientWorkTimes
        }
    }

    componentDidMount() {
        this.props.provideController({getState: this.getState});
    }

    getState = () => this.state;

    onChange = (ev) => {
        const [, indexStr, propName] = ev.target.name.match(/\w+\.(\d+)\.(\w+)/);
        const index = parseInt(indexStr, 10);

        const clientWorkTimes = this.state.clientWorkTimes.slice();
        clientWorkTimes[index] = {...clientWorkTimes[index], [propName]: ev.target.value};
        this.setState({clientWorkTimes});
    }

    renderRow(item, index, key) {
        const selectOptions = this.props.clients;

        return (
            <FormGroup key={key}>
                <div className="col-8 col-md-7 col-sm-6">
                    <Select small value={item.clientId} name={`clientWorkTimes.${index}.clientId`} onChange={this.onChange}>
                        <option value="">Выберите клиента:</option>
                        {selectOptions.map(([clientId, clientName]) => <option key={clientId} value={clientId}>{clientName}</option>)}
                    </Select>
                </div>
                <div className="col-4 col-md-5 col-sm-6 d-flex">
                    <div className="flex-1 d-flex">
                        {":"}
                        <Input small className="mx-1" type="text" placeholder="06:00"
                               value={item.startTime} onChange={this.onChange}
                               name={`clientWorkTimes.${index}.startTime`} id={`clientWorkTimes.${index}.startTime`}
                        />
                        {"–"}
                        <Input small className="mx-1" type="text" placeholder="15:00"
                               value={item.endTime} onChange={this.onChange}
                               name={`clientWorkTimes.${index}.endTime`} id={`clientWorkTimes.${index}.endTime`}
                        />
                    </div>
                    <Button action link small onClick={this.removeRow} data-index={index}>
                        <Icon icon="cross"/>
                    </Button>
                </div>
            </FormGroup>
        )
    }

    addRow = () => {
        this.setState(({clientWorkTimes, clientWorkTimesArrayKeys}) => ({
            clientWorkTimes: [...clientWorkTimes, {clientId: '', startTime: '', endTime: ''}],
            clientWorkTimesArrayKeys: [...clientWorkTimesArrayKeys, Math.random().toString(16).slice(2)]
        }));
    }

    removeRow = (ev) => {
        const index = parseInt(ev.currentTarget.dataset.index, 10);

        this.setState(({clientWorkTimes, clientWorkTimesArrayKeys}) => ({
            clientWorkTimes: clientWorkTimes.filter((_, i) => i !== index),
            clientWorkTimesArrayKeys: clientWorkTimesArrayKeys.filter((_, i) => i !== index)
        }));
    }

    render() {
        const {clientWorkTimes, clientWorkTimesArrayKeys} = this.state;

        return (
            <div className="form-horizontal px-1">
                {clientWorkTimes.map((item, index) => this.renderRow(item, index, clientWorkTimesArrayKeys[index]))}

                <Divider />

                <Button block link small onClick={this.addRow}>
                    <Icon icon="plus"/>
                </Button>
            </div>
        )
    }
}


function mapStateToProps1(state, ownProps) {
    const clients = getClients(state).map(client => [client._id, client.name]);
    return { clients };
}

ClientWorkTimes = connect(mapStateToProps1)(ClientWorkTimes);


class WagesParamsEditForm extends React.PureComponent {
    static propTypes = {
        engineers: PropTypes.array,
        calcParams: PropTypes.object, // TODO: PropTypes.shape?
    };

    state = {
        submitState: 0, submitError: undefined
    };

    setClientWorkTimesController = (methods) => this._clientWorkTimesController = methods;

    buildParamsFromForm(form) {
        let engineerCoeffs = Array.from(form.elements);
        engineerCoeffs = engineerCoeffs.filter(input => input.name.startsWith('engineerCoeffs.'));
        engineerCoeffs = engineerCoeffs.map(input => [input.name.slice('engineerCoeffs.'.length), parseFloat(input.value)]);
        engineerCoeffs = Object.fromEntries(engineerCoeffs);

        let engineerPayrollMethods = Array.from(form.elements);
        engineerPayrollMethods = engineerPayrollMethods.filter(input => input.name.startsWith('engineerPayrollMethods.') && input.checked);
        engineerPayrollMethods = engineerPayrollMethods.map(input => [input.name.slice('engineerPayrollMethods.'.length), input.value]);
        engineerPayrollMethods = Object.fromEntries(engineerPayrollMethods);

        let machinesCoeffs = {
            [MACHINE_COEFF_GROUP.GAMMA__PICTOCER]: parseFloat(form.elements['machinesCoeffs.gammaPictocer'].value),
            [MACHINE_COEFF_GROUP.RHOTEX__P5_250_HS]: parseFloat(form.elements['machinesCoeffs.rhotexP5250HS'].value),
            [MACHINE_COEFF_GROUP.MUTOH]: parseFloat(form.elements['machinesCoeffs.mutoh'].value),
            [MACHINE_COEFF_GROUP.REST]: parseFloat(form.elements['machinesCoeffs.rest'].value),
        };

        let workTypeCoeffs = {
            INSTALL: parseFloat(form.elements['workTypeCoeffs.INSTALL'].value),
            TEACH: parseFloat(form.elements['workTypeCoeffs.TEACH'].value),
            MAINTENANCE: parseFloat(form.elements['workTypeCoeffs.MAINTENANCE'].value),
            SLOT: parseFloat(form.elements['workTypeCoeffs.SLOT'].value),
            SUPPORT: parseFloat(form.elements['workTypeCoeffs.SUPPORT'].value),
        }

        let clientWorkTimes = this._clientWorkTimesController.getState().clientWorkTimes
            .filter(item => Object.values(item).some(item => !!item));

        const addedTimeTransport = Object.fromEntries(Object.keys(TRIP_KIND).map(kind => [kind, {}]));
        const addedTimeInputs = Array.from(form.elements).filter(el => el.name.startsWith('addedTimeTransport'));
        for (const input of addedTimeInputs) {
            const [, category, coeff] = input.name.split('.');
            addedTimeTransport[category][coeff] = parseFloat(input.value);
        }

        return {
            startDate: form.elements.startDate.value,
            params: {
                basePrice: parseFloat(form.elements.basePrice.value),
                workFromHomeCoeff: parseFloat(form.elements.workFromHomeCoeff.value),
                workFromOfficeCoeff: parseFloat(form.elements.workFromOfficeCoeff.value),
                workInHomeCityCoeff: parseFloat(form.elements.workInHomeCityCoeff.value),
                workInOtherCityCoeff: parseFloat(form.elements.workInOtherCityCoeff.value),
                addedTimeInHomeCityCoeff: parseFloat(form.elements.addedTimeInHomeCityCoeff.value),
                addedTimeInOtherCityRegularCoeff: parseFloat(form.elements.addedTimeInOtherCityRegularCoeff.value),
                addedTimeInOtherCityOvertimeCoeff: parseFloat(form.elements.addedTimeInOtherCityOvertimeCoeff.value),
                workdayUpTo8Coeff: parseFloat(form.elements.workdayUpTo8Coeff.value),
                workdayMoreThan8Coeff: parseFloat(form.elements.workdayMoreThan8Coeff.value),
                weekendUpTo8Coeff: parseFloat(form.elements.weekendUpTo8Coeff.value),
                weekendMoreThan8Coeff: parseFloat(form.elements.weekendMoreThan8Coeff.value),
                travelWorktimeCoeff: parseFloat(form.elements.travelWorktimeCoeff.value),
                travelNonWorktimeCoeff: parseFloat(form.elements.travelNonWorktimeCoeff.value),
                engineerPayrollMethods: engineerPayrollMethods,
                engineerCoeffs: engineerCoeffs,
                machinesCoeffs: machinesCoeffs,
                workTypeCoeffs: workTypeCoeffs,
                engineerCoeffsEnabled: form.elements.engineerCoeffsEnabled.checked,
                machinesCoeffsEnabled: form.elements.machinesCoeffsEnabled.checked,
                workTypeCoeffsEnabled: form.elements.workTypeCoeffsEnabled.checked,
                clientWorkTimes: clientWorkTimes,
                addedTimeTransport: addedTimeTransport,
            }
        };
    }

    onSubmit = async (ev) => {
        ev.preventDefault();

        const form = ev.target;
        const params = this.buildParamsFromForm(form);

        this.setState({submitState: 1, submitError: undefined});

        try {
            await postWagesParams(params);
            this.setState({submitState: 2, calcParams: params});
        } catch (error) {
            console.error(error);
            this.setState({submitState: 0, submitError: error.message});
        }
    }

    render() {
        const {submitState, submitError} = this.state;

        if (submitState === 2) {
            alert("Изменения успешно внесены.");
            return <Redirect to={SPECIAL_DOCS_PATH} />;
        }

        const {startDate, calcParams, action, engineers} = this.props;

        return (
            <form id="wages-params-form" className="columns" onSubmit={this.onSubmit}>
                <div className="column col-12">
                    <div className="h2">Редактирование коэффициентов ОТ</div>
                    {action === "add" && <p>Будет создан новый набор коэффициентов. Обратите внимание, что дата начала не может быть раньше завтрашнего дня.</p>}
                    {action === "edit" && <p>Будет отредактированы коэффициенты, которые еще не начали действовать. Обратите внимание, что дата начала в любом случае не может быть раньше завтрашнего дня.</p>}
                </div>
                <div className="column col-12">
                    <Divider/>
                </div>

                <div className="column col-3 col-lg-12">
                    <details className="accordion" open>
                        <summary className="accordion-header c-hand">
                            <Icon icon="arrow-right" className="mr-1"/> Коэффициенты 1
                        </summary>
                        <div className="accordion-body">
                            <FormGroup>
                                <Label form small htmlFor="basePrice">Базовая цена</Label>
                                <Input small type="text" name="basePrice" id="basePrice" placeholder="1250р"
                                       defaultValue={calcParams.basePrice}/>
                            </FormGroup>
                            <Divider/>
                            <FormGroup>
                                <Label form small htmlFor="workFromHomeCoeff"><b>k1</b> Работа из дома</Label>
                                <Input small type="text" name="workFromHomeCoeff" id="workFromHomeCoeff"
                                       placeholder="0.8" defaultValue={calcParams.workFromHomeCoeff}/>
                            </FormGroup>
                            <FormGroup>
                                <Label form small htmlFor="workFromOfficeCoeff"><b>k1</b> Работа из офиса</Label>
                                <Input small type="text" name="workFromOfficeCoeff" id="workFromOfficeCoeff"
                                       placeholder="0.8" defaultValue={calcParams.workFromOfficeCoeff}/>
                            </FormGroup>
                            <FormGroup>
                                <Label form small htmlFor="workInHomeCityCoeff"><b>k1</b> Работа из СПб</Label>
                                <Input small type="text" name="workInHomeCityCoeff" id="workInHomeCityCoeff"
                                       placeholder="1" defaultValue={calcParams.workInHomeCityCoeff}/>
                            </FormGroup>
                            <FormGroup>
                                <Label form small htmlFor="workInOtherCityCoeff"><b>k1</b> Работа в командировках</Label>
                                <Input small type="text" name="workInOtherCityCoeff" id="workInOtherCityCoeff"
                                       placeholder="1.2" defaultValue={calcParams.workInOtherCityCoeff}/>
                            </FormGroup>
                        </div>
                    </details>
                </div>

                <div className="column col-3 col-lg-12">
                    <details className="accordion" open>
                        <summary className="accordion-header c-hand">
                            <Icon icon="arrow-right" className="mr-1"/> Коэффициенты 2
                        </summary>
                        <div className="accordion-body">
                            <FormGroup>
                                <Label form small htmlFor="workdayUpTo8Coeff"><b>k2</b> Рабочий день до 8 часов</Label>
                                <Input small type="text" name="workdayUpTo8Coeff" id="workdayUpTo8Coeff" placeholder="1"
                                       defaultValue={calcParams.workdayUpTo8Coeff}/>
                            </FormGroup>
                            <FormGroup>
                                <Label form small htmlFor="workdayMoreThan8Coeff"><b>k2</b> Рабочий день свыше 8 часов</Label>
                                <Input small type="text" name="workdayMoreThan8Coeff" id="workdayMoreThan8Coeff"
                                       placeholder="1.5" defaultValue={calcParams.workdayMoreThan8Coeff}/>
                            </FormGroup>
                            <FormGroup>
                                <Label form small htmlFor="weekendUpTo8Coeff"><b>k2</b> Нерабочий день до 8 часов</Label>
                                <Input small type="text" name="weekendUpTo8Coeff" id="weekendUpTo8Coeff"
                                       placeholder="1.5" defaultValue={calcParams.weekendUpTo8Coeff}/>
                            </FormGroup>
                            <FormGroup>
                                <Label form small htmlFor="weekendMoreThan8Coeff"><b>k2</b> Нерабочий день свыше 8 часов</Label>
                                <Input small type="text" name="weekendMoreThan8Coeff" id="weekendMoreThan8Coeff"
                                       placeholder="2" defaultValue={calcParams.weekendMoreThan8Coeff}/>
                            </FormGroup>
                            <Divider/>
                            <FormGroup>
                                <Label form small htmlFor="travelWorktimeCoeff">Дорога в рабочее время 10-19 | {"<="} 8</Label>
                                <Input small type="text" name="travelWorktimeCoeff" id="travelWorktimeCoeff"
                                       placeholder="1.2" defaultValue={calcParams.travelWorktimeCoeff}/>
                            </FormGroup>
                            <FormGroup>
                                <Label form small htmlFor="travelNonWorktimeCoeff">Дорога в нерабочее время | {">"} 8</Label>
                                <Input small type="text" name="travelNonWorktimeCoeff" id="travelNonWorktimeCoeff"
                                       placeholder="1.2" defaultValue={calcParams.travelNonWorktimeCoeff}/>
                            </FormGroup>
                        </div>
                    </details>
                </div>

                <div className="column col-3 col-lg-12">
                    <details className="accordion" open>
                        <summary className="accordion-header c-hand">
                            <Icon icon="arrow-right" className="mr-1"/> Коэффициенты 3
                        </summary>
                        <div className="accordion-body">
                            <FormGroup>
                                <Divider/>
                            </FormGroup>
                            <FormGroup>
                                <Checkbox name="machinesCoeffsEnabled"
                                          defaultChecked={calcParams.machinesCoeffsEnabled}>
                                    Коэффициенты 3 включены
                                </Checkbox>
                            </FormGroup>
                            <FormGroup>
                                <Divider/>
                            </FormGroup>
                            <FormGroup>
                                <Label form small htmlFor="machinesCoeffs.gammaPictocer"><b>k3</b> {MACHINE_COEFF_GROUP_LABEL.GAMMA__PICTOCER}</Label>
                                <Input small type="text" name="machinesCoeffs.gammaPictocer"
                                       id="machinesCoeffs.gammaPictocer" placeholder="1"
                                       defaultValue={calcParams.machinesCoeffs?.GAMMA__PICTOCER}/>
                            </FormGroup>
                            <FormGroup>
                                <Label form small htmlFor="machinesCoeffs.rhotexP5250HS"><b>k3</b> {MACHINE_COEFF_GROUP_LABEL.RHOTEX__P5_250_HS}</Label>
                                <Input small type="text" name="machinesCoeffs.rhotexP5250HS"
                                       id="machinesCoeffs.rhotexP5250HS" placeholder="1"
                                       defaultValue={calcParams.machinesCoeffs?.RHOTEX__P5_250_HS}/>
                            </FormGroup>
                            <FormGroup>
                                <Label form small htmlFor="machinesCoeffs.mutoh"><b>k3</b> {MACHINE_COEFF_GROUP_LABEL.MUTOH}</Label>
                                <Input small type="text" name="machinesCoeffs.mutoh" id="machinesCoeffs.mutoh"
                                       placeholder="1" defaultValue={calcParams.machinesCoeffs?.MUTOH}/>
                            </FormGroup>
                            <FormGroup>
                                <Label form small htmlFor="machinesCoeffs.rest"><b>k3</b> {MACHINE_COEFF_GROUP_LABEL.REST}</Label>
                                <Input small type="text" name="machinesCoeffs.rest" id="machinesCoeffs.rest"
                                       placeholder="1" defaultValue={calcParams.machinesCoeffs?.REST}/>
                            </FormGroup>
                        </div>
                    </details>
                </div>

                <div className="column col-3 col-lg-12">
                    <details className="accordion" open>
                        <summary className="accordion-header c-hand">
                            <Icon icon="arrow-right" className="mr-1"/> Коэффициенты 4
                        </summary>
                        <div className="accordion-body">
                            <FormGroup>
                                <Divider/>
                            </FormGroup>
                            <FormGroup>
                                <Checkbox name="workTypeCoeffsEnabled" defaultChecked={calcParams.workTypeCoeffsEnabled}>
                                    Коэффициенты 4 включены
                                </Checkbox>
                            </FormGroup>
                            <FormGroup>
                                <Divider/>
                            </FormGroup>
                            <FormGroup>
                                <Label form small htmlFor="workTypeCoeffs.INSTALL"><b>k4</b> {WORK_TYPE_LABEL.INSTALL}</Label>
                                <Input small type="text" name="workTypeCoeffs.INSTALL" id="workTypeCoeffs.INSTALL"
                                       placeholder="1" defaultValue={calcParams.workTypeCoeffs?.INSTALL}/>
                            </FormGroup>
                            <FormGroup>
                                <Label form small htmlFor="workTypeCoeffs.TEACH"><b>k4</b> {WORK_TYPE_LABEL.TEACH}</Label>
                                <Input small type="text" name="workTypeCoeffs.TEACH" id="workTypeCoeffs.TEACH"
                                       placeholder="1" defaultValue={calcParams.workTypeCoeffs?.TEACH}/>
                            </FormGroup>
                            <FormGroup>
                                <Label form small
                                       htmlFor="workTypeCoeffs.MAINTENANCE"><b>k4</b> {WORK_TYPE_LABEL.MAINTENANCE}</Label>
                                <Input small type="text" name="workTypeCoeffs.MAINTENANCE"
                                       id="workTypeCoeffs.MAINTENANCE" placeholder="1"
                                       defaultValue={calcParams.workTypeCoeffs?.MAINTENANCE}/>
                            </FormGroup>
                            <FormGroup>
                                <Label form small htmlFor="workTypeCoeffs.SLOT"><b>k4</b> {WORK_TYPE_LABEL.SLOT}</Label>
                                <Input small type="text" name="workTypeCoeffs.SLOT" id="workTypeCoeffs.SLOT"
                                       placeholder="1" defaultValue={calcParams.workTypeCoeffs?.SLOT}/>
                            </FormGroup>
                            <FormGroup>
                                <Label form small htmlFor="workTypeCoeffs.SUPPORT"><b>k4</b> {WORK_TYPE_LABEL.SUPPORT}</Label>
                                <Input small type="text" name="workTypeCoeffs.SUPPORT" id="workTypeCoeffs.SUPPORT"
                                       placeholder="1" defaultValue={calcParams.workTypeCoeffs?.SUPPORT}/>
                            </FormGroup>
                        </div>
                    </details>
                </div>

                <div className="column col-3 col-lg-12">
                    <details className="accordion" open>
                        <summary className="accordion-header c-hand">
                            <Icon icon="arrow-right" className="mr-1"/> Коэффициенты 4.5
                        </summary>
                        <div className="accordion-body">
                            <FormGroup>
                                <Label form small htmlFor="addedTimeInHomeCityCoeff">На добавленное время при работах в
                                    СПб</Label>
                                <Input small type="text" name="addedTimeInHomeCityCoeff" id="addedTimeInHomeCityCoeff"
                                       placeholder="1" defaultValue={calcParams.addedTimeInHomeCityCoeff}/>
                            </FormGroup>
                            <FormGroup>
                                <Label form small htmlFor="addedTimeInOtherCityRegularCoeff">На добавленное время в
                                    командировках, рабочее время</Label>
                                <Input small type="text" name="addedTimeInOtherCityRegularCoeff"
                                       id="addedTimeInOtherCityRegularCoeff" placeholder="1"
                                       defaultValue={calcParams.addedTimeInOtherCityRegularCoeff}/>
                            </FormGroup>
                            <FormGroup>
                                <Label form small htmlFor="addedTimeInOtherCityOvertimeCoeff">На добавленное время в
                                    командировках, нерабочее время</Label>
                                <Input small type="text" name="addedTimeInOtherCityOvertimeCoeff"
                                       id="addedTimeInOtherCityOvertimeCoeff" placeholder="1"
                                       defaultValue={calcParams.addedTimeInOtherCityOvertimeCoeff}/>
                            </FormGroup>
                        </div>
                    </details>
                </div>

                <div className="column col-3 col-lg-12">
                    <details className="accordion" open>
                        <summary className="accordion-header c-hand">
                            <Icon icon="arrow-right" className="mr-1"/> Коэффициенты 5
                        </summary>
                        <div className="accordion-body">
                            <FormGroup>
                                <Divider/>
                            </FormGroup>
                            <FormGroup>
                                <Checkbox name="engineerCoeffsEnabled"
                                          defaultChecked={calcParams.engineerCoeffsEnabled}>
                                    Коэффициенты 5 включены
                                </Checkbox>
                            </FormGroup>
                            <FormGroup>
                                <Divider/>
                            </FormGroup>
                            <div className="form-horizontal">
                                {engineers.map(engineer => {
                                    return (
                                        <FormGroup key={engineer._id}>
                                            <div className="col-4 col-lg-12">
                                                <Label form small
                                                       htmlFor={`engineerCoeffs.${engineer._id}`}>
                                                    {engineer.nameLfp0}
                                                </Label>
                                            </div>
                                            <div className="col-8 col-lg-12 d-flex">
                                                <Input small className="form-inline col-3 mr-2" type="text"
                                                       placeholder="1"
                                                       name={`engineerCoeffs.${engineer._id}`}
                                                       id={`engineerCoeffs.${engineer._id}`}
                                                       defaultValue={calcParams.engineerCoeffs[engineer._id]}
                                                />
                                                <Radio inline small value="SHIFT"
                                                       name={`engineerPayrollMethods.${engineer._id}`}
                                                       id={`engineerPayrollMethods.${engineer._id}.shift`}
                                                       defaultChecked={calcParams.engineerPayrollMethods[engineer._id] === "SHIFT"}
                                                >
                                                    см.
                                                </Radio>
                                                <Radio inline small value="WORKTIME"
                                                       name={`engineerPayrollMethods.${engineer._id}`}
                                                       id={`engineerPayrollMethods.${engineer._id}.worktime`}
                                                       defaultChecked={calcParams.engineerPayrollMethods[engineer._id] === "WORKTIME"}
                                                >
                                                    10-19
                                                </Radio>
                                            </div>
                                        </FormGroup>
                                    )
                                })}
                            </div>
                        </div>
                    </details>
                </div>

                <div className="column col-6 col-lg-12">
                    <details className="accordion" open>
                        <summary className="accordion-header c-hand">
                            <Icon icon="arrow-right" className="mr-1"/> Рабочее время (default 10-19)
                        </summary>
                        <div className="accordion-body">
                            <ClientWorkTimes clientWorkTimes={calcParams.clientWorkTimes}
                                             provideController={this.setClientWorkTimesController}
                            />
                        </div>
                    </details>
                </div>

                <div className="column col-3 col-lg-12">
                    <details className="accordion" open>
                        <summary className="accordion-header c-hand">
                            <Icon icon="arrow-right" className="mr-1"/> Коэффициенты 6
                        </summary>
                        <div className="accordion-body">
                            <FormGroup>
                                <Label form small htmlFor="">Транспорт ТАКСИ (t*k1+k2)*k3</Label>
                                <div className="container">
                                    <div className="columns">
                                        <div className="column col-4 pl-0">
                                            <Input small type="text" name="addedTimeTransport.TAXI.k1" placeholder="1"
                                                   defaultValue={calcParams.addedTimeTransport.TAXI.k1}/>
                                        </div>
                                        <div className="column col-4">
                                            <Input small type="text" name="addedTimeTransport.TAXI.k2" placeholder="1"
                                                   defaultValue={calcParams.addedTimeTransport.TAXI.k2}/>
                                        </div>
                                        <div className="column col-4 pr-0">
                                            <Input small type="text" name="addedTimeTransport.TAXI.k3" placeholder="1"
                                                   defaultValue={calcParams.addedTimeTransport.TAXI.k3}/>
                                        </div>
                                    </div>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label form small htmlFor="addedTimeInOtherCityRegularCoeff">Транспорт ПОЕЗД
                                    (t*k1+k2)*k3</Label>
                                <div className="container">
                                    <div className="columns">
                                        <div className="column col-4 pl-0">
                                            <Input small type="text" name="addedTimeTransport.TRAIN.k1" placeholder="1"
                                                   defaultValue={calcParams.addedTimeTransport.TRAIN.k1}/>
                                        </div>
                                        <div className="column col-4">
                                            <Input small type="text" name="addedTimeTransport.TRAIN.k2" placeholder="1"
                                                   defaultValue={calcParams.addedTimeTransport.TRAIN.k2}/>
                                        </div>
                                        <div className="column col-4 pr-0">
                                            <Input small type="text" name="addedTimeTransport.TRAIN.k3" placeholder="1"
                                                   defaultValue={calcParams.addedTimeTransport.TRAIN.k3}/>
                                        </div>
                                    </div>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label form small htmlFor="addedTimeInOtherCityOvertimeCoeff">Транспорт САМОЛЕТ
                                    (t*k1+k2)*k3</Label>
                                <div className="container">
                                    <div className="columns">
                                        <div className="column col-4 pl-0">
                                            <Input small type="text" name="addedTimeTransport.PLANE.k1" placeholder="1"
                                                   defaultValue={calcParams.addedTimeTransport.PLANE.k1}/>
                                        </div>
                                        <div className="column col-4">
                                            <Input small type="text" name="addedTimeTransport.PLANE.k2" placeholder="1"
                                                   defaultValue={calcParams.addedTimeTransport.PLANE.k2}/>
                                        </div>
                                        <div className="column col-4 pr-0">
                                            <Input small type="text" name="addedTimeTransport.PLANE.k3" placeholder="1"
                                                   defaultValue={calcParams.addedTimeTransport.PLANE.k3}/>
                                        </div>
                                    </div>
                                </div>
                            </FormGroup>
                        </div>
                    </details>
                </div>

                <div className="column col-3 col-lg-12">
                    <details className="accordion" open>
                        <summary className="accordion-header c-hand">
                            <Icon icon="arrow-right" className="mr-1"/> Срок действия
                        </summary>
                        <div className="accordion-body">
                            <FormGroup>
                                <Label form small htmlFor="startDate">С этой даты</Label>
                                <Input small type="date" name="startDate" id="startDate"
                                       defaultValue={startDate}
                                />
                            </FormGroup>
                        </div>
                    </details>
                </div>

                <div className="column col-12">
                    <Divider/>
                </div>
                <div className="column col-12">
                    {submitError && <Toast error className="my-2">{submitError}</Toast>}

                    <Button primary type="submit">Отправить</Button>
                </div>
            </form>
        );
    }
}


class WagesParamsEditPage extends React.Component {
    static propTypes = {
        engineers: PropTypes.array,
    }

    state = {
        fetchState: 0, fetchError: undefined,
        calcParams: undefined,
        startDate: undefined,
        action: undefined,
    }

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        this.setState({fetchState: 1, fetchError: undefined});

        try {
            const {doc, action} = await fetchWagesParamsLast();

            const tomorrowISO = lightFormat(addDays(new Date(), 1), 'yyyy-MM-dd');
            const startDate = action === "add" ? tomorrowISO : doc.startDate;

            this.setState({fetchState: 2, startDate, action, calcParams: doc.params});
        } catch (error) {
            console.error(error);
            this.setState({fetchState: 0, fetchError: error.message});
        }
    }

    render() {
        const {fetchState, fetchError, calcParams, startDate, action} = this.state;

        if (fetchError)
            return <Toast error>{fetchError}</Toast>;

        if (fetchState !== 2)
            return <Loading large/>;

        return <WagesParamsEditForm calcParams={calcParams} startDate={startDate} action={action} engineers={this.props.engineers}/>
    }
}


const mapStateToProps = (state) => {
    const engineers = state.engineersByCategory[ENGINEER_CATEGORY.ACTIVE];
    return {engineers};
};


export default connect(mapStateToProps, null)(WagesParamsEditPage);