import React from "react";
import {
    addTransportAddress,
    confirmTransportAddress,
    fetchTransportAddresses,
    removeTransportAddress
} from "../../api";
import cx from "classnames";
import {Button, Divider, FormGroup, Input, Loading, Toast} from "spectre-react/dist/cjs";


class TransportAddressesModal extends React.Component {
    static defaultState = {
        isOpened: false,
        getState: 0, addState: 0, confirmState: 0, deleteState: 0,
        getError: undefined, addError: undefined, confirmError: undefined, deleteError: undefined,
    }

    state = {
        ...TransportAddressesModal.defaultState,
        confirmed: [], unconfirmed: [],
    };

    close = () => this.setState(TransportAddressesModal.defaultState);

    open = () => {
        this.setState({isOpened: true}, this.fetchAddresses);
    };

    fetchAddresses = () => {
        this.setState({getState: 1, getError: undefined, addError: undefined, confirmError: undefined, deleteError: undefined});

        fetchTransportAddresses()
            .then(({confirmed, unconfirmed}) => this.setState({confirmed, unconfirmed, getState: 2}))
            .catch(error => this.setState({getState: 0, confirmed: [], unconfirmed: [], getError: error.message}));
    }

    removeAddress = (address) => {
        this.setState({deleteState: 1, deleteError: undefined});

        removeTransportAddress(address)
            .then(() => this.setState({deleteState: 2}))
            .then(this.fetchAddresses)
            .catch(error => this.setState({deleteState: 0, deleteError: error.message}));
    }

    removeConfirmedAddress = (ev) => this.removeAddress(ev.currentTarget.parentElement.parentElement.dataset.email);
    removeUnconfirmedAddress = (ev) => this.removeAddress(ev.currentTarget.parentElement.parentElement.parentElement.dataset.email);

    addAddress = (ev) => {
        ev.preventDefault();

        const form = ev.target;
        const email = form.elements.email.value;

        this.setState({addState: 1, addError: undefined});

        addTransportAddress(email)
            .then(() => this.setState({addState: 2}))
            .then(() => form.reset())
            .then(this.fetchAddresses)
            .catch(error => this.setState({addState: 0, addError: error.message}));
    }

    confirmAddress = (ev) => {
        const email = ev.currentTarget.parentElement.parentElement.dataset.email;
        const token = ev.currentTarget.previousElementSibling.value;

        this.setState({confirmState: 1, confirmError: undefined});

        confirmTransportAddress(email, token)
            .then(() => this.setState({confirmState: 2}))
            .then(this.fetchAddresses)
            .catch(error => this.setState({confirmState: 0, confirmError: error.message}));
    }

    render() {
        if (!this.state.isOpened)
            return null;

        const {
            getError, addError, confirmError, deleteError,
            confirmed, unconfirmed,
        } = this.state;

        const isFetching = this.state.getState === 1;
        const isDeleting = this.state.deleteState === 1;
        const isConfirming = this.state.confirmState === 1;
        const isAdding = this.state.addState === 1;
        const isLoading = isFetching || isDeleting || isConfirming || isAdding;

        return (
            <div className="modal modal-sm active" id="transport-addresses-modal">
                <span className="modal-overlay" onClick={this.close}/>
                <div className="modal-container modal-fullheight">
                    <div className="modal-header">
                        <button type="button" className="btn btn-clear float-right" disabled={isLoading} onClick={this.close}/>
                        <div className="modal-title h5">
                            Мои адреса
                            {isLoading && <Loading className="ml-4" renderAs="span" />}
                        </div>
                    </div>

                    <div className="modal-body">
                        {getError && <Toast error className="mb-1">{getError}</Toast>}
                        {deleteError && <Toast error className="mb-1">{deleteError}</Toast>}

                        {confirmed.length > 0 && (
                            <FormGroup>
                                <div className="h6 text-bold mb-2">Подтвержденные</div>
                                {confirmed.map(address => (
                                    <div key={address} data-email={address} className="text-hover-primary flex-centered mt-1">
                                        <span className="flex-1">{address}</span>
                                        <span className="pl-2">
                                            <Button action link small loading={isDeleting} onClick={this.removeConfirmedAddress}>
                                                <span className="material-icons">delete_forever</span>
                                            </Button>
                                        </span>
                                    </div>
                                ))}
                            </FormGroup>
                        )}

                        {confirmed.length > 0 && <Divider />}

                        {unconfirmed.length > 0 && (
                            <FormGroup>
                                <div className="h6 text-bold mb-2">Нужно подтвердить</div>
                                {confirmError && <Toast error className="mb-1">{confirmError}</Toast>}
                                {unconfirmed.map(address => (
                                    <div key={address} data-email={address} className="text-hover-primary my-2">
                                        <div className="mb-1 flex-centered">
                                            <span className="pr-2">
                                                <Button action link small loading={isDeleting} onClick={this.removeUnconfirmedAddress}>
                                                    <span className="material-icons">delete_forever</span>
                                                </Button>
                                            </span>
                                            <span className="text-italic flex-1">{address}</span>
                                        </div>
                                        <div className="input-group">
                                            <Input type="text" placeholder="Код из письма"/>
                                            <Button primary loading={isConfirming} className="input-group-btn" onClick={this.confirmAddress}>
                                                <span className="material-icons">check</span>
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                            </FormGroup>
                        )}

                        {unconfirmed.length > 0 && <Divider />}

                        <FormGroup>
                            <div className="h6 text-bold mb-2">Добавить адрес</div>
                            {addError && <Toast error className="mb-1">{addError}</Toast>}
                            <form onSubmit={this.addAddress}>
                                <div className="input-group">
                                    <Input type="email" name="email" placeholder="Ваш email"/>
                                    <Button type="submit" primary loading={isAdding} className="input-group-btn">
                                        <span className="material-icons">send</span>
                                    </Button>
                                </div>
                                <span className="form-input-hint text-bold">На этот адрес придет письмо с кодом</span>
                            </form>
                        </FormGroup>
                    </div>


                    <div className="modal-footer">
                        <Button primary block disabled={isLoading} onClick={this.close}>Закрыть</Button>
                    </div>
                </div>
            </div>
        )
    }
}


export default TransportAddressesModal;