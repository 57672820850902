import React from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import * as paths from '../paths';
import {signOut} from "../api";
import {LOGGED_OUT} from "../store/actions";
import {ReactComponent as BillIcon} from '../icons/bills.svg';
import {ReactComponent as PaymentIcon} from '../icons/payments.svg';
import {Button, Divider, Icon} from "spectre-react/dist/cjs";
import {PAYOUTS_PAGE_PATH} from "../paths";


class Navbar extends React.Component {
    signOut = () => {
        signOut()
            .then(this.props.onLoggedOut)
            .catch(error => alert(error.message));
    };

    render() {
        const currentUser = this.props.currentUser;
        const {isEngineer, isManagement, isAdmin} = currentUser;

        const isPollingError = this.props.isPollingError;

        return (
            <div id="navbar">
                <header className="navbar mb-2">
                    <section className="navbar-section hide-lg">
                        <NavLink to={paths.REPORTS_PATH}>
                            {!isPollingError && <img src='/logo.svg' className="img-responsive" alt="Логотип SignArt" />}
                            {isPollingError && <img src='/logo_black.svg' className="img-responsive" alt="Логотип SignArt" />}
                        </NavLink>
                    </section>

                    <section className="navbar-section">
                        {isManagement && <NavLink className="btn btn-link" to={paths.SPECIAL_DOCS_PATH}><span className="material-icons">settings</span></NavLink>}
                        <NavLink className="btn btn-link" exact to={paths.TRANSPORT_EMAILS_PATH}>
                            {/*<span className="material-icons">local_taxi</span>*/}
                            {/*<span className="material-icons">train</span>*/}
                            <span className="material-icons">flight</span>
                        </NavLink>
                        {(isEngineer || isManagement) && <NavLink className="btn btn-link" exact to={paths.CALENDAR_PATH}><span className="material-icons">calendar_month</span></NavLink>}
                        {isManagement && <NavLink className="btn btn-link" exact to={paths.WAGES_ENGINEERS_TABLE_PATH}>ОТ-E</NavLink>}
                        {isAdmin && (
                            <NavLink className="btn btn-link" exact to={paths.PAYOUTS_PAGE_PATH}>
                                <span className="material-icons-outlined">engineering</span>
                                <span className="material-icons-outlined">request_quote</span>
                            </NavLink>
                        )}
                        {isManagement && (
                            <NavLink className="btn btn-link" exact to={paths.PAYMENTS_TABLE_PATH} id="navbar-btn__payments">
                                <PaymentIcon/>
                            </NavLink>
                        )}
                        {isManagement && (
                            <NavLink className="btn btn-link" exact to={paths.BILLS_TABLE_PATH} id="navbar-btn__bills">
                                <BillIcon/>
                            </NavLink>
                        )}
                        <div className="btn-group">
                            <NavLink className="btn btn-link" to={paths.REPORTS_PATH}>Репорты</NavLink>
                            <NavLink className="btn btn-link" exact to={paths.NEW_REPORT_PATH}>
                                <Icon icon="plus"/>
                            </NavLink>
                        </div>
                        <NavLink className="btn btn-link" exact to={paths.CLIENTS_PATH}>Клиенты</NavLink>
                        <NavLink className="btn btn-link" exact to={paths.MACHINES_PATH}>Оборудование</NavLink>
                        <Button link onClick={this.signOut}>Выйти</Button>
                    </section>
                </header>

                <Divider id="divider"/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isPollingError: !!state.changesFeedFetch.error
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLoggedOut: () => dispatch({ type: LOGGED_OUT }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);