import React from "react";
import PropTypes from "prop-types";
import memoizeOne from "memoize-one";
import cx from "classnames";
import DayPicker from "react-day-picker";
import {
    compareAsc, differenceInMonths,
    format,
    getDate,
    getYear,
    isBefore, isLastDayOfMonth,
    isSameMonth,
    lightFormat,
    parseISO,
    setDay,
    setHours, startOfDay,
    startOfWeek
} from "date-fns";
import {ru} from "date-fns/locale";
import rubFmt from "../shared/summaFormatter";
import {Button} from "spectre-react/dist/cjs";


class MonthCalendar extends React.PureComponent {
    static propTypes = {
        month: PropTypes.instanceOf(Date),
        forceFullWeeks: PropTypes.bool,
        showDaysOfWeek: PropTypes.bool,
        firstDayOfWeek: PropTypes.number,
        selectRange: PropTypes.bool,
        onPickDate: PropTypes.func,
        onPickRange: PropTypes.func,
        onMonthChange: PropTypes.func,
        selectedDay: PropTypes.instanceOf(Date),
        yearDays: PropTypes.objectOf(PropTypes.shape({
            payAmount: PropTypes.number,
        })),
        payAmountsByMonth: PropTypes.objectOf(PropTypes.number),
        customClasses: PropTypes.object,
    }

    static defaultProps = {
        month: new Date(),
        forceFullWeeks: false,
        showDaysOfWeek: true,
        firstDayOfWeek: 1,
        selectRange: false,
    }

    constructor(props) {
        super(props);

        this.state = {
            selectingRange: undefined,
        }

        MonthCalendar.computeClassesForDates = memoizeOne(MonthCalendar.computeClassesForDates);
    }

    static computeClassesForDates(customClasses) {
        if (!customClasses || customClasses instanceof Function)
          return customClasses;

        customClasses = {...customClasses};

        for (const key in customClasses) {
            if (Array.isArray(customClasses[key]))
                customClasses[key] = customClasses[key].map(dateStr => parseISO(dateStr))
        }

        return customClasses;
    }

    renderWeekday({weekday, className, localeUtils, locale}) {
        const day = setDay(startOfWeek(new Date()), weekday);
        const dayNameRU = format(day, 'EEEEEE', {locale: ru});

        return (
            <div className={className}>
                {dayNameRU}
            </div>
        )
    }

    renderCaption = ({date, classNames, months, localeUtils, locale, onClick}) => {
        const firstDayOfMonthStr = lightFormat(date, 'yyyy-MM-dd');
        const monthPayAmount = this.props.payAmountsByMonth[firstDayOfMonthStr];

        const monthStr = format(date, 'LLLL yyyy', {locale: ru});

        const isPastMonth = differenceInMonths(startOfDay(new Date()), startOfDay(date)) >= 1;

        const captionStr = isPastMonth
            ? `${monthStr} – ${rubFmt(monthPayAmount)}`
            : monthStr;

        classNames = cx(classNames.caption, 'text-capitalize');

        return (
            <div className={classNames}>
                <div onClick={onClick}>
                    {captionStr}
                </div>
            </div>
        );
    }

    renderDay = (day) => {
        const dateStr = lightFormat(day, 'yyyy-MM-dd');
        const yearDays = this.props.yearDays;
        const payAmount = (dateStr in yearDays) ? yearDays[dateStr].payAmount : undefined;
        const payAmountStr = payAmount > 0 ? rubFmt(payAmount) : '';

        return (
            <>
                <span className="text-small DayPicker-DayNumber">{getDate(day)}</span>
                <span className="text-small text-bold">{payAmountStr}</span>
            </>
        );
    }

    renderNavbar = ({
        classNames,
        className,
        showPreviousButton,
        showNextButton,
        onPreviousClick,
        onNextClick,
        labels,
        dir
    }) => {
        const previousClassName = showPreviousButton
            ? classNames.navButtonPrev
            : `${classNames.navButtonPrev} ${classNames.navButtonInteractionDisabled}`;

        const nextClassName = showNextButton
            ? classNames.navButtonNext
            : `${classNames.navButtonNext} ${classNames.navButtonInteractionDisabled}`;

        const onPreviousButtonClick = showPreviousButton ? () => onPreviousClick() : undefined;
        const previousButton = (
            <Button action link className={previousClassName} onClick={onPreviousButtonClick}>
                &laquo;
            </Button>
        );

        const onNextButtonClick = showNextButton ? () => onNextClick() : undefined;
        const nextButton = (
            <Button action link className={nextClassName} onClick={onNextButtonClick}>
                &raquo;
            </Button>
        );

        return (
            <div className={className || classNames.navBar}>
                {previousButton}
                {nextButton}
            </div>
        );
    }

    dayClicked = (date, modifiers) => {
        if (!date)
            return;

        date = setHours(date, 0);

        let {selectingRange} = this.state;
        const {selectRange, onPickRange, onPickDate} = this.props;

        if (!selectRange) {
            if (onPickDate instanceof Function) {
                onPickDate(date);
            }
            return;
        }

        if (!selectingRange) {
            selectingRange = [date, date];
        } else {
            if (onPickRange instanceof Function) {
                if (selectingRange[0] > date) {
                    onPickRange(date, selectingRange[0]);
                } else {
                    onPickRange(selectingRange[0], date);
                }
            }
            selectingRange = undefined;
        }

        this.setState({ selectingRange });
    }

    dayHovered = (hoveredDay) => {
        if (!hoveredDay || !this.props.selectRange)
            return;

        let {selectingRange} = this.state;

        if (selectingRange) {
            selectingRange = [selectingRange[0], hoveredDay];
            this.setState({ selectingRange });
        }
    }

    render() {
        const {
            month, onMonthChange, selectedDay, selectRange, selectedRange,
            forceFullWeeks, firstDayOfWeek, showDaysOfWeek
        } = this.props;
        const customClasses = MonthCalendar.computeClassesForDates(this.props.customClasses);
        const { selectingRange } = this.state;

        let selectedDays = selectedDay;
        if (selectRange) {
            // selectingRange is used while user is selecting a range
            // (has clicked on start day, and is hovering end day - but not yet clicked)
            let start = (selectingRange || selectedRange)[0];
            let end = (selectingRange || selectedRange)[1];

            // validate range
            if (isBefore(end, start))
                [end, start] = selectingRange || selectedRange;

            selectedDays = { from: start, to: end };
        }

        return (
            <DayPicker
                canChangeMonth
                // showOutsideDays
                firstDayOfWeek={firstDayOfWeek}
                numberOfMonths={2}
                fixedWeeks={forceFullWeeks}
                showWeekDays={showDaysOfWeek}
                modifiers={customClasses}
                month={month}
                selectedDays={selectedDays}
                onMonthChange={onMonthChange}
                onDayClick={this.dayClicked}
                onDayMouseEnter={this.dayHovered}
                weekdayElement={this.renderWeekday}
                renderDay={this.renderDay}
                captionElement={this.renderCaption}
                navbarElement={this.renderNavbar}
            />
        )
    }
}

export default MonthCalendar;