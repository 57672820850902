import React from 'react';
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';
import cx from 'classnames';
import memoize from 'memoize-one';
import {differenceInDays} from "date-fns";
import {Checkbox, Input, Button, Icon, Select} from "spectre-react/dist/cjs";
import {NEW_MACHINE_PATH, NEW_REPORT_PATH, editMachinePath, editClientPath} from "../paths";
import {fetchCSV} from "../api";
import {date1to2s, formatDate1, LocationPropType} from './shared/sharedImport';
import {connect} from "react-redux";
import {getMachinesWithClients} from "../selectors";


const DerivedMachinesPropType = PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    client: PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
        location: LocationPropType,
        service: PropTypes.string,
        repair: PropTypes.string,
    }),
    manufacturer: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    serial: PropTypes.string.isRequired,
    yearOfIssue: PropTypes.string,
    installation: PropTypes.string,
    warrantyEnd: PropTypes.string,
    warranty: PropTypes.string,
    notes: PropTypes.string,

    durst: PropTypes.shape({
        service: PropTypes.string,
        spares: PropTypes.string,
        inspection: PropTypes.string,
        options: PropTypes.string,
        spotColors: PropTypes.string,
        inkTypes: PropTypes.string,
        bulb: PropTypes.string
    }),
    location: LocationPropType
}));


function renderLocation(machine, client) {
    if (machine.address || (machine.lat && machine.lon))
        return (
            <div>
                {machine.lat && machine.lon &&
                    <a href={`https://yandex.ru/maps/?text=${machine.lat},${machine.lon}&z=12`} target="_blank" rel="noopener noreferrer">
                        <Icon icon="location"/>
                    </a>
                } {machine.address}
            </div>
        );
    else if (client.address || (client.lat && client.lon))
        return (
            <div className="text-gray tooltip" data-tooltip="Адрес клиента">
                {client.lat && client.lon &&
                    <a href={`https://yandex.ru/maps/?text=${client.lat},${client.lon}&z=12`} target="_blank" rel="noopener noreferrer">
                        <Icon icon="location"/>
                    </a>
                } {client.address}
            </div>
        );
    else
        return <div/>;
}

function renderContractPersons(persons) {
    return (
        <div>
            {persons.map(person =>
                <div key={person.name}>
                    <span>{person.name}</span>,
                    <span className="float-right">{person.phone}</span>
                </div>
            )}
        </div>
    )

}


class Aristo extends React.PureComponent {
    static propTypes = {
        machines: PropTypes.array,
        headers: PropTypes.array,
        currentUser: PropTypes.object.isRequired,
    };

    renderRow = (machine) => {
        const currentUser = this.props.currentUser;
        const {isEngineer, isManagement} = currentUser;

        return (
            <div key={machine._id} className="table-row">
                <div>
                    <Link to={editClientPath(machine.client)} className="tooltip" data-tooltip="Редактировать клиента">{machine.client.name}</Link>
                </div>
                <div>
                    <Link to={editMachinePath(machine)} className="tooltip" data-tooltip="Редактировать оборудование">{machine.model}</Link>
                </div>
                <div>{
                    (isEngineer || isManagement) &&
                    <Link to={{pathname: NEW_REPORT_PATH, state: {m: machine._id, c: machine.client._id}}} className="tooltip" data-tooltip="Новый репорт">{machine.serial}</Link>
                }</div>
                <div>
                    {machine.warranty}
                </div>
                <div>
                    {machine.client.aristoService}
                </div>
                {renderLocation(machine.location, machine.client.location)}
            </div>
        )
    };

    render() {
        const {machines, headers} = this.props;

        return (
            <div>
                <ul>{headers.map(message => <li>{message}</li>)}</ul>

                <div id="machines-table" className="custom-table">
                    <div className="table-row table-head">
                        <div>Клиент</div>
                        <div>Оборудование</div>
                        <div>Сист. номер</div>
                        <div>Гарантия</div>
                        <div>Сервис</div>
                        <div>Адрес</div>
                    </div>

                    {machines.map(this.renderRow)}
                </div>
            </div>
        );
    }
}


class Durst extends React.Component {
    static propTypes = {
        machines: PropTypes.array,
        headers: PropTypes.array,
    };

    static getDerivedStateFromProps(props, state) {
        return {machines: Durst.filter(props.machines, state.filters)};
    }

    static filter = memoize(
        (machines, filters) => {
            for (const key in filters) {
                const re = new RegExp(filters[key], 'i');
                switch (key) {
                    case 'model': machines = machines.filter(m => re.test(m.model) || re.test(m.client.name)); break;
                    case 'serial': machines = machines.filter(m => re.test(m.serial)); break;
                    case 'serviceFW': machines = machines.filter(m => !!m.client.durstService); break;
                    case 'inspection': machines = machines.filter(m => !!m.durst.inspection); break;
                    case 'repair': machines = machines.filter(m => !!m.client.durstRepair); break;
                    case 'location': machines = machines.filter(m => re.test(m.location.address)); break;
                    case 'bulb': machines = machines.filter(m => re.test(m.durst.bulb)); break;
                    case 'options': machines = machines.filter(m => re.test(m.durst.options)); break;
                    case 'spotColors': machines = machines.filter(m => re.test(m.durst.spotColors)); break;
                    case 'inkTypes': machines = machines.filter(m => re.test(m.durst.inkTypes)); break;
                    case 'notes': machines = machines.filter(m => re.test(m.notes)); break;
                }
            }
            return machines;
        }
    );

    N = 15;
    state = {
        shownNum: this.N,
        lastColumn: 'contractPersons',
        filters: {},
        machines: []
    };

    componentDidMount() {
        // [document.body.scrollHeight - window.innerHeight, window.scrollY]
        window.addEventListener('scroll', this.onScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    }

    onScroll = () => {
        if (document.body.scrollHeight - window.innerHeight - 400 < window.scrollY &&
            this.state.shownNum < this.state.machines.length)
            this.setState({shownNum: this.state.shownNum + this.N});
    };

    onFilterChange = (ev) => {
        const {type, name, value, checked} = ev.target;
        const filters = Object.assign({}, this.state.filters);

        if (type === "checkbox" && checked) filters[name] = true;
        else if (type !== "checkbox" && value) filters[name] = value;
        else delete filters[name];

        this.setState({filters, shownNum: this.N});
    };

    onColumnSelect = (ev) => {
        if (ev.target.value !== this.state.lastColumn) {
            const filters = Object.assign({}, this.state.filters);
            delete filters[this.state.lastColumn];
            this.setState({lastColumn: ev.target.value, filters, shownNum: this.N});
        }
    };

    renderLastColumn = (machine) => {
        switch (this.state.lastColumn) {
            case 'contractPersons': return renderContractPersons(machine.client.contractPersons)
            case 'location': return renderLocation(machine.location, machine.client.location);
            case 'notes': return <div>{machine.notes}</div>;
            case 'bulb': return <div className="ws-pl">{machine.durst.bulb}</div>;
            case 'options': return <div>{machine.durst.options}</div>;
            case 'spotColors': return <div>{machine.durst.spotColors}</div>;
            case 'inkTypes': return <div>{machine.durst.inkTypes}</div>;
        }
    };

    renderRow = (machine) => {
        const currentUser = this.props.currentUser;
        const {isEngineer, isManagement} = currentUser;

        const client = machine.client;

        return (
            <div className="table-row" key={machine._id} data-machine-id={machine._id}>
                <div>
                    <div>
                        <Link to={editMachinePath(machine)} className="tooltip" data-tooltip="Редактировать оборудование">{machine.model}</Link>
                    </div>
                    <div>
                        {machine.clientId && <Link to={editClientPath(client._id)} className="tooltip text-grey text-tiny" data-tooltip="Редактировать клиента">{client.name}</Link>}
                    </div>
                </div>

                <div>
                    <div className={cx({'text-primary': !!machine.warranty})}>{
                        (isEngineer || isManagement) && machine.clientId
                            ? <Link to={{pathname: NEW_REPORT_PATH, state: {m: machine._id, c: client._id}}} className="tooltip text-bold" data-tooltip="Новый репорт">{machine.serial}</Link>
                            : machine.serial
                    }</div>
                    <div className="text-primary text-grey text-tiny">{machine.warranty}</div>
                </div>

                <div>
                    <div className="text-bold">{machine.durst.service}</div>
                    <div className="text-bold">{machine.client.durstService}</div>
                    <div>{machine.durst.spares}</div>
                    <div>{machine.durst.inspection}</div>
                    <div>{machine.client.durstRepair}</div>
                </div>

                {this.renderLastColumn(machine)}
            </div>
        );
    };

    render() {
        const {lastColumn, filters, shownNum} = this.state;
        const machines = this.state.machines.slice(0, shownNum);
        const headers = this.props.headers;

        return (
            <div>
                <ul>{headers.map(message => <li>{message}</li>)}</ul>

                <div id="machines-table" className="custom-table">
                    <div className="table-row table-head">
                        <div data-col="model" className="sortable">
                            <div className="mb-2">Модель и клиент</div>
                            <Input small name="model" value={filters.model} onChange={this.onFilterChange}/>
                        </div>
                        <div data-col="serial" className="sortable">
                            <div className="mb-2">Сист. номер</div>
                            <Input small name="serial" value={filters.serial} onChange={this.onFilterChange}/>
                        </div>
                        <div>
                            <div className="mb-2">Договоры</div>
                            <Checkbox inline name="serviceFW" checked={filters.serviceFW} onChange={this.onFilterChange}>рам.</Checkbox>
                            <Checkbox inline name="repair" checked={filters.repair} onChange={this.onFilterChange}>-восст. голов</Checkbox>
                        </div>
                        <div className="m-select">
                            <Select small className="mb-1" value={lastColumn} onChange={this.onColumnSelect}>
                                <option value='contractPersons'>Уполномоч. подписывать акт</option>
                                <option value='location'>Адрес</option>
                                <option value='notes'>Заметки</option>
                                <option value='bulb'>УФ лампы</option>
                                <option value='options'>Опции</option>
                                <option value='spotColors'>Цвета</option>
                                <option value='inkTypes'>Чернила</option>
                            </Select>
                            <Input small name={lastColumn} value={filters[lastColumn]} onChange={this.onFilterChange} />
                        </div>
                    </div>

                    {machines.map(this.renderRow)}
                </div>
            </div>
        );
    }
}


class MachinesList extends React.Component {
    static propTypes = {
        currentUser: PropTypes.object.isRequired,
        machines: PropTypes.shape({
            Aristo: DerivedMachinesPropType,
            Durst: DerivedMachinesPropType
        }),
        headers: PropTypes.shape({
            Aristo: PropTypes.arrayOf(PropTypes.string),
            Durst: PropTypes.arrayOf(PropTypes.string)
        })
    };

    state = {
        Component: 'Durst',
    };

    onTabClick = (ev) => this.setState({Component: ev.currentTarget.dataset.component});

    sendCSV = () => {
        switch (this.state.Component) {
            case 'Durst': {
                let output = "data:text/csv;charset=utf-8," +
                    "Клиент;Модель;Системный номер;Инсталляция;Конец гарантии;Опции;Цвета;Чернила;Сервис;Допгарантия;Адрес\n";
                for (const machine of this.props.machines.Durst) {
                    const {client, model, serial, installation, warrantyEnd} = machine;
                    const {options, spotColors, inkTypes, service, spares} = machine.durst;
                    const address = machine.location.address || client.location.address || '';
                    output += `${client.name};${model};${serial};${installation || ""};${warrantyEnd || ""};` +
                        `${options || ""};${spotColors || ""};${inkTypes || ""};${service};${spares};${address}\n`;
                }
                fetchCSV(output, `durst-${new Date().toISOString()}.csv`);
                break;
            }

            case 'Aristo': {
                let output = "data:text/csv;charset=utf-8," +
                    "Клиент;Модель;Системный номер;Инсталляция;Конец гарантии;Адрес\n";
                for (const machine of this.props.machines.Aristo) {
                    const {client, model, serial, installation, warrantyEnd} = machine;
                    const address = machine.location.address || client.location.address || '';
                    output += `${client.name};${model};${serial};${installation || ""};${warrantyEnd || ""};${address}\n`;
                }
                fetchCSV(output, `aristo-${new Date().toISOString()}.csv`);
                break;
            }
        }
    };

    render() {
        const {currentUser, machines, headers} = this.props;
        const Component = this.state.Component;

        return (
            <div>
                <div id="machines-table-tabs-container" className="mb-4 d-flex">
                    <div className="stackoverflow-44348868">
                        <Link className="btn btn-primary" to={NEW_MACHINE_PATH}>Создать оборудование</Link>
                    </div>

                    <ul id="machines-table-tabs" className="tab">
                        <li className={cx({"tab-item c-hand": true, "active": Component === "Durst"})}
                            onClick={this.onTabClick} data-component="Durst">
                            <a><img src="/durst.svg" alt="Durst" className='img-responsive'/></a>
                        </li>
                        <li className={cx({"tab-item c-hand": true, "active": Component === "Aristo"})}
                            onClick={this.onTabClick} data-component="Aristo">
                            <a><img src="/aristo.svg" alt="Aristo" id="aristo-img" className='img-responsive'/></a>
                        </li>
                    </ul>

                    <div className="stackoverflow-44348868">
                        <Button primary className="float-right" onClick={this.sendCSV}>
                            <Icon icon="download"/> CSV
                        </Button>
                    </div>
                </div>

                {Component === 'Durst' && <Durst currentUser={currentUser} machines={machines.Durst} headers={headers.Durst}/>}
                {Component === 'Aristo' && <Aristo currentUser={currentUser} machines={machines.Aristo} headers={headers.Aristo}/>}
            </div>
        );
    }
}


function mapStateToProps(state, ownProps) {
    const machines = getMachinesWithClients(state);

    // same machine.clientId => same machine.client object
    const clientsById = {};
    for (const machine of machines) {
        if (machine.client) {
            if (!(machine.clientId in clientsById)) {
                clientsById[machine.clientId] = machine.client;
            }
        }
    }
    for (const clientId in clientsById) {
        const client = clientsById[clientId]

        let contract = client.durstService;
        client.durstService = contract.active ? `${contract.comment} ${contract.refund ? '(с возмещением расходов)' : ''}` : '';

        contract = client.durstRepair;
        client.durstRepair = contract.active ? contract.comment : '';

        contract = client.aristoService;
        client.aristoService = contract.active ? `${contract.comment} ${contract.refund ? '(c возмещением расходов)' : ''}` : '';
    }

    const durst = machines.filter(machine => machine.manufacturer === "Durst");
    const aristo = machines.filter(machine => machine.manufacturer === "Aristo");

    const today = formatDate1(new Date());
    const headers = {Durst: [], Aristo: []};

    for (const machine of durst) {
        machine.durst = {...machine.durst};

        let contract = machine.durst.service;

        if (contract.end && today <= contract.end) {
            const left = differenceInDays(new Date(contract.end), new Date()) + 1;
            machine.durst.service = `${contract.comment} до ${date1to2s(contract.end)} (${left} д.) ${contract.refund ? '(с возмещением расходов)' : ''}`;
            if (-1 < left && left < 21)
                headers.Durst.push(`Durst ${machine.model}, ${machine.serial}: через ${left} д. заканчивается сервисный денежный контракт.`);
        } else
            machine.durst.service = '';

        contract = machine.durst.spares;

        if (contract.end && today <= contract.end) {
            const left = differenceInDays(new Date(contract.end), new Date()) + 1;
            machine.durst.spares = `[${contract.with || '?'}] ${contract.comment} до ${date1to2s(contract.end)} (${left} д.)`;
            if (-1 < left && left < 21)
                headers.Durst.push(`Durst ${machine.model}, ${machine.serial}: через ${left} д. заканчивается контракт на допгарантию.`);
        } else
            machine.durst.spares = '';

        contract = machine.durst.inspection;

        if (contract.end && today <= contract.end) {
            const left = differenceInDays(new Date(contract.end), new Date()) + 1;
            machine.durst.inspection = `${contract.comment} до ${date1to2s(contract.end)} (${left} д.), всего ${contract.times} раз`;
            if (-1 < left && left < 21)
                headers.Durst.push(`Durst ${machine.model}, ${machine.serial}: через ${left} д. заканчивается контракт на инспекцию голов.`);
        } else
            machine.durst.inspection = '';

        machine.durst.bulb = `${machine.durst.bulb.type}\n${machine.durst.bulb.batch}`;
    }

    for (const machine of durst.concat(aristo)) {
        if (!machine.client) {
            machine.client = {
                _id: '',
                name: '',
                location: {address: ''},
                durstService: '',
                aristoService: '',
                durstRepair: '',
                contractPersons: []
            };
        }

        const warrantyEnd = machine.warrantyEnd;

        if (warrantyEnd && (today <= warrantyEnd)) {
            const left = differenceInDays(new Date(machine.warrantyEnd), new Date()) + 1;
            machine.warranty = `Гарантия еще ${left} д.`;
            if (-1 < left && left < 21 && (machine.manufacturer === 'Durst' || machine.manufacturer === 'Aristo'))
                headers[machine.manufacturer].push(`${machine.manufacturer} ${machine.model}, ${machine.serial}: через ${left} д. заканчивается гарантия.`);
        } else
            machine.warranty = '';
    }

    const sortFn = (a, b) => a.model.localeCompare(b.model) || a.serial.localeCompare(b.serial);

    return {
        headers,
        machines: {
            Aristo: aristo.sort(sortFn),
            Durst: durst.sort(sortFn)
        }
    };
}


export default connect(mapStateToProps)(MachinesList);