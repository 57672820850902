import React from "react";
import {Link} from "react-router-dom";
import {Icon, Button} from "spectre-react/dist/cjs";
import {editReportPath} from "../../paths";
import {
    BILL_STATE,
    BILL_STATE_LABEL,
    PAYMENT_TYPE,
    PAYMENT_TYPE_LABEL,
    REPORT_APPROVAL_STAGE
} from "../shared/sharedImport";
import cx from "classnames";


const EditReportLinkButton = ({reportId, ...rest}) => {
    const path = editReportPath(reportId);

    return (
        <Link className="btn btn-link" to={path} title='Редактировать репорт' {...rest}>
            <Icon icon="edit"/>
        </Link>
    )
}


function getPaymentStatusButtonColor(paymentType) {
    switch (paymentType) {
        case BILL_STATE.ERROR: return 'text-primary';
        case BILL_STATE.NOT_ISSUED: return 'text-primary';
        case BILL_STATE.ISSUED: return 'text-error';
        case BILL_STATE.PART_PAY: return 'text-warning';
        default: return 'text-success';
    }
}

function getPaymentStatusButtonIcon(paymentType) {
    switch (paymentType) {
        case PAYMENT_TYPE.CONTRACT: return 'format_list_numbered';
        case PAYMENT_TYPE.WARRANTY: return 'done';
        case PAYMENT_TYPE.GOODWILL: return 'card_giftcard';
        default: return 'euro_symbol';
    }
}

const PaymentStatusButton = ({paymentType, reportId, ...rest}) => {
    const cls = `btn btn-link ${getPaymentStatusButtonColor(paymentType)}`;
    const title = BILL_STATE_LABEL[paymentType] || PAYMENT_TYPE_LABEL[paymentType];
    const iconName = getPaymentStatusButtonIcon(paymentType);

    return (
        <Button className={cls} data-id={reportId} title={title} {...rest}>
            <i className="material-icons">{iconName}</i>
        </Button>
    )
}


const BewertungButton = ({clScore: clientScore, manScore: managerScore, comment, reportId, ...rest}) => {
    const cls = cx("btn btn-link", {"badge": !!comment});

    return (
        <Button className={cls} data-id={reportId} title="Заметки" {...rest}>
            {clientScore || '-'}/{managerScore || '-'}
        </Button>
    )
}


const ApprovalActionButton = ({approvalStage, disabled = false, reportId, ...rest}) => {
    // priority_high, pending_actions

    const isInProgress = approvalStage === REPORT_APPROVAL_STAGE.IN_PROGRESS;
    const isApproved = approvalStage === REPORT_APPROVAL_STAGE.APPROVED;

    const className = isApproved ? "text-success" : (disabled ? "text-gray" : "text-warning");

    return (
        <Button link className={className} data-id={reportId} disabled={disabled} {...rest}>
            {isInProgress && <i className="material-icons-outlined">priority_high</i>}
            {isApproved && <i className="material-icons">event_available</i>}
        </Button>
    )
}


export {
    EditReportLinkButton,
    ApprovalActionButton,
    PaymentStatusButton,
    BewertungButton
}