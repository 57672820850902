import React from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Button, Checkbox, Icon, Input} from "spectre-react/dist/cjs";
import {ModalContainerError, ModalContainerLoading} from "../shared/Modals";
import {SingleSelect} from "../shared/Select";
import {fetchPayment, fetchBills, postLinks} from "../../api";
import {formatDate2} from '../shared/sharedImport';
import {getClientsById} from "../../selectors";


async function fetchData(paymentId, clientsById) {
    try {
        let {bills, links, payment} = await fetchPayment(paymentId);

        const map = {};
        for (const link of links) {
            const billId = link._id.slice(0, link._id.indexOf(':'));
            map[billId] = link;
        }

        const items = await fetchBills({balance: 'positive'});
        for (const item of items) {
            const {_id, numDoc: NUM_DOC, date: DATE, summa: SUMMA, clientId} = item;

            if (bills.findIndex(b => b._id === _id) === -1) {
                const bill = {_id, NUM_DOC, DATE: new Date(DATE), SUMMA, clientId};
                bills.push(bill);

                map[_id] = {
                    type: "bill_payment",
                    _id: `${_id}:${payment._id}`,
                    billNumDoc: NUM_DOC,
                    billDate: DATE,
                    paymentNumDoc: payment.NUM_DOC,
                    paymentDate: payment.DATE_PL,
                    SUMMA: Math.min(SUMMA, payment.SUMMA),
                    _deleted: true
                }
            }
        }

        payment.DATE_PL = new Date(payment.DATE_PL);
        payment.clientName = payment.clientId ? clientsById[payment.clientId].name : payment.PL_NAME;

        bills = bills.map(bill => {
            bill.DATE = new Date(bill.DATE);
            bill.clientName = clientsById[bill.clientId].name;
            return bill;
        });

        return {payment, bills, links: map};
    } catch (error) {
        return {error: error.message};
    }
}


function PaymentEditModalFormBillsTable({bills, links, onSummaChange, removeBill}) {
    if (bills.length === 0)
        return null;

    return (
        <table className="table mb-2">
            <thead><tr><th>№ счета</th><th>Дата</th><th>Сумма</th><th>В ее счет</th><th/></tr></thead>
            <tbody>
            {bills.map(item =>
                <tr key={item._id}>
                    <td>{item.NUM_DOC}</td>
                    <td>{formatDate2(item.DATE)}</td>
                    <td>{item.SUMMA}</td>
                    <td>
                        <Input small type="number" name={`"links.${item._id}.SUMMA`}
                               value={links[item._id].SUMMA} onChange={onSummaChange}/>
                    </td>
                    <td>
                        <Button primary small data-bill-id={item._id} onClick={removeBill}>
                            <Icon icon="cross"/>
                        </Button>
                    </td>
                </tr>
            )}
            </tbody>
        </table>
    )
}

class PaymentEditModalForm extends React.PureComponent {
    static propTypes = {
        bill: PropTypes.object,
        links: PropTypes.object,
        taken: PropTypes.arrayOf(PropTypes.object),
        available: PropTypes.arrayOf(PropTypes.object),
        showAll: PropTypes.bool,
        onChange: PropTypes.func,
        close: PropTypes.func,
        submit: PropTypes.func
    };

    onSummaChange = (ev) => {
        const paymentId = ev.target.name.split('.')[1];
        const links = {...this.props.links};
        links[paymentId] = {...links[paymentId], SUMMA: ev.target.value};
        this.props.onChange({target: {name: 'links', value: links}});
    };

    toggleBill = (billId) => {
        const links = {...this.props.links};
        const _deleted = !links[billId]._deleted;
        links[billId] = {...links[billId], _deleted};
        this.props.onChange({target: {name: 'links', value: links}});
    };

    removeBill = (ev) => this.toggleBill(ev.currentTarget.dataset.billId);
    addBill = (ev) => this.toggleBill(ev.target.value._id);

    billToString(item) {
        return item ? `Счет № ${item.NUM_DOC} от ${formatDate2(item.DATE)} на ${item.SUMMA} р. (${item.clientName})` : '';
    }

    render() {
        const {taken, available, links, payment, showAll} = this.props;
        const title = `Пп № ${payment.NUM_DOC} от ${formatDate2(payment.DATE_PL)} на ${payment.SUMMA} р. (${payment.clientName})`;

        return (
            <div className="modal-container">
                <div className="modal-header">
                    <span className="btn btn-clear float-right" onClick={this.props.close}/>
                    <div className="modal-title h5">{title}</div>
                </div>
                <div className="modal-body">
                    <PaymentEditModalFormBillsTable bills={taken} links={links} onSummaChange={this.onSummaChange} removeBill={this.removeBill}/>
                    <SingleSelect items={available} itemToString={this.billToString} name="available"
                                  selectedItem={null} onChange={this.addBill} searchable={true}
                    />
                    <Checkbox name="bills.showAll" checked={showAll} onChange={this.props.onChange}>Показать все счета</Checkbox>
                </div>
                <div className="modal-footer">
                    <Button primary className="mr-2" onClick={this.props.submit}>Отправить</Button>
                    <Button link onClick={this.props.close}>Закрыть</Button>
                </div>
            </div>
        );
    }
}

class PaymentEditModalFormWithBillsFilter extends React.PureComponent {
    static propTypes = {
        bills: PropTypes.arrayOf(PropTypes.object),
        links: PropTypes.object,
        payment: PropTypes.object,
        onChange: PropTypes.func,
        close: PropTypes.func,
        submit: PropTypes.func
    };

    state = {showAll: false};

    onChange = (ev) => {
        if (ev.target.name === "bills.showAll")
            this.setState({showAll: ev.target.checked});
        else
            this.props.onChange(ev);
    };

    render() {
        let {bills, links, payment, close, submit} = this.props;
        const showAll = this.state.showAll;

        let taken = [], available = [];
        for (const bill of bills)
            (links[bill._id]._deleted ? available : taken).push(bill);
        if (!showAll)
            available = available.filter(bill => bill.clientId === payment.clientId);

        return <PaymentEditModalForm taken={taken} available={available} links={links} payment={payment} showAll={showAll} onChange={this.onChange} close={close} submit={submit}/>;
    }
}

class PaymentEditModal extends React.PureComponent {
    static propTypes = {
        provideController: PropTypes.func.isRequired,
        clientsById: PropTypes.objectOf(PropTypes.object).isRequired,
        onSubmit: PropTypes.func.isRequired,
    };

    static getDefaultState() {
        return {active: false, loading: false, error: '', payment: null, bills: null, links: null};
    }

    state = PaymentEditModal.getDefaultState();

    componentDidMount() {
        this.props.provideController({open: this.open, close: this.close});
    }

    open = async (paymentId) => {
        this.setState({loading: true, active: true});
        const {payment, bills, links} = await fetchData(paymentId, this.props.clientsById);
        this.setState({loading: false, payment, bills, links});
    };

    close = () => this.setState(PaymentEditModal.getDefaultState());

    submit = () => {
        // we should update those having _rev and create 1) not having _rev 2) not marked as _deleted, i.e. newly added
        const links = Object.values(this.state.links).filter(link => link._rev || !link._deleted);
        postLinks(links)
            .then(this.close)
            .then(this.props.onSubmit)
            .catch(error => this.setState({error: error.message}));
    };

    onChange = (ev) => this.setState({[ev.target.name]: ev.target.value});

    renderContainer() {
        if (this.state.loading)
            return <ModalContainerLoading close={this.close}/>;

        if (this.state.error)
            return <ModalContainerError error={this.state.error} close={this.close}/>;

        const {bills, payment, links} = this.state;
        return <PaymentEditModalFormWithBillsFilter bills={bills} payment={payment} links={links} onChange={this.onChange} close={this.close} submit={this.submit}/>
    }

    render() {
        if (!this.state.active)
            return null;

        return (
            <div className="modal active" id="bill-edit-modal">
                <span className="modal-overlay" onClick={this.close}/>
                {this.renderContainer()}
            </div>
        )
    }
}


function mapStateToProps(state, ownProps) {
    return {
        clientsById: getClientsById(state),
    }
}

export default connect(mapStateToProps)(PaymentEditModal);