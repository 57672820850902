import React from "react";
import PropTypes from "prop-types";
import {Button, Checkbox, FormGroup, Input, Label, Radio, Select, Toast} from "spectre-react/dist/cjs";
import {SimpleAutocomplete, SingleSelect} from "../shared/Select";
import {DateInput, FormLocation, ObjectField} from "../shared/Forms";
import DayPickerInputRange from "../shared/DayPickerInputRange";
import {PRINTER_CATEGORIES} from "../shared/sharedImport";
import {connect} from "react-redux";
import {getClients, getMachines} from "../../selectors";


class MachineForm extends React.Component {
    static propTypes = {
        machine: PropTypes.shape({
            type: PropTypes.oneOf(['machine']).isRequired,
            _id: PropTypes.string,
            _rev: PropTypes.string,

            clientId: PropTypes.string.isRequired,
            manufacturer: PropTypes.string.isRequired,
            model: PropTypes.string.isRequired,
            serial: PropTypes.string.isRequired,
            yearOfIssue: PropTypes.string,
            installation: PropTypes.string,
            warrantyEnd: PropTypes.string,
            notes: PropTypes.string,

            durst: PropTypes.shape({
                service: PropTypes.shape({
                    start: PropTypes.string,
                    end: PropTypes.string,
                    refund: PropTypes.bool,
                    comment: PropTypes.string
                }),
                spares: PropTypes.shape({
                    start: PropTypes.string,
                    end: PropTypes.string,
                    with: PropTypes.string,
                    comment: PropTypes.string
                }),
                inspection: PropTypes.shape({
                    start: PropTypes.string,
                    end: PropTypes.string,
                    times: PropTypes.string,
                    comment: PropTypes.string,
                }),
                category: PropTypes.string,
                options: PropTypes.string,
                spotColors: PropTypes.string,
                inkTypes: PropTypes.string,
                bulb: PropTypes.shape({
                    batch: PropTypes.string,
                    type: PropTypes.string,
                }),
                phInit: PropTypes.objectOf(PropTypes.shape({
                    serialNum: PropTypes.string,
                    status: PropTypes.number,
                    slots: PropTypes.objectOf(PropTypes.shape({
                        serialNum: PropTypes.string,
                        status: PropTypes.number
                    }))
                }))
            }),

            location: PropTypes.shape({
                address: PropTypes.string,
                lat: PropTypes.string,
                lon: PropTypes.string
            }),
        }),

        isSubmitting: PropTypes.bool,
        submitError: PropTypes.string,
        isDestroying: PropTypes.bool,
        destroyError: PropTypes.string,
        onChange: PropTypes.func,
        onDestroy: PropTypes.func,
        onSubmit: PropTypes.func
    };

    // clients = [];
    // manufacturers = [];
    // models = new Map();

    state = {
        selectedClient: null,
    }

    async componentDidMount() {
        if (window.location.hash)
            setTimeout(() => document.getElementById(window.location.hash.slice(1)).scrollIntoView(), 100);

        const clientId = this.props.machine.clientId;
        if (clientId) {
            const selectedClient = this.props.clients.find(client => client._id === clientId) || null;
            this.setState({selectedClient});
        } else {
            this.forceUpdate();
        }
    }

    onClientChange = (ev) => {
        const selectedClient = ev.target.value;
        const clientId = selectedClient?._id || null;
        const upperEvent = {target: {name: ev.target.name, value: clientId}};

        this.setState({selectedClient}, () => this.props.onChange(upperEvent));
    }

    onSubmit = () => {
        const machine = {...this.props.machine};

        if (machine.manufacturer !== "Durst")
            delete machine.durst;

        return this.props.onSubmit(machine);
    };

    render() {
        const {machine, isSubmitting, isDestroying, submitError, destroyError, onChange, onDestroy} = this.props;
        const selectedClient = this.state.selectedClient;

        const isLoading = isSubmitting || isDestroying;
        const error = submitError || destroyError;

        const models = this.props.models.get(machine.manufacturer) || [];

        return (
            <form id='machine-form'>
                <fieldset className="mb-6">
                    <legend className="h2">Общее</legend>

                    <div className='columns'>
                        <div className="column col-3 col-sm-12">
                            <FormGroup>
                                <Label form htmlFor="clientId">Клиент</Label>
                                <SingleSelect items={this.props.clients} selectedItem={selectedClient}
                                              clearable={true} searchable={true}
                                              name="clientId" placeholder="Клиент" onChange={this.onClientChange}
                                />
                            </FormGroup>
                        </div>
                        <div className="column col-3 col-sm-12">
                            <FormGroup>
                                <Label form htmlFor="manufacturer">Производитель</Label>
                                <SimpleAutocomplete items={this.props.manufacturers} name="manufacturer" placeholder="Пример: Durst"
                                                    value={machine.manufacturer} onChange={onChange}/>
                            </FormGroup>
                        </div>
                        <div className="column col-3 col-sm-12">
                            <FormGroup>
                                <Label form htmlFor="model">Модель</Label>
                                <SimpleAutocomplete items={models} name="model" placeholder="Пример: Rho1312AF"
                                                    value={machine.model} onChange={onChange}/>
                            </FormGroup>
                        </div>

                        <div className="column col-3 col-sm-12">
                            <FormGroup>
                                <Label form htmlFor="serial">Серийный номер</Label>
                                <Input type="text" id="serial" name="serial" value={machine.serial} onChange={onChange} placeholder="Пример: 9001237"/>
                            </FormGroup>
                        </div>

                        <div className="column col-3 col-sm-12">
                            <FormGroup>
                                <Label form htmlFor="serial">Год производства</Label>
                                <Input type="number" id="yearOfIssue" name="yearOfIssue" value={machine.yearOfIssue} onChange={onChange} placeholder="Пример: 2005" min="0" step="1"/>
                            </FormGroup>
                        </div>

                        <div className="column col-3 col-sm-12">
                            <Label form htmlFor="installation">Дата пусконаладки</Label>
                            <DateInput id="installation" className="form-input" name="installation" value={machine.installation} placeholder="Пример: 26.03.2015" onChange={onChange}/>
                        </div>

                        <div className="column col-3 col-sm-12">
                            <Label form htmlFor="warrantyEnd">Дата конца гарантии</Label>
                            <DateInput id="warrantyEnd" className="form-input" name="warrantyEnd" value={machine.warrantyEnd} placeholder="Пример: 26.03.2015" onChange={onChange}/>
                        </div>

                        <div className="column col-3 col-sm-12">
                            <FormGroup>
                                <Label form htmlFor="notes">Заметки</Label>
                                <Input type="text" id="notes" name="notes" value={machine.notes} onChange={onChange}/>
                            </FormGroup>
                        </div>
                    </div>
                </fieldset>

                {machine.durst &&
                    <ObjectField object={machine.durst} onChange={onChange}>
                        {({object: durst, onChange}) =>
                            <fieldset className="mb-6">
                                <legend className="h2">Durst</legend>

                                <div className='columns mb-6'>
                                    <ObjectField object={durst.service} onChange={onChange}>
                                        {({object, onChange}) =>
                                            <div className='column col-sm-12 col-4'>
                                                <h4>Сервисный денежный контракт</h4>

                                                <FormGroup>
                                                    <Label form htmlFor="durst.service.start">Период действия</Label>
                                                    <DayPickerInputRange idFrom="durst.service.start" idTo="sdurst.ervice.end" className="form-input" onChange={onChange}
                                                                         nameFrom="durst.service.start" nameTo="durst.service.end" from={object.start} to={object.end} />
                                                </FormGroup>

                                                <FormGroup>
                                                    <Checkbox name="durst.service.refund" checked={object.refund} onChange={onChange}>
                                                        Возмещение проезда и проживания
                                                    </Checkbox>
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label form htmlFor="durst.service.comment">Комментарий</Label>
                                                    <Input type="text" id="durst.service.comment" name="durst.service.comment" value={object.comment} onChange={onChange}/>
                                                </FormGroup>
                                            </div>}
                                    </ObjectField>

                                    <ObjectField object={durst.spares} onChange={onChange}>
                                        {({object, onChange}) =>
                                            <div id="spares-container" className='column col-sm-12 col-4'>
                                                <h4>Контракт на доп. гарантию (з/ч)</h4>

                                                <FormGroup>
                                                    <Label form htmlFor="durst.spares.start">Период действия</Label>
                                                    <DayPickerInputRange idFrom="durst.spares.start" idTo="durst.pares.end" className="form-input" onChange={onChange}
                                                                         nameFrom="durst.spares.start" nameTo="durst.spares.end" from={object.start} to={object.end} />
                                                </FormGroup>

                                                <FormGroup>
                                                    Поддерживается&nbsp;&nbsp;
                                                    <Radio inline name="durst.spares.with" value="D" checked={object.with === "D"} onChange={onChange}>Durst</Radio>
                                                    <Radio inline name="durst.spares.with" value="SA" checked={object.with === "SA"} onChange={onChange}>SignArt</Radio>
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label form htmlFor="durst.spares.comment">Комментарий</Label>
                                                    <Input type="text" id="durst.spares.comment" name="durst.spares.comment" value={object.comment} onChange={onChange}/>
                                                </FormGroup>
                                            </div>}
                                    </ObjectField>

                                    <ObjectField object={durst.inspection} onChange={onChange}>
                                        {({object, onChange}) =>
                                            <div className='column col-sm-12 col-4'>
                                                <h4>Инспекция голов</h4>

                                                <FormGroup>
                                                    <Label form htmlFor="durst.inspection.start">Период действия</Label>
                                                    <DayPickerInputRange idFrom="durst.inspection.start" idTo="durst.inspection.end" className="form-input" onChange={onChange}
                                                                         nameFrom="durst.inspection.start" nameTo="durst.inspection.end" from={object.start} to={object.end} />
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label form htmlFor="durst.inspection.times">Количество визитов</Label>
                                                    <Input type="number" id="durst.inspection.times" name="durst.inspection.times" value={object.times} onChange={onChange} min="0" step="1"/>
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label form htmlFor="durst.inspection.comment">Комментарий</Label>
                                                    <Input type="text" id="durst.inspection.comment" name="durst.inspection.comment" value={object.comment} onChange={onChange}/>
                                                </FormGroup>
                                            </div>}
                                    </ObjectField>
                                </div>

                                <fieldset>
                                    <legend className="h4">Другое</legend>

                                    <div className='columns'>
                                        <div className='column col-sm-12 col-4'>
                                            <FormGroup>
                                                <Label form htmlFor="durst.category">Категория</Label>
                                                <Select name="durst.category" id="durst.category" onChange={onChange} value={durst.category}>
                                                    {PRINTER_CATEGORIES.map(({label, value}) => <option key={value} value={value}>{label}</option>)}
                                                </Select>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label form htmlFor="durst.options">Опции</Label>
                                                <Input type="text" id="durst.options" name="durst.options" value={durst.options} onChange={onChange} placeholder="Пример: automatic feeding"/>
                                            </FormGroup>
                                        </div>
                                        <div className='column col-sm-12 col-4'>
                                            <FormGroup>
                                                <Label form htmlFor="durst.spotColors">Цвета</Label>
                                                <Input type="text" id="durst.spotColors" name="durst.spotColors" value={durst.spotColors} onChange={onChange} placeholder="Пример: lc,lm"/>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label form htmlFor="durst.inkTypes">Чернила</Label>
                                                <Input type="text" id="durst.inkTypes" name="durst.inkTypes" value={durst.inkTypes} onChange={onChange} placeholder="Пример: PopHS(CMYKLclM), FP.Sol"/>
                                            </FormGroup>
                                        </div>
                                        <ObjectField object={durst.bulb} onChange={onChange}>
                                            {({object, onChange}) =>
                                                <div className='column col-sm-12 col-4'>
                                                    <FormGroup>
                                                        <Label form htmlFor="durst.bulb.batch">УФ: партия</Label>
                                                        <Input type="text" id="durst.bulb.batch" name="durst.bulb.batch" value={object.batch} onChange={onChange} placeholder="Пример: 1891688"/>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label form htmlFor="durst.bulb.type">УФ: тип</Label>
                                                        <Input type="text" id="durst.bulb.type" name="durst.bulb.type" value={object.type} onChange={onChange} placeholder="Пример: 1380mm, 21kW"/>
                                                    </FormGroup>
                                                </div>}
                                        </ObjectField>
                                    </div>
                                </fieldset>
                            </fieldset>
                        }
                    </ObjectField>}

                <FormLocation location={machine.location} onChange={onChange} />

                <div className="form-actions">
                    {error && <Toast error className="mb-2">{error}</Toast>}

                    <div className="action-buttons">
                        {onDestroy && <Button error loading={isLoading} className="mr-2" onClick={onDestroy}>Удалить</Button>}
                        <Button success loading={isLoading} onClick={this.onSubmit}>Отправить</Button>
                    </div>
                </div>
            </form>
        );
    }
}


function mapStateToProps(state, ownProps) {
    // TODO: memoization required
    // TODO: this function computes on every change in the MachineForm because it's rendered after every change

    const clients = getClients(state)
        .map(client => ({_id: client._id, label: client.name}))
        .sort((a, b) => a.label.localeCompare(b.label));

    const machines = getMachines(state);
    const manufacturers = Array.from(new Set(machines.map(machine => machine.manufacturer))).sort();
    const modelsMap = new Map();

    for (const machine of machines) {
        if (modelsMap.has(machine.manufacturer))
            modelsMap.get(machine.manufacturer).add(machine.model);
        else
            modelsMap.set(machine.manufacturer, new Set([machine.model]))
    }

    for (const model of modelsMap.keys())
        modelsMap.set(model, Array.from(modelsMap.get(model)).sort((a, b) => a.localeCompare(b)));

    return {
        clients, manufacturers, models: modelsMap,
    }
}


export default connect(mapStateToProps)(MachineForm);