import axios from "axios";
import {
    ALL_DOCS_FETCH_START, ALL_DOCS_FETCH_PROGRESS, ALL_DOCS_FETCH_SUCCESS, ALL_DOCS_FETCH_ERROR,
    HYDRATE_STATE_FROM_IDB,
    CHANGES_NORMAL_FETCH_START, CHANGES_NORMAL_FETCH_SUCCESS, CHANGES_NORMAL_FETCH_ERROR,
    CHANGES_FEED_FETCH_START, CHANGES_FEED_FETCH_SUCCESS, CHANGES_FEED_FETCH_ERROR, CHANGES_FEED_FETCH_STOP,
    LAUNCH_POLLING,
    LOGGED_IN, LOGGED_OUT,
} from "./actions";


const DB_NAME = "service";

const STORE_SETTINGS = "settings";
const STORE_DOCS = "docs";

const LAST_SEQ = "lastSeq";
const UPDATED_AT = "updatedAt";


let retryTimeout = null;
let abortController = new AbortController();


const removePouchDatabases = async () => {
    try {
        const databases = await window.indexedDB.databases();

        for (const database of databases) {
            if (database.name.startsWith('_pouch_')) {
                console.error(`[pollingMiddleware] removePouchDatabases: Found database ${database.name}`);

                const request = window.indexedDB.deleteDatabase(database.name);

                request.onerror = (event) => {
                    console.error(`[pollingMiddleware] removePouchDatabases: Error deleting database ${database.name}`, event);
                };

                request.onsuccess = (event) => {
                    console.log(`[pollingMiddleware] removePouchDatabases: Database ${database.name} deleted successfully`);
                };
            }
        }
    } catch (error) {
        console.error(error);
    }
}


const openDB = async () => {
    return new Promise((resolve, reject) => {
        const version = parseInt(process.env.REACT_APP_VERSION.replace(/\./g, ""));  // IndexedDB requires version to be an unsigned long long
        // console.log(`[pollingMiddleware] openDB indexedDB.open(database_name = ${DB_NAME}, version = ${version})`);
        const request = window.indexedDB.open(DB_NAME, version);

        request.onupgradeneeded = (event) => {  // ev.oldVersion, ev.newVersion
            console.log('[pollingMiddleware] openDB request.onupgradeneeded', event);

            const db = event.target.result;

            if (db.objectStoreNames.length > 0) {
                db.deleteObjectStore(STORE_SETTINGS);
                db.deleteObjectStore(STORE_DOCS);
            }

            const docsStore = db.createObjectStore(STORE_DOCS, { keyPath: "_id" });
            const settingsStore = db.createObjectStore(STORE_SETTINGS);

            settingsStore.add("0", LAST_SEQ);
            settingsStore.add(new Date(0).toISOString(), UPDATED_AT);
        };

        request.onblocked = (event) => {
            console.error('[pollingMiddleware] openDB request.onblocked', event);
            alert("Please close all other tabs with this site open!");  // if some other tab is loaded with the database, then it needs to be close before we can proceed
        };

        request.onsuccess = () => {
            // console.log('[pollingMiddleware] openDB request.onsuccess');

            const db = request.result;

            db.onerror = function(event) {
                // Generic error handler for all errors targeted at this database's requests!
                console.error("[IndexedDB] db error", db.error);
            };

            db.onversionchange = function(event) {
                console.error('[IndexedDB] db onversionchange');
                db.close();
                alert("A new version of this page is ready. Please reload or close this tab!");
            };

            resolve(db);
        };

        request.onerror = (ev) => {
            console.log('[pollingMiddleware] openDB request.onerror', request.error);
            reject(request.error);
        };
    });
};


const getLastSeq = async (db) => {
    return new Promise((resolve, reject) => {
        console.log('[pollingMiddleware] getPersistedData -> getLastSeq');

        const request = db.transaction(STORE_SETTINGS, 'readonly').objectStore(STORE_SETTINGS).get(LAST_SEQ);
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
    })
}

const getDocs = async (db) => {
    return new Promise((resolve, reject) => {
        console.log('[pollingMiddleware] getPersistedData -> getDocs');

        const request = db.transaction(STORE_DOCS, 'readonly').objectStore(STORE_DOCS).getAll();
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
    });
}

const getPersistedData = async () => {
    console.log('[pollingMiddleware] getPersistedData');

    const db = await openDB();
    const lastSeq = await getLastSeq(db);
    const docs = await getDocs(db);
    db.close();

    return {lastSeq, docs};
};


const persistData = async (db, lastSeq, docsStoreCallback) => {
    return new Promise((resolve, reject) => {
        // console.log('[pollingMiddleware] persistData');

        const t0 = performance.now();

        const transaction = db.transaction([STORE_DOCS, STORE_SETTINGS], "readwrite");
        transaction.oncomplete = () => {
            const ms = performance.now() - t0;
            console.log('[persistData] transaction oncomplete', `took ${ms} milliseconds or ${ms / 1000} seconds`);
            resolve(ms);
        };
        transaction.onerror = (ev) => {
            console.log('[persistData] transaction onerror', transaction.error);
            reject(transaction.error);
        };

        const docsStore = transaction.objectStore(STORE_DOCS);
        docsStoreCallback(docsStore);

        const settingsStore = transaction.objectStore(STORE_SETTINGS);
        settingsStore.put(lastSeq, LAST_SEQ);
        settingsStore.put(new Date().toISOString(), UPDATED_AT);
    });
}

const persistDocs = async (docs, lastSeq) => {
    // console.log('[pollingMiddleware] persistDocs');

    const db = await openDB();
    const callback = (store) => docs.forEach(doc => store.add(doc));
    await persistData(db, lastSeq, callback);
    db.close();
}

const persistChanges = async (changes, lastSeq) => {
    // console.log('[pollingMiddleware] persistChanges');

    const db = await openDB();
    const callback = (store) => changes.forEach(({id, doc, deleted}) => deleted ? store.delete(id) : store.put(doc));
    await persistData(db, lastSeq, callback);
    db.close();
}


const pollingMiddleware = (store) => (next) => (action) => {
    console.log(`[pollingMiddleware] middleware action = ${action.type}`);

    if (action.type === LOGGED_IN) {
        (async function() {
            try {
                const {docs, lastSeq} = await getPersistedData();
                console.log(`[pollingMiddleware] getPersistedData -> docs count = ${docs.length}, lastSeq = ${lastSeq}`);

                if (docs.length > 0) {
                    store.dispatch({ type: HYDRATE_STATE_FROM_IDB, payload: {docs, lastSeq} });
                    store.dispatch({ type: CHANGES_NORMAL_FETCH_START });
                } else {
                    await removePouchDatabases(); // TODO: remove at some point in future
                    store.dispatch({ type: ALL_DOCS_FETCH_START });
                }
            } catch (error) {
                console.error('[pollingMiddleware] LOGGED_IN error', error);
            }
        })();
    }

    if (action.type === ALL_DOCS_FETCH_START) {
        (async function() {
            try {
                // ACHTUNG: hard-coded value
                // Since the server's response is gzipped and chunked, there's no (reasonable) way of getting Content-Length precomputed.
                // And this is just the approximate size of the gzipped response at the moment of writing this file, in order to provide neat progress feedback.
                // Actually, the real response size was around 1320000, so we even have some time before this value needs to be updated!
                const contentLength = parseInt(process.env.REACT_APP_ALL_DOCS_GZIPPED_SIZE_IN_BYTES, 10);
                const onDownloadProgress = ev => store.dispatch({ type: ALL_DOCS_FETCH_PROGRESS, payload: Math.floor((ev.loaded * 100) / contentLength) });
                const getResponse = await axios.get(`/api/data/allDocs`, {onDownloadProgress});
                const {docs, lastSeq} = getResponse.data;

                await persistDocs(docs, lastSeq);

                store.dispatch({ type: ALL_DOCS_FETCH_SUCCESS, payload: {docs, lastSeq} })
                store.dispatch({ type: LAUNCH_POLLING });
            } catch (error) {
                console.error('[pollingMiddleware] ALL_DOCS_FETCH_START error', error);
                store.dispatch({ type: ALL_DOCS_FETCH_ERROR, payload: error.message })
            }
        })();
    }

    if (action.type === CHANGES_NORMAL_FETCH_START) {
        (async function() {
            try {
                const currentLastSeq = store.getState().lastSeq;
                const getResponse = await axios.get(`/api/data/changesSince?lastSeq=${currentLastSeq}`);
                const {changes, lastSeq} = getResponse.data;

                await persistChanges(changes, lastSeq);

                store.dispatch({type: CHANGES_NORMAL_FETCH_SUCCESS, payload: {changes, lastSeq}});
                store.dispatch({type: LAUNCH_POLLING});
            } catch (error) {
                console.error('[pollingMiddleware] CHANGES_NORMAL_FETCH_START error', error);
                store.dispatch({ type: CHANGES_NORMAL_FETCH_ERROR, payload: error.message })
            }
        })();
    }

    if (action.type === LAUNCH_POLLING) {
        let retryInterval = 5000; // Start with 5 seconds
        const maxRetryInterval = 640000; // Max cap at 640 seconds

        const poll = async () => {
            try {
                const state = store.getState();
                const currentLastSeq = state.lastSeq;

                console.log(`[pollingMiddleware] poll: request sent, lastSeq=${currentLastSeq}`);

                store.dispatch({ type: CHANGES_FEED_FETCH_START });

                abortController = new AbortController();
                const getResponse = await axios.get(`/api/data/changesLive?lastSeq=${currentLastSeq}`, {
                    signal: abortController.signal
                });
                const {changes, lastSeq} = await getResponse.data;
                await persistChanges(changes, lastSeq); // Persist fetched data to IndexedDB

                store.dispatch({ type: CHANGES_FEED_FETCH_SUCCESS, payload: {changes, lastSeq} });

                abortController = null;
                retryTimeout = null;
                retryInterval = 5000; // Reset interval after success

                poll();
            } catch (error) {
                if (axios.isCancel(error)) {
                    // do nothing, don't continue
                    console.log('[pollingMiddleware] poll: request cancelled', error.message);
                } else {
                    console.log('[pollingMiddleware] poll: request error', error.message);
                    store.dispatch({ type: CHANGES_FEED_FETCH_ERROR, payload: error.message });

                    console.log(`[pollingMiddleware] poll: trying again in ${retryInterval / 1000} seconds`);
                    retryTimeout = setTimeout(poll, retryInterval);
                    retryInterval = Math.min(retryInterval * 2, maxRetryInterval);
                }
            }
        };

        setTimeout(poll, 300);
    }

    if (action.type === LOGGED_OUT || action.type === CHANGES_FEED_FETCH_STOP) {
        if (abortController) {
            abortController.abort();
            abortController = null;
        }

        if (retryTimeout) {
            clearTimeout(retryTimeout);
            retryTimeout = null;
        }
    }

    return next(action);
};


export default pollingMiddleware;
