import React from "react";
import PropTypes from "prop-types";
import {FormGroup, Label} from "spectre-react/dist/cjs";
import {SingleSelect} from "../../shared/Select";
import {getActiveClients} from "../../../selectors";
import {connect} from "react-redux";


class Client extends React.PureComponent {
    static propTypes = {
        clients: PropTypes.arrayOf(PropTypes.object),
        clientId: PropTypes.string,
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
    };

    clients = this.props.clients.map(client => ({_id: client._id, label: client.name}));

    onChange = (ev) => {
        const selectedClient = ev.target.value;

        this.props.onChange({target: [
            {name: 'clientId', value: selectedClient?._id || null},
            {name: 'machineId', value: null}
        ]});
    };

    render() {
        const clientId = this.props.clientId;
        const selectedItem = clientId
            ? this.clients.find(client => client._id === clientId)
            : null;

        return (
            <FormGroup>
                <Label form htmlFor="clientId">Клиент</Label>
                <SingleSelect items={this.clients} selectedItem={selectedItem}
                              clearable={true} searchable={true} disabled={this.props.disabled}
                              name="clientId" placeholder="Клиент" onChange={this.onChange}
                />
            </FormGroup>
        )
    }
}


function mapStateToProps(state, ownProps) {
    return {
        clients: getActiveClients(state)
    }
}


export default connect(mapStateToProps)(Client);
