import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {BrowserRouter as Router, Route, Redirect, Switch} from 'react-router-dom';
import * as paths from '../paths';
// import {stopReplication} from "../store/actions";

import Navbar from './Navbar';
import AppLoadingScreen from "./AppLoadingScreen";
import SignInForm from './SignInForm';
import WagesEngineersTablePage from "./WagesEngineersTablePage";
import NewClientPage from "./ClientForms/NewClientPage";
import EditClientPage from "./ClientForms/EditClientPage";
import ClientsList from './ClientsList';
import NewMachinePage from "./MachineForms/NewMachinePage";
import EditMachinePage from "./MachineForms/EditMachinePage";
import MachinesList from './MachinesList';
import NewReportPage from "./ReportForms/NewReportPage";
import EditReportPage from "./ReportForms/EditReportPage";
import ReportsList from './ReportsList';
import BillsList from "./BillsList/BillsList";
import PaymentsList from "./PaymentsList/PaymentsList";
import CalendarPage from "./CalendarPage";
import TransportEmailsPage from "./TransportEmailsPage";
import SpecialDocsPage from "./SpecialDocsPage/SpecialDocsPage";
import SpecialDocEditPage from "./SpecialDocEditPage";
import WagesParamsEditPage from "./WagesParamsEditPage";
import WagesAlgorithmExplanationPage from "./WagesAlgorithmExplanationPage";
import WagesParamsHistoryPage from "./WagesParamsHistoryPage";
import ReportApprovalChainDocPage from "./SpecialDocsPage/ReportApprovalChainDocPage";
import PayoutsPage from "./PayoutsPage";


const AuthenticatedRoute = ({component: Component, authenticated, currentUser, ...rest}) => (
    <Route {...rest} render={(props) => (
        authenticated === true
            ? <Component {...props} currentUser={currentUser} />
            : <Redirect to={{pathname: paths.SIGN_IN_PATH, state: {from: props.location}}}/>
    )} />
);

const ManagementRoute = ({component: Component, authenticated, currentUser, ...rest}) => (
    <Route {...rest} render={(props) => (
        authenticated === true && currentUser.isManagement
            ? <Component {...props} currentUser={currentUser} />
            : <Redirect to={{pathname: paths.SIGN_IN_PATH, state: {from: props.location}}}/>
    )} />
);


class App extends React.Component {
    static propTypes = {
        currentUser: PropTypes.object,
        isInitializing: PropTypes.bool.isRequired,
        allDocsFetch: PropTypes.shape({
            isInProgress: PropTypes.bool.isRequired,
            progress: PropTypes.number.isRequired,
            error: PropTypes.string,
        }).isRequired,
        changesNormalFetch: PropTypes.shape({
            isInProgress: PropTypes.bool.isRequired,
            error: PropTypes.string,
        }).isRequired,
    }

    componentDidMount() {
        // window.addEventListener('unload', stopReplication);
    }

    componentWillUnmount() {
        // window.removeEventListener('unload', stopReplication);
        // stopReplication();
    }

    render() {
        const {isInitializing, allDocsFetch, changesNormalFetch} = this.props;

        if (allDocsFetch.isInProgress) {
            return <AppLoadingScreen text={`≈ ${allDocsFetch.progress} %`} />;
        }

        if (isInitializing) {
            return <AppLoadingScreen blank />;
        }

        const initializeError = allDocsFetch.error || changesNormalFetch.error;

        if (initializeError) {
            return (
                <div className="text-center text-error">
                    {initializeError}
                </div>
            )
        }

        const currentUser = this.props.currentUser;
        const authenticated = !!currentUser;

        return (
            <Router>
                {authenticated && <Navbar currentUser={currentUser}/>}

                <Switch>
                    <Route exact path="/" component={() => <Redirect to={paths.SIGN_IN_PATH}/>}/>

                    <Route exact path={paths.SIGN_IN_PATH} component={
                        (props) => authenticated
                            ? <Redirect to={paths.REPORTS_PATH}/>
                            : <SignInForm {...props}/>
                    }/>

                    <ManagementRoute path={paths.WAGES_ENGINEERS_TABLE_PATH} component={WagesEngineersTablePage} currentUser={currentUser} authenticated={authenticated}/>
                    <AuthenticatedRoute exact path={paths.CALENDAR_PATH} component={CalendarPage} currentUser={currentUser} authenticated={authenticated}/>

                    <ManagementRoute path={paths.BILLS_TABLE_PATH} component={BillsList} currentUser={currentUser} authenticated={authenticated}/>
                    <ManagementRoute path={paths.PAYMENTS_TABLE_PATH} component={PaymentsList} currentUser={currentUser} authenticated={authenticated}/>

                    <AuthenticatedRoute exact path={paths.REPORTS_PATH} component={ReportsList} currentUser={currentUser} authenticated={authenticated}/>
                    <AuthenticatedRoute exact path={paths.NEW_REPORT_PATH} component={NewReportPage} currentUser={currentUser} authenticated={authenticated}/>
                    <AuthenticatedRoute exact path={paths.EDIT_REPORT_PATH} component={EditReportPage} currentUser={currentUser} authenticated={authenticated}/>

                    <AuthenticatedRoute exact path={paths.MACHINES_PATH} component={MachinesList} currentUser={currentUser} authenticated={authenticated}/>
                    <AuthenticatedRoute exact path={paths.NEW_MACHINE_PATH} component={NewMachinePage} currentUser={currentUser} authenticated={authenticated}/>
                    <AuthenticatedRoute exact path={paths.EDIT_MACHINE_PATH} component={EditMachinePage} currentUser={currentUser} authenticated={authenticated}/>

                    <AuthenticatedRoute exact path={paths.CLIENTS_PATH} component={ClientsList} currentUser={currentUser} authenticated={authenticated}/>
                    <AuthenticatedRoute exact path={paths.NEW_CLIENT_PATH} component={NewClientPage} currentUser={currentUser} authenticated={authenticated}/>
                    <AuthenticatedRoute exact path={paths.EDIT_CLIENT_PATH} component={EditClientPage} currentUser={currentUser} authenticated={authenticated}/>

                    <AuthenticatedRoute exact path={paths.TRANSPORT_EMAILS_PATH} component={TransportEmailsPage} currentUser={currentUser} authenticated={authenticated}/>

                    <ManagementRoute exact path={paths.SPECIAL_DOCS_PATH} component={SpecialDocsPage} currentUser={currentUser} authenticated={authenticated}/>
                    <ManagementRoute exact path={paths.EDIT_SPECIAL_DOC_PATH} component={SpecialDocEditPage} currentUser={currentUser} authenticated={authenticated}/>
                    <ManagementRoute exact path={paths.EDIT_WAGES_PARAMS_PATH} component={WagesParamsEditPage} currentUser={currentUser} authenticated={authenticated}/>

                    <ManagementRoute exact path={paths.WAGES_PARAMS_HISTORY_PATH} component={WagesParamsHistoryPage} currentUser={currentUser} authenticated={authenticated}/>
                    <ManagementRoute exact path={paths.WAGES_ALGORITHM_PATH} component={WagesAlgorithmExplanationPage} currentUser={currentUser} authenticated={authenticated}/>

                    <ManagementRoute exact path={paths.PAYOUTS_PAGE_PATH} component={PayoutsPage} currentUser={currentUser} authenticated={authenticated}/>
                    <ManagementRoute exact path={paths.REPORT_APPROVAL_CHAIN_DOC_PATH} component={ReportApprovalChainDocPage} currentUser={currentUser} authenticated={authenticated}/>

                    <AuthenticatedRoute exact component={() => <h1>404 Not Found</h1>} authenticated={authenticated}/>
                </Switch>
            </Router>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser,
        isInitializing: state.isInitializing,
        allDocsFetch: state.allDocsFetch,
        changesNormalFetch: state.changesNormalFetch,
    };
};

export default connect(mapStateToProps, null)(App);