export const ALL_DOCS_FETCH_START = 'ALL_DOCS_FETCH_START';
export const ALL_DOCS_FETCH_PROGRESS = 'ALL_DOCS_FETCH_PROGRESS';
export const ALL_DOCS_FETCH_SUCCESS = 'ALL_DOCS_FETCH_SUCCESS';
export const ALL_DOCS_FETCH_ERROR = 'ALL_DOCS_FETCH_ERROR';

export const CHANGES_NORMAL_FETCH_START = 'CHANGES_NORMAL_FETCH_START';
export const CHANGES_NORMAL_FETCH_SUCCESS = 'CHANGES_NORMAL_FETCH_SUCCESS';
export const CHANGES_NORMAL_FETCH_ERROR = 'CHANGES_NORMAL_FETCH_ERROR';

export const CHANGES_FEED_FETCH_START = 'CHANGES_FEED_FETCH_START';
export const CHANGES_FEED_FETCH_SUCCESS = 'CHANGES_FEED_FETCH_SUCCESS';
export const CHANGES_FEED_FETCH_ERROR = 'CHANGES_FEED_FETCH_ERROR';
export const CHANGES_FEED_FETCH_STOP = 'CHANGES_FEED_FETCH_STOP';

export const HYDRATE_STATE_FROM_IDB = 'HYDRATE_STATE_FROM_IDB';

export const LAUNCH_POLLING = 'LAUNCH_POLLING';

export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';

export const SET_REPORTS_LIST_FILTER = 'SET_REPORTS_LIST_FILTER';
export const setReportsListFilter = (data) => ({type: SET_REPORTS_LIST_FILTER, payload: data});

export const SET_WAGES_TABLE_FILTER = 'SET_WAGES_TABLE_FILTER';
export const setWagesTableFilter = (state) => ({type: SET_WAGES_TABLE_FILTER, payload: state});